import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from "@material-ui/core";
import ReactExport from "react-data-export";
import PrimaryButton from 'app/components/Button/PrimaryButton';
import { useEffect, useRef, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { formatDateTime } from 'app/utils/validate';
import { getDataExport } from 'app/services/userManagement';
import moment from 'moment';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    width: '700px',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    borderRadius: '6px'
  },
  header: {
    color: "white",
    backgroundColor: '#2F49D1',
    padding: '20px',
    borderBottom: '1px solid #EEEEEE',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    '&.delete': {
      color: '#FF2B2B'
    },
    '&.save': {
      color: '#1A43CC'
    }
  },
  body: {
    padding: '10px 20px',
    fontSize: '16px',
    textAlign: 'center'
  },
  footer: {
    padding: '10px 20px',
    textAlign: 'center'
  },
  cancelBtn: {
    textTransform: 'uppercase',
    fontSize: '14px',
    padding: '5px 30px',
    marginRight: '15px'
  },
  okBtn: {
    marginRight: '15px',
    color: "white",
    backgroundColor: '#2F49D1',
    textTransform: 'uppercase',
    fontSize: '14px',
    padding: '5px 30px',
    "&:hover": {
      backgroundColor: "#303f9f",
      borderColor: "#303f9f"
    },
    "&:active": {
      backgroundColor: "#303f9f",
      borderColor: "#303f9f"
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      borderColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none"
    },
  },
  deleteText: {
    color: '#FF2B2B'
  },
  saveText: {
    color: '#1A43CC'
  },
  deleteBg: {
    backgroundColor: '#FF2B2B',
  },
  saveBg: { 
    backgroundColor: '#1A43CC',
  }
}));

export default function ChooseExportFileModal({ 
  isOpen,
  onClose,
  title,
  children,
  type,
  cancelText,
  option1Text,
  option2Text,
  dataExport,
}) {
  const [dataFileExportAllUser, setDataFileExportAllUser] = useState([]);
  const [dataFileExportSAPUser, setDataFileExportSAPUser] = useState([]);

  useEffect( async() => {
    const resAll = await getDataExport(-1);
    const resSAP = await getDataExport(1);
    const {results: dataAll} = resAll?.data?.data || [];
    const {results: dataSAP} = resSAP?.data?.data || [];
    let arrayAll = [];
    let arraySAP = [];

    dataAll.forEach((data1, index) => {
      arrayAll.push([
        index +1,
        data1?.sap_customer_id,
        data1?.name,
        data1?.email,
        data1?.phone_number,
        data1?.current_card_type?.key_identity,
        formatDateTime(data1?.created_at),
        data1?.status ===1 ? "Mở" : "Khóa",
      ])
    })

    dataSAP.forEach((data1, index) => {
      arraySAP.push([
        index +1,
        data1?.sap_customer_id,
        data1?.name,
        data1?.email,
        data1?.phone_number,
        data1?.current_card_type?.key_identity,
        formatDateTime(data1?.created_at),
        data1?.status ? "Mở" : "Khóa",
      ])
    })
    setDataFileExportAllUser(arrayAll);
    setDataFileExportSAPUser(arraySAP);
  }, [dataExport])

  const classes = useStyles();
  const refButton = useRef();

  const styleExcelAllUserSet =[{
    columns: [
      {title: "STT", width: {wpx: 80} },//pixels width 
      {title: "SAP Code", width: {wch: 20}},//char width 
      {title: "Họ tên", width: {wch: 40}},
      {title: "Email", width: {wch: 50}},
      {title: "Số điện thoại", width: {wch: 20}},
      {title: "Hạng thành viên", width: {wch: 20}},
      {title: "Ngày đăng kí tài khoản", width: {wch: 30}},
      {title: "Trạng thái tài khoản", width: {wch: 20}},
    ],
    data: dataFileExportAllUser
  }];

  const styleExcelSAPUserSet =[{
    columns: [
      {title: "STT", width: {wpx: 80} },//pixels width 
      {title: "SAP Code", width: {wch: 20}},//char width 
      {title: "Họ tên", width: {wch: 40}},
      {title: "Email", width: {wch: 50}},
      {title: "Số điện thoại", width: {wch: 20}},
      {title: "Hạng thành viên", width: {wch: 20}},
      {title: "Ngày đăng kí tài khoản", width: {wch: 30}},
      {title: "Trạng thái tài khoản", width: {wch: 20}},
    ],
    data: dataFileExportSAPUser
  }];

  var today = moment().format('DD-MM-YYYY');

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.modal}>
        <div className={classes.header}>
          <span className={`${classes.title} ${classes[`${type}Text`]}`}>{title}</span>
        </div>
        <div className={classes.body}>
          {children}
        </div>
        <div className={classes.footer}>
            <Button
              variant="outlined"
              onClick={onClose}
              className={classes.cancelBtn}
            >
							{cancelText}
						</Button>
            <ExcelFile
              element={
                <PrimaryButton ref={refButton} startIcon={<FiDownload />} style={{ marginRight: 15}}>
                  {option1Text}
                </PrimaryButton>
              }
              filename={`${today}_DS_ALL_USER`}
            >
              <ExcelSheet dataSet={styleExcelAllUserSet}  name="All User"/>
            </ExcelFile>
            <ExcelFile
              element={
                <PrimaryButton ref={refButton} startIcon={<FiDownload />}>
                  {option2Text}
                </PrimaryButton>
              }
              filename={`${today}_DS_SAP_USER`}
            >
              <ExcelSheet dataSet={styleExcelSAPUserSet}  name="SAP User"/>
            </ExcelFile>
        </div>
      </div>
    </Modal>
  )
}

ChooseExportFileModal.defaultProps = {
  cancelText: 'Huỷ bỏ'
}