import axios from "../../axios";
import { apiRanking } from "../../axios/urlApi";

export const getRankList = (params) => {
  return axios.get(`${apiRanking}`, { params });
};

export const getRankId = ( id) => {
  return axios.get(`${apiRanking}/${id}`);
};

export const createRank = (params) => {
  return axios.post(`${apiRanking}`, params);
};

export const editRank = ({ id, ...params }) => {
  return axios.put(`${apiRanking}/${id}`, params);
};

export const deleteRanks = (params) => {
    return axios.delete(`${apiRanking}/${params.id}`, params)
};
