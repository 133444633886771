import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/index';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { configureAppStore } from './store/configureStore';

const store = configureAppStore();

const theme = createMuiTheme({
  palette: {
    blue: {
      main: '#2F49D1',
    }
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 8,
      }
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
