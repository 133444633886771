import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Select as SelectLib,
  MenuItem,
  OutlinedInput,
  FormControl,
  makeStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DefaultButton from "app/components/Button/DefaultButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import TextFields from "app/components/TextFields";
import { ACTION_TYPE } from "app/reducers/charity";
import { isNumeric, numberWithCommas } from "app/utils/common";
import { formatPrice, isEmpty } from "app/utils/validate";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SelectProduct from "./SelectProduct";
import Autocomplete from "app/pages/FlashSales/components/Autocomplete";
import { LANG_VI } from "app/utils/constant";
import { getProductList as _getProductList } from "app/services/flashsale";

const AddEditCharityProduct = ({
  product,
  setProduct,
  charity,
  refreshList,
}) => {
  const dispatch = useDispatch();
  const [productSelect, setProductSelect] = useState();
  const [errorProduct, setErrorProduct] = useState();
  const [percentage, setPercentage] = useState("");
  const [typePriceDiscount, setTypePriceDiscount] = useState("");
  const [quantity, setQuantity] = useState("");
  const [submit, setSubmit] = useState(false);
  const [colorList, setColorList] = useState();
  const [color, setColor] = useState();
  const classes = useStyles();
  const [conditionPrice, setConditionPrice] = useState({ value: null });

  useEffect(async() => {
    if (product?.id) {
      setProductSelect(product?.product_obj);
      setConditionPrice({value:product?.percentage ? product?.percentage : product?.discount});
      setQuantity(formatPrice(product?.quantity));
      setTypePriceDiscount(product?.discount ? 2 : 1)

      try {
        const request = await _getProductList();
        const { results } = await request.data;

        const listStructure =
          results.length > 0
            ? results.map((item) => ({
              id: item.id,
              title: item.translates.find((item) => LANG_VI === item?.language_code).name,
              price: item?.attributes?.length === 0 ? item?.price : -10,
              attributes: item?.attributes?.length === 0 ? item?.code : item?.attributes?.[0]
            }))
            : [];
            const getDataProduct = listStructure?.find(item2 => product?.product_obj?.id === item2?.id )
            console.log("getDataProduct",getDataProduct)
        const getColors = typeof(getDataProduct?.attributes) === "string" ? [] : getDataProduct?.attributes?.values?.map((itemColor)=>
        {
           return {
             price:itemColor?.price,
           id:itemColor?.sap_material_code,
           title:itemColor?.translates.find(itemColorName => itemColorName?.language_code === LANG_VI ).language_value,
  
         }})
        setColorList(getColors)
      } catch (error) {
        console.log(error);
      }


    } else {
      setProductSelect();
      setPercentage("");
      setQuantity("");
      setSubmit(false);
    }
  }, [product]);
  useEffect(() => {
    if (colorList) {
      setColor(colorList?.find(item => item?.id === product?.sap_material_code));
    }
  }, [product, colorList])
  useEffect(() => {
    if (Array.isArray(productSelect?.product_inventory)) {
      // let max = productSelect.product_inventory.reduce(
      //   (value, item) => (item?.remaining_number || 0) + value,
      //   0
      // );
      // let q = parseInt(quantity.replace(/[^0-9]/g, ""));
      // if (q > max) {
        // setQuantity(formatPrice(max));
      // }
    }
  }, [productSelect, quantity]);

  const handleClose = (e, reason) => {
    e?.preventDefault();
    if (reason !== "backdropClick") {
      setProduct instanceof Function && setProduct();
    }
  };

  const onChangeProduct = (product) => {
    setProductSelect(product);
    setColor({price:0,id:'',title:''})
    console.log(product)
    const getColors = typeof(product?.attributes) === "string" ? [] : product?.attributes[0]?.values?.map((itemColor)=>
    {
       return {
         price:itemColor?.price,
       id:itemColor?.sap_material_code,
       title:itemColor?.translates.find(itemColorName => itemColorName?.language_code === LANG_VI ).language_value,

     }})
    setColorList(getColors)
    setErrorProduct();
  };
  const onChangeColor = (color) => {
    setColor(color);
  };
  const onChangeTypePriceDiscount = (value) => {
    setTypePriceDiscount(value);
  };
  const onChangePercentage = (e) => {
    const value = e.target.value;
    const isValidNumber = isNumeric(value || 0);
    if (!isValidNumber) return;
    if (parseInt(value) > 100) {
      setPercentage("100");
    } else {
      setPercentage(value);
    }
  };

  const onSubmit = () => {
    if (
      !productSelect?.id ||
      isEmpty(quantity) ||
      parseInt(quantity) === 0 ||
      !conditionPrice?.value ||
      parseInt(conditionPrice?.value) === 0 ||
      colorList?.length > 0 && !color
    ) {

      setSubmit(true);
      return;
    }
    let params = {
      product: productSelect?.id,
      quantity: quantity.replace(/[^0-9]/g, ""),
      sap_material_code: color?.id? color?.id : productSelect?.code
    }
    if (typePriceDiscount === 2) {
      params = { ...params, discount: Number(conditionPrice?.value) }
      delete params?.percentage
    } else {
      params = { ...params, percentage: conditionPrice?.value }
      delete params?.discount

    }
    dispatch({
      type: product?.id
        ? ACTION_TYPE.EDIT_PRODUCT_REQUEST
        : ACTION_TYPE.ADD_PRODUCT_REQUEST,
      id: charity?.id,
      productId: product?.id,
      data: params,
      success: () => {
        refreshList instanceof Function && refreshList();
        setProduct();
      },
      error: (e) => {
        if (e?.message === "charity product already exist") {
          setErrorProduct("Sản phẩm đã xuất hiện trong chương trình");
        }
      },
    });
  };
  const handlePriceToType = (price) => {
    if (typePriceDiscount === 1) {
      return (price / 100) * parseFloat(conditionPrice?.value || "0")
    }
    console.log("conditionPrice?.value",conditionPrice?.value)
    return parseFloat(conditionPrice?.value || "0")

  }
  const handlePriceAfterDiscoutToType = (price) => {
    if (typePriceDiscount === 1) {
      return (price / 100) * (100 - parseFloat(conditionPrice?.value))
    }
    return price - parseFloat(conditionPrice?.value || "0")

  }
  const priceDiscount = color ? numberWithCommas(
    +(handlePriceToType(color?.price)).toFixed(4),
    ","
  ) : numberWithCommas(
    +(handlePriceToType(productSelect?.price)).toFixed(4),
    ","
  );

  const priceAfterDiscount = color?.value ? numberWithCommas(
    +(handlePriceAfterDiscoutToType(color?.price)).toFixed(4),
    ","
  ) : numberWithCommas(
    +(handlePriceAfterDiscoutToType(productSelect?.price)).toFixed(4),
    ","
  );
  const onChaneDiscount = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (/^0{2,}/.test(value) || /\D+/.test(value)) {
      return;
    }
    if (typePriceDiscount === 1) {
      if (e.target.value <= 100) {
        if (parseInt(e.target.value) > 100) {
          setConditionPrice({ value: "100" });
        } else {
          setConditionPrice({ value: e.target.value });
        }
      }
    } else {
      if (/^0[1-9]/.test(value)) {
        setConditionPrice({ value: value.slice(1) });
      } else {
        setConditionPrice({ value: value });
      }
    }
  };
  const getBorderColor = () => {
    return submit && !conditionPrice ? '#f44336' : 'transparent';
  };
  console.log(Boolean(!color?.title))
  console.log(submit)
  console.log(colorList?.length)
  console.log(colorList?.length > 0 && !color?.title)
  return (
    <Modal
      open={product !== undefined}
      onClose={handleClose}
      style={{ outline: "none" }}
    >
      <div className="modal-content" style={{ maxWidth: 650 }}>
        <div className="modal-header">
          <div className="modal-title">
            {product?.id ? "Chỉnh sửa " : "Thêm "}sản phẩm
          </div>
          <IconButton onClick={handleClose} style={{ padding: 3 }}>
            <Close />
          </IconButton>
        </div>
        <div className="modal-body">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextFields
                label="Sản phẩm"
                required
                error={errorProduct || (submit && !productSelect?.id)}
                helperText={
                  errorProduct ? errorProduct : "Sản phẩm được yêu cầu"
                }
              >
                <SelectProduct
                  value={productSelect}
                  onChange={onChangeProduct}
                  disabled={!!product?.id}
                  error={
                    errorProduct || (submit && !productSelect?.id)
                  }
                />
              </TextFields>
              <TextFields
                label="Số lượng"
                required
                type="number"
                placeholder="Nhập số lượng"
                value={quantity}
                onChange={(e) => { setQuantity(e.target.value < 0 ? 0 :e.target.value)  }}
                error={
                  submit && (isEmpty(quantity) || parseInt(quantity) === 0)
                }
                helperText={
                  isEmpty(quantity)
                    ? "Số lượng được yêu cầu"
                    : "Số lượng phải lớn hơn 0"
                }
              />
              <TextFields
                disabled
                label="Giá sản phẩm"
                required
                placeholder="Tự cập nhật"
                value={formatPrice(color?.price ? color?.price : productSelect?.price)}
              />
              <TextFields
                disabled
                label="Tiền quyên góp"
                required
                placeholder="Tự cập nhật"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#3A3A3A" fontWeight="bold">
                      VND
                    </Box>
                  </InputAdornment>
                }
                value={priceDiscount}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
         
            </Grid> */}

            <Grid item xs={12} md={6}>
              <Autocomplete
                required
                // disabled={!isCreate}
                // error={colorList?.length > 0 && error.promo_color}
                defaultValue={color?.title}
                forColor={true}
                data={colorList}
                id="color"
                labelName="Màu sắc"
                placeholder="Chọn màu sắc"
                error={
                   colorList?.length > 0 && !color?.title
                }
                // inputSuffix="VND"
                onChange={(val) => onChangeColor(val)}
              />
              <TextFields
                label="Giá trị giảm giá"
                helperText="Giá trị giảm giá đươc yêu cầu"

                error={
                  submit && !conditionPrice?.value
                }
              >
                <Box display="flex" alignItems="center">
                <div style={{ border: `1px solid ${getBorderColor()}`, borderRadius: 4, width: '100%', height: 38 }}>

                  <OutlinedInput
                    disabled={!typePriceDiscount}
                    style={{ width: "100%", height: 38 }}
                    value={
                      typePriceDiscount === 1
                        ? conditionPrice.value
                        : formatPrice(conditionPrice.value)
                    }
                    placeholder="Nhập giá trị giảm giá"
                    error={submit && !conditionPrice?.value}
                    onChange={onChaneDiscount}
                    sx={{ flexGrow: 1 }}
                  />
                </div>

                  <FormControl style={{ minWidth: 70 }}>
                    <SelectLib
                      // disabled={disable || detail}
                      style={{ marginLeft: 8, fontSize: 14, fontWeight: 700, border: "solid 1px #b3b3b3", borderRadius: "4px" }}
                      // name="promo_type_discount"
                      value={typePriceDiscount}
                    error={submit &&  !typePriceDiscount}

                      onChange={(e) => {
                        setConditionPrice({ value: "" });
                        onChangeTypePriceDiscount(e.target.value)
                      }}
                      disableUnderline
                      className={submit && !typePriceDiscount ? `${classes.select} ${classes.selecError}` : `${classes.select} ${classes.selectNoError}`}

                      MenuProps={{ classes: { paper: classes.menuProps } }}


                    >
                      <MenuItem value={2}>
                        <Box>VNĐ</Box>
                      </MenuItem>
                      <MenuItem value={1}>
                        <Box> % </Box>
                      </MenuItem>
                    </SelectLib>
                  </FormControl>
                </Box>
              </TextFields>
              {/* <TextFields
                label="Quyên góp"
                required
                placeholder="Nhập %"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#3A3A3A" fontWeight="bold">
                      %
                    </Box>
                  </InputAdornment>
                }
                value={percentage}priceAfterDiscount
                onChange={onChangePercentage}
                error={submit && isEmpty(percentage)}
                helperText={
                  isEmpty(percentage) && "Phần trăm quyên góp được yêu cầu"
                }
                inputProps={{ maxLength: 5 }}
              /> */}
              <TextFields
                disabled
                label="Giá trị sau quyên góp"
                required
                placeholder="Tự cập nhật"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#3A3A3A" fontWeight="bold">
                      VND
                    </Box>
                  </InputAdornment>
                }
                value={priceAfterDiscount}
              />
            </Grid>
          </Grid>
        </div>
        <div className="modal-footer">
          <DefaultButton onClick={handleClose}>Hủy</DefaultButton>
          <PrimaryButton onClick={onSubmit}>Lưu lại</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    height: '100%',
    fontSize: "12px",
    fontWeight: 500,
    padding: 0,
    margin: 0,
    borderRadius: "5px",
    "& :focus": {
      backgroundColor: "transparent",
    },
    ".MuiNativeSelect-select": {
      backgroundColor: 'black'
    },
    "&.MuiInput-root": {
      padding: "0px 16px",
    },

    "& .Mui-disabled span": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  selectNoError: {
    border: "1px solid #B3B3B3 !important",
  },
  selecError: {
    border: "1px solid #EA403F !important",
  },
  menuProps: {
    paddingLeft: 0,
    marginTop: "55px",
    backgroundColor: 'white',
  },
}))
export default AddEditCharityProduct;
