import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Link as LinkUI,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Breadcrumbs from "app/components/Breadcrumbs";
import DangerButton from "app/components/Button/DangerButton";
import DefaultButton from "app/components/Button/DefaultButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import Switch from "app/components/Switch";
import TextFields from "app/components/TextFields";
import {
  fetchCoupon,
  fetchCreateCoupon,
  fetchDeleteCoupons,
  fetchListCoupon,
  fetchUpdateCoupon,
} from "app/reducers/coupon";
import { getTranslateField } from "app/utils";
import { LANG_EN, LANG_VI, PAGE_SIZE } from "app/utils/constant";
import { formatDateTime, isEmpty } from "app/utils/validate";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Lodash from "lodash";
import { getCustomCard as _getCustomCard } from "app/services/flashsale";
import SelectLang from "app/components/SelectLang";
import Textarea from "../../Promotions/components/Textarea";
import {
  fetchCreateCouponLoyalty,
  fetchDeleteLoyaltyCoupons,
  fetchListLoyaltyCoupon,
  fetchLoyaltyCoupon,
  fetchUpdateLoyaltyCoupon,
} from "app/reducers/loyaltyCoupon";
import Checkbox from "app/components/Checkbox";
import { fetchCreateRank, fetchRank, fetchUpdateRank } from "app/reducers/rank";
import { isNumeric } from "app/utils/common";

const objectCondition = {
  percentage: null,
  price: null,
  product: null,
  quantity: null,
  type: 1,
  value: null,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const RankManagementCreateEdit = ({ coupon }) => {
  const [customerCard, setCustomerCard] = useState([]);
  const { coupon: couponDetail } = useSelector((state) => state.rank);
  const [dataDetail, setDataDetail] = useState(coupon || couponDetail || {});
  const params = useParams();
  const [disable, setDisable] = useState(false);
  const [conditionPrice, setConditionPrice] = useState({ value: null });
  const [validate, setValidate] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [lang, setLang] = useState(LANG_VI);
  const [openToastError, setOpenToastError] = useState(false);
  const [messageToastError, setMessageToastError] = useState("");
  const [detail, setDetail] = useState(true);
  useEffect(() => {
    if (params.id) {
     dispatch(fetchRank(params?.id));
    }
  }, [params?.id]);
  useEffect(() => {
    const getCustomerCard = async () => {
      const res = await _getCustomCard();
      const { data } = await res.data;

      const listStructure =
        data.length > 0
          ? data.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setCustomerCard(listStructure);
    };
    getCustomerCard();
  }, []);
  useEffect(() => {
    if (dataDetail?.type === 1) setDisable(true);
    else setDisable(false);
  }, [dataDetail]);

  const handleCreate = () => {
    if (
      dataDetail.key_identity &&
      dataDetail.name 
    ) {
      if (!(/^(\w|-)*$/).test(dataDetail?.key_identity)) {
        setValidate(true);
        return;
      }
   

    if(parseInt(dataDetail.max) < parseInt(dataDetail.min)){
      setValidate(true);
        return;
    }
      const data = {
        key_identity: dataDetail.key_identity,
        name: dataDetail.name,
        config_money_spends:[
           {
            min:dataDetail.min,
            max:dataDetail.max,
            include_min:dataDetail.include_min || -1,
            include_max:dataDetail.include_max || -1
           }
        ],
        config_rewards:[{
            percent:dataDetail.percent,
            is_freeship:dataDetail.is_freeship || -1,
            is_evoucher_birthday:dataDetail.is_evoucher_birthday || -1,
            is_evoucher_cent:dataDetail.is_evoucher_cent || -1,
            is_evoucher_sale:dataDetail.is_evoucher_sale || -1,
            
            is_sms_birthday:dataDetail.is_sms_birthday || -1,
        }
        ]
      };
      let dataCreate = {
        items: [data],
      };   
      if (params.id) {
        dispatch(fetchUpdateRank({ ...data, id: params.id }))
          .then((s) => {
            if (s?.status === 200) {
              setDetail(true);
              setValidate(false);
              setLang(LANG_VI);
              history.push(`/${params.id}`);
            } else {
              if ((s.message || "").includes("code already exist")) {
                setMessageToastError("Mã khuyến mại đã tồn tại");
                setValidate(true);
              } else {
                setOpenToastError(true);
                setMessageToastError(s.message);
              }
            }
          })
          .catch((error) => {});
      } else {
        dispatch(fetchCreateRank({ ...dataCreate }))
          .then((s) => {
            if (s?.status === 201) {
              history.push("/");
            } else {
              setOpenToastError(true);
              setMessageToastError(s.message);
            }
          })
          .catch((error) => {});
      }
    } else {
      setValidate(true);
    }
  };
  function calcDiscount(price, discount = 0, name) {
    let productDiscount = discount;
    const isValidNumber = isNumeric(productDiscount || 0);
    if (!isValidNumber) return;
    if (parseInt(productDiscount) > 100) {
      productDiscount = "100";
    } else {
      productDiscount = discount;
    }
    setDataDetail({
        ...dataDetail,
        [name]: productDiscount,
      });
 }
  const onChangeValue = (e, type, key) => {
    const name = e.target.name;
    if (name === "min" || name === "max"){
       if(!(isNumeric(e.target.value || 0))) return
    }
    if (name === "percent") {
        calcDiscount(0, e.target.value,name);
        return
      }
    switch (type) {
      case "switch":
        setDataDetail({
          ...dataDetail,
          [key]: dataDetail.status === 1 ? -1 : 1,
        });
        break;
      default:
        setDataDetail({
          ...dataDetail,
          [name]: e.target.value,
        });
        break;
    }
  };
  const formatPrice = (value) => {
    let res = (value || "")
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    return res;
  };

  
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastError(false);
  };
  const onChangeDetail = (e) => {
    e.preventDefault();
    setValidate(false);
    setDetail(!detail);
  };
 
  useEffect(() => {
    if (!couponDetail) {
      history.replace("/");
      return;
    }
    if(!params.id){
      setDetail(Boolean(coupon?.id));

    }
    if (couponDetail?.id && params?.id) {
      const dataConfigMoney =couponDetail.config_money_spends.at(0)
      const dataConfigRewards =couponDetail.config_rewards.at(0)
      
      setDataDetail({
        ...couponDetail,
        max: dataConfigMoney?.max,
        min: dataConfigMoney?.min,
        include_max: dataConfigMoney?.include_max,
        include_min: dataConfigMoney?.include_min,
        percent:dataConfigRewards?.percent,
        is_sms_birthday:dataConfigRewards?.is_sms_birthday,
        is_freeship:dataConfigRewards?.is_freeship,
        is_evoucher_sale:dataConfigRewards?.is_evoucher_sale,
        is_evoucher_cent:dataConfigRewards?.is_evoucher_cent,
        is_evoucher_birthday:dataConfigRewards?.is_evoucher_birthday,
      });

      setConditionPrice({ value: couponDetail.sale_price });
    }
  }, [couponDetail]);
  const handleChangeTextarea = (key, value) => {
    setDataDetail({
      ...dataDetail,
      [key]: value,
    });
  };
  return (
    <Fragment>
      <Snackbar
        open={openToastError}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseToast} severity="error">
          {messageToastError}
        </Alert>
      </Snackbar>
      <div className="page-header">
        {params?.id ? (
          detail ? (
            <Fragment>
              <div className="page-title">
                <Breadcrumbs>
                  <Link to="/">Danh sách thứ hạng</Link>
                  <Typography>{couponDetail?.key_identity}</Typography>
                </Breadcrumbs>
              </div>
              <PrimaryButton onClick={onChangeDetail}>Chỉnh sửa</PrimaryButton>
            </Fragment>
          ) : (
            <Fragment>
              <div className="page-title">
                <Breadcrumbs>
                  <Link to="/">Danh sách thứ hạng</Link>
    
                  <LinkUI href="#" onClick={()=>history.go(0)}>
                  {couponDetail?.key_identity}
                  </LinkUI>
                  <Typography>Chỉnh sửa</Typography>
                </Breadcrumbs>
              </div>

              <DefaultButton component={Link}  onClick={()=>history.go(0)}>
                Hủy
              </DefaultButton>
              <PrimaryButton onClick={handleCreate}>Lưu lại</PrimaryButton>
            </Fragment>
          )
        ) : (
          <Fragment>
            <div className="page-title">
              <Breadcrumbs>
                <Link to="/">Danh sách thứ hạng</Link>
                <Typography>Thêm mới</Typography>
              </Breadcrumbs>
            </div>
            <DefaultButton component={Link} to="/">
              Hủy
            </DefaultButton>
            <PrimaryButton onClick={handleCreate}>Lưu Lại</PrimaryButton>
          </Fragment>
        )}
      </div>
      <div className="page-content">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box fontSize={16} fontWeight={600}>
          {lang === LANG_VI ? "Thông tin chung" : "Information"}
          </Box>
          <SelectLang
            value={lang}
            onChange={(e) => {
              setLang(e);
              setValidate(false);
            }}
          />
        </Box>
        {lang === LANG_VI ? (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Mã thứ hạng"
                required
                name="key_identity"
                disabled={detail}
                placeholder="Nhập mã khuyến mãi"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.key_identity || ""}
                helperText={
                  validate && !dataDetail.key_identity
                    ? "Mã thứ hạng được yêu cầu"
                    : validate && /^(\w|-)*$/.test(dataDetail?.key_identity) ? validate : "Mã thứ hạng chỉ chứa kí tự , số , gạch ngang"
                }
                error={(validate && !dataDetail.key_identity)||(validate && !(/^(\w|-)*$/.test(dataDetail?.key_identity)))}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Tên thứ hạng"
                required
                name="name"
                disabled={detail}
                placeholder="Nhập tên thứ hạng"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.name || ""}
                helperText={
                  validate && !dataDetail.name
                    ? "Tên thứ hạng được yêu cầu"
                    : validate
                }
                error={validate && !dataDetail.name}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Rank code"
                required
                name="key_identity"
                disabled={detail}
                placeholder="Type Rank code "
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.key_identity || ""}
                helperText={
                  validate && !dataDetail.key_identity
                    ? "Rank code is required"
                    : validate && /^(\w|-)*$/.test(dataDetail?.key_identity) ? validate : "Coupon code constain letter , number , dahses"
                }
                error={(validate && !dataDetail.key_identity)||(validate && !(/^(\w|-)*$/.test(dataDetail?.key_identity)))}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Rank name"
                required
                name="name"
                disabled={detail}
                placeholder="Type Rank name "
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.name || ""}
                helperText={
                  validate && !dataDetail.name
                    ? "Rank name is required"
                    : validate
                }
                error={validate && !dataDetail.name}
              />
            </Grid>
          </Grid>
        )}
      </div>

      {lang === LANG_VI ? (
        <>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Quyền lợi thành viên
              </Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Quyền lợi ưu đãi"
                  required
                  name="percent"
                  disabled={detail}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.percent || ""}
                  helperText={
                    validate && !dataDetail.percent
                      ? "Quyền lợi ưu đãi phải được yêu cầu"
                      : validate
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>%</strong>
                      </span>
                    </InputAdornment>
                  }
                  error={validate && !dataDetail.percent}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFields label="Quyền lợi chăm sóc" required>
                  <Box display="flex">
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingRight="25%"
                    >
                      
                      <Checkbox disabled={detail} label="Mã Freeship" checked={dataDetail?.is_freeship === 1 ? true :false}  onChange={(e) => handleChangeTextarea("is_freeship",e ? 1 :-1)} />
                      <Checkbox disabled={detail} label="E-voucher giảm giá" checked={dataDetail?.is_evoucher_sale === 1 ? true :false} onChange={(e) =>handleChangeTextarea("is_evoucher_sale",e ? 1 :-1)} />
                    </Box>
                    {/* <Box display="flex" flexDirection="column">
                      <Checkbox disabled={detail} label="E-voucher tặng xu" checked={dataDetail?.is_evoucher_cent === 1 ? true : false}  onChange={(e) =>handleChangeTextarea("is_evoucher_cent",e ? 1 :-1)}/>
                      <Checkbox disabled={detail} label="E-voucher sinh nhật" checked={dataDetail?.is_evoucher_birthday === 1 ? true : false} onChange={(e) =>handleChangeTextarea("is_evoucher_birthday",e ? 1 :-1)} />
                    </Box> */}
                  </Box>
                  {/* <Checkbox disabled={detail} label="SMS mừng sinh nhật" checked={dataDetail?.is_sms_birthday === 1 ? true : false} onChange={(e) =>handleChangeTextarea("is_sms_birthday",e ? 1 :-1)} /> */}
                </TextFields>
              </Grid>
            </Grid>
          </div>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Điều kiện
              </Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Chi tiêu tối thiểu"
                  required
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="min"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.min}
                  helperText="Chi tiêu tối thiểu phải được yêu cầu"
                  error={validate && !dataDetail.min}
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VND</strong>
                      </span>
                    </InputAdornment>
                  }
                />
                <Checkbox label="Bao gồm chi tiêu tối thiểu" checked={dataDetail?.include_min === 1 ? true : false} onChange={(e) =>handleChangeTextarea("include_min",e ? 1 :-1)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Chi tiêu tối đa"
                  required
                  disabled={disable || detail }
                  inputProps={{ maxLength: 50 }}
                  name="max"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.max}
                  helperText={validate && !dataDetail.max ?"Chi tiêu tối đa phải được yêu cầu":validate && dataDetail.min && (parseInt(dataDetail?.max) < parseInt(dataDetail?.min))?"Chi tiếu tối đa không được bé hơn chi tiếu tối thiểu" : validate}
                  error={(validate && !dataDetail.max) || (validate && dataDetail.min && parseInt(dataDetail?.max) < parseInt(dataDetail?.min))}
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VND</strong>
                      </span>
                    </InputAdornment>
                  }
                />
                <Checkbox label="Bao gồm chi tiêu tối đa" checked={dataDetail?.include_max === 1 ? true : false} onChange={(e) =>handleChangeTextarea("include_max",e ? 1 :-1)} />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Benefits for members
              </Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Discount to percent"
                  required
                  name="percent"
                  disabled={detail}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.percent || ""}
                  helperText={
                    validate && !dataDetail.percent
                      ? "Discount to percent is required"
                      : validate
                  }
                  error={validate && !dataDetail.percent}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFields label="Benefits to voucher" required>
                  <Box display="flex">
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingRight="25%"
                    >
                      <Checkbox disabled={detail} label="Freeship" checked={dataDetail?.is_freeship === 1 ? true :false} onChange={(e) => handleChangeTextarea("is_freeship",e ? 1 :-1)} />
                      <Checkbox disabled={detail} label="E-voucher discount" checked={dataDetail?.is_evoucher_sale === 1 ? true :false} onChange={(e) =>handleChangeTextarea("is_evoucher_sale",e ? 1 :-1)}/>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Checkbox disabled={detail} label="E-voucher coin" checked={dataDetail?.is_evoucher_cent === 1 ? true : false} onChange={(e) =>handleChangeTextarea("is_evoucher_cent",e ? 1 :-1)}/>
                      <Checkbox disabled={detail} label="E-voucher birthday" checked={dataDetail?.is_evoucher_birthday === 1 ? true : false} onChange={(e) =>handleChangeTextarea("is_evoucher_birthday",e ? 1 :-1)} />
                    </Box>
                  </Box>

                  <Checkbox disabled={detail} label="SMS birthday" checked={dataDetail?.is_sms_birthday === 1 ? true : false} onChange={(e) =>handleChangeTextarea("is_sms_birthday",e ? 1 :-1)} />                 
                </TextFields>


               
              </Grid>
            </Grid>
          </div>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Conditions
              </Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Minimum price"
                  required
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="min"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.min}
                  helperText="Minimum price is required"
                  error={validate && !dataDetail.min}
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VND</strong>
                      </span>
                    </InputAdornment>
                  }
                />
                <Checkbox label="Include of Minimum price" checked={dataDetail?.include_min === 1 ? true : false} onChange={(e) =>handleChangeTextarea("include_min",e ? 1 :-1)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Maximum price"
                  required
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="max"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.max}
                  helperText="Maximum price is required"
                  error={validate && !dataDetail.max}
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VND</strong>
                      </span>
                    </InputAdornment>
                  }
                />
                <Checkbox label="Include of Maximum price"  checked={dataDetail?.include_max === 1 ? true : false} onChange={(e) =>handleChangeTextarea("include_max",e ? 1 :-1)} />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Fragment>
  );
};
const useStyles = makeStyles({
  select: {
    width: "100%",
    fontSize: "12px",
    fontWeight: 500,

    border: "1px solid #dedede",
    borderRadius: "5px",
    // padding: '4px 16px',

    "& :focus": {
      backgroundColor: "transparent",
    },

    "&.MuiInput-root": {
      padding: "4px 16px",
    },

    "& .Mui-disabled span": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  labelError: {
    color: "#EA403F",
  },
  selectError: {
    border: "1px solid #EA403F",
  },
});
RankManagementCreateEdit.defaultProps = {
  coupon: {},
};
export default RankManagementCreateEdit;
