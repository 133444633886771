import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import InboxIcon from '@material-ui/icons/Inbox';

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
  
    ...(isDragging && {
      background: "#fff",
      display: 'table',
      border: '1px solid black'
    }),
  });
const DraggableListItem = ({ item, index }) => {
    
  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <ListItem
        sx={{ borderColor: 'text.primary' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          
          <ListItemText primary={item.name} />
        </ListItem>
      )}
    </Draggable>
    
  );
};

export default DraggableListItem;
