import {
  AppBar,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import DangerButton from "app/components/Button/DangerButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import Pagination from "app/components/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import SearchInput from "app/components/SearchInput";
import SelectStatus from "app/components/SelectStatus";
import SwitchUI from "app/components/Switch";
import {
  fetchDeleteCoupons,
  fetchListCoupon,
  fetchUpdateCoupon,
} from "app/reducers/coupon";
import { PAGE_SIZE } from "app/utils/constant";
import * as Utils from "app/utils";
import moment from "moment";
import { Fragment, useEffect, useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { TabPanel } from "@material-ui/lab";
import { formatDate, formatDateTime } from "app/utils/validate";

export default function Coupons( {couponLoyalty, couponFriends}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabContext value={value}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "unset",
            boxShadow: "none",
          }}
        >
          <TabList
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="simple tabs example"
          >
            <Tab
              label={
                <div className="page-header">
                  <div className="page-title">Quyền lợi ưu đãi</div>
                </div>
              }
              value="1"
            />
            <Tab
              label={
                <div className="page-header">
                  <div className="page-title">Chia sẻ người dùng</div>
                </div>
              }
              value="2"
            />
          </TabList>
        </AppBar>
        <TabPanel value="1" style={{ padding: 0, paddingTop: "24px" }}>
          <Fragment>
            <TableContainer component={Paper}>
              <Table padding="normal">
                <TableHead>
                  <TableRow>
                    <TableCell>Mã coupon</TableCell>
                    <TableCell>Ngày bắt đầu</TableCell>
                    <TableCell>Ngày kết thúc</TableCell>
                    <TableCell>Trạng thái</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {couponLoyalty?.map((coupon, index) =>(
                    <TableRow key={index}>
                      <TableCell style={{textTransform: 'uppercase' }}>
                        {coupon?.coupon_loyalty?.code}
                      </TableCell>
                      <TableCell>{formatDate(coupon?.start_date)}</TableCell>
                      <TableCell>{formatDate(coupon?.end_date)}</TableCell>
                      <TableCell>{coupon?.used === 1 ? "Đã sử dụng" : "Chưa sử dụng"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </TabPanel>
        <TabPanel value="2" style={{ padding: 0, paddingTop: "24px" }}>
          <Fragment>
            <TableContainer component={Paper}>
              <Table padding="normal">
                <TableHead>
                  <TableRow>
                    <TableCell>Mã coupon</TableCell>
                    <TableCell>Ngày bắt đầu</TableCell>
                    <TableCell>Ngày kết thúc</TableCell>
                    <TableCell>Trạng thái</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {couponFriends?.map((coupon, index) =>(
                    <TableRow key={index}>
                      <TableCell style={{textTransform: 'uppercase' }}>
                        {coupon?.coupon_loyalty?.code}
                      </TableCell>
                      <TableCell>{formatDate(coupon?.start_date)}</TableCell>
                      <TableCell>{formatDate(coupon?.end_date)}</TableCell>
                      <TableCell>{coupon?.used === 1 ? "Đã sử dụng" : "Chưa sử dụng"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </TabPanel>
      </TabContext>
    </div>
  );
}
