import axios from "../../axios";
import { apiCouponFriendsByIdCustomer, apiCouponLoyaltyByIdCustomer, apiExportFileListUsers } from "../../axios/urlApi";

export const getDataExport = (params) => {
  return axios.get(`${apiExportFileListUsers}?synced_to_sap=${params}`);
};


export const getCouponLoyaltyByIdCustomer = (params) => {
  return axios.get(`${apiCouponLoyaltyByIdCustomer}?customer=${params}` );
};

export const getCouponFriendsByIdCustomer = (params) => {
  return axios.get(`${apiCouponFriendsByIdCustomer}?customer=${params}` );
};
