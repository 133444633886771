import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import DangerButton from "app/components/Button/DangerButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import { Fragment, useEffect, useState } from "react";
import EditFlashSale from "../components/Discountflashsale";
import { formatVND } from "app/utils/common";

const Detailflashsale = ({
  details,
  setDetails,
  handleAddDiscount,
  productPrice,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [discount, setDiscount] = useState();
  // const [details, setDiscountItems] = useState([]);

  // useEffect(() => {
  //   setDiscountItems(details);
  // }, [JSON.stringify(details)]);

  const checkedAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(details.map((item) => item?.id));
    } else {
      setSelectedRows([]);
    }
  };

  const checkedRow = (e, row) => {
    let selected = [...selectedRows];
    if (e.target.checked) {
      selected.push(row?.id);
    } else {
      selected = selected.filter((item) => item !== row?.id);
    }
    setSelectedRows(selected);
  };

  const removeDiscounts = () => {
    let discountList = details.filter(
      (item) => !selectedRows.includes(item?.id)
    );
    setDetails(discountList);
    setSelectedRows([]);
    setConfirmDelete(false);
  };

  const handleEdit = (data) => {
    const { id } = data;
    let editDiscountList = [...details];
    console.log(editDiscountList)
    const newDetails = editDiscountList.map((item) => {
      if (item.id === id) {
        return { ...data };
      } else {
        return item;
      }
    });
    console.log(newDetails)

    setDetails(newDetails);
  };
console.log(details)
console.log(productPrice)

  return (
    <Fragment>
      <EditFlashSale
        flashsale={discount}
        setFlashsale={setDiscount}
        modal={editModal}
        setModal={setEditModal}
        callbackSubmit={handleEdit}
        details={details}
      />
      <ConfirmDelete
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onOK={removeDiscounts}
        title="XÓA FLASHSALE"
        message="Bạn có chắc muốn xóa flashsale đã chọn?"
        cancelTitle="HỦY BỎ"
        okTitle="XÓA"
      />
      <div className="page-header">
        <PrimaryButton startIcon={<Add />} onClick={handleAddDiscount}>
          Thêm mới
        </PrimaryButton>
        <DangerButton
          disabled={selectedRows.length === 0}
          onClick={() => setConfirmDelete(true)}
        >
          Xóa
        </DangerButton>
        <div className="page-title">Danh sách số lượng áp dụng Flash sale</div>
      </div>
      <TableContainer component={Paper} style={{ maxHeight: 377 }}>
        <Table padding="normal" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < details.length
                  }
                  checked={
                    details.length > 0 && selectedRows.length === details.length
                  }
                  onChange={checkedAll}
                />
              </TableCell>
              <TableCell>Min</TableCell>
              <TableCell>Max</TableCell>
              <TableCell>Giá giảm</TableCell>
              <TableCell>Giá sau giảm</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((discountItem) => {
              return (
                <TableRow key={discountItem.id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(discountItem?.id)}
                      onChange={(e) => checkedRow(e, discountItem)}
                    />
                  </TableCell>
                  <TableCell>{discountItem?.min}</TableCell>
                  <TableCell>{discountItem?.max}</TableCell>
                  <TableCell>{discountItem?.type === 2 ? formatVND(discountItem?.percentage  ?? discountItem?.discount) : discountItem?.percentage + "%" } </TableCell>
                  <TableCell>
                    {formatVND(productPrice -
                     (discountItem?.type === 2 ? (discountItem?.percentage ?? discountItem?.discount) :( (productPrice * discountItem?.percentage) / 100)))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setDiscount(discountItem);
                        setEditModal(true);
                        handleEdit(discountItem?.id);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default Detailflashsale;
