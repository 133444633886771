import { createSlice } from "@reduxjs/toolkit";

import { createRank, deleteRanks, editRank, getRankId, getRankList } from "app/services/ranking";
import { PAGE_SIZE } from "app/utils/constant";
export const fetchListRank = (params) => async (dispatch, getState) => {
  try {
    const response = await getRankList(params);
    let payload = {
      listRank: response.data?.data || [],
      totalRanks: response.data?.total || 0,
    };
    dispatch(updateDataRank(payload));
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchRank = (params) => async (dispatch, getState) => {
  try {
    const response = await getRankId(params);
    let payload = {
      coupon: response.data?.data || {},
    };
    dispatch(updateDataRank(payload));
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchCreateRank = (params) => async (dispatch, getState) => {
  try {
    const response = await createRank(params);
    const state = getState().coupon;
    const listRank = state.listRank || [];
    if (listRank.length) {
      let payloadListRank = {
        listRank: [
          {
            ...(response?.data?.data || {}),
            ...params,
            start_date: params.start_date,
            start_time: params.start_time,
            end_date: params.end_date,
            end_time: params.end_time,
            translates: params.contents,
            requirements:
              params.type === 1
                ? params.order_requirements
                : params.product_requirements,
          },
          ...listRank,
        ],
      };
      dispatch(updateDataRank(payloadListRank));
    } else {
      dispatch(fetchListRank({ page: 1, limit: PAGE_SIZE }));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchUpdateRank = (params) => async (dispatch, getState) => {
  try {
    const response = await editRank(params);
    const state = getState().coupon;
    const listRank = state.listRank || [];
    if (listRank.length) {
      let payloadListRank = {
        listRank: [
          ...listRank.map((item) => {
            if (item.id === +params.id) {
              return {
                ...params,
                start_date: params.start_date,
                start_time: params.start_time,
                end_date: params.end_date,
                end_time: params.end_time,
                translates: params.contents,
                requirements:
                  params.type === 1
                    ? params.order_requirements
                    : params.product_requirements,
              };
            } else {
              return item;
            }
          }),
        ],
      };
      dispatch(updateDataRank(payloadListRank));
    } else {
      dispatch(fetchListRank({ page: 1, limit: PAGE_SIZE }));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchDeleteRank = (params) => async (dispatch, getState) => {
  try {
    const { id } = params;
    const response =  await deleteRanks({ id: id })
    return response;
  } catch (error) {
    return error;
  }
};
export const setupRank = createSlice({
  name: "rank",
  initialState: {
    listRank: [],
    totalRanks: 0,
    coupon: {},
  },
  reducers: {
    updateDataRank: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateDataRank } = setupRank.actions;
export default setupRank.reducer;
