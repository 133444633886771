import axios from "app/axios";
import { apiAddVariantValue, apiListProductCoupon, apiVariantsColor } from "app/axios/urlApi";

export const fetchProductsCouponAxios = (params) => {
  return axios.get(`${apiListProductCoupon}`, { params });
};

export const getAllVariantsColor = () => {
  return axios.get(`${apiVariantsColor}`);
}

export const addValueVariant = (params) => {
  return axios.post(`${apiAddVariantValue}/${params?.id}/values`, params?.data);
} 

export const checkDataImportNewColor = async (params) =>{
  try{
    const response = await addValueVariant(params);
    return response;
  }catch(err){
    return err;
  }
} 
