import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextEditors from "app/components/TextEditors";
import * as Utils from "app/utils";
import { formatPrice } from "app/utils/validate";
import lodash from "lodash";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { ProductCreationContext } from "../context";
import ColorCondition from "./ColorAdd";
import PrimaryButton from "app/components/Button/PrimaryButton";
import { Add, PrintRounded } from "@material-ui/icons";
import {
  addValueVariant,
  checkDataImportNewColor,
  getAllVariantsColor,
} from "app/services/product";
import { useHistory, useParams } from "react-router-dom";
import ConfirmModalImportColor from "./customModal/ConfirmImportColor";
import StatusModalImportColor from "./customModal/StatusImportColor";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: "10px 0",
    marginBottom: "10px",
    // fontSize: '14px'
  },
  gridItemLeft: {
    padding: "0px 10px 0px 0px",
    margin: "auto",
  },
  gridItemLeftCheckbox: {
    padding: "2vw 10px 0px 0px",
    margin: "auto",
  },
  gridItemRight: {
    padding: "0px 0px 0px 10px",
  },
  featureProductCount: {
    color: "#ED0017",
    fontWeight: "italic",
  },
  input: {
    width: "100%",
    fontSize: "14px !important",
  },
  textarea: {
    width: "100%",
    fontSize: "14px",
  },
  label: {
    marginBottom: "5px",
  },
  inputCheckbox: {
    width: "30%",
    fontSize: "14px !important",
  },
  noBorder: {
    border: "none",
  },
}));

const objectCondition = {
  price: null,
  attributes: [
    {
      id: 1,
      values: [0],
    },
  ],
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
    />
  );
};

export default function Content({
  listChildCate,
  currentParent,
  setCurrentParent,
  creationFormErrorMessages,
  setCreationFormErrorMessages,
  lan,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [colorConditionList, setColorConditionList] = useState([]);
  const [validate, setValidate] = useState(false);
  const [dataVariants, setDataVariants] = useState();
  const [dataImportColor, setDataImportColor] = useState();
  const [isAddColorError, setIsAddColorError] = useState(false);
  const [messageAddColorError, setMessageAddColorError] = useState("");
  const [confirmImportColor, setConfirmImportColor] = useState(false);
  const [statusAlert, setStatusAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("error");

  const context = useContext(ProductCreationContext);
  const params = useParams();
  const idDetailProd = params?.id;

  useEffect(async () => {
    const res = await getAllVariantsColor();
    setDataVariants(res.data);
  }, []);

  const {
    state: { detail },
  } = context;

  const addBill = () => {
    let condition = [...colorConditionList];
    condition.push({
      key: colorConditionList.length,
      ...objectCondition,
    });
    setColorConditionList(condition);
  };
  const handleChange = (evt) => {
    const { dispatch } = context;
    if (
      evt.target.name === "product_quantity" ||
      evt.target.name === "product_price"
    ) {
      const value = parseInt(evt.target.value);
      dispatch({
        type: "updateDetailInput",
        payload: {
          key: evt.target.name,
          value,
        },
      });
    }
    dispatch({
      type: "updateDetailInput",
      payload: {
        key: evt.target.name,
        value: evt.target.value,
      },
    });
    if (
      evt.target.name === "width" ||
      evt.target.name === "height" ||
      evt.target.name === "length"
    ) {
      setCreationFormErrorMessages({
        ...creationFormErrorMessages,
        size: "",
      });
    } else {
      if (evt.target.value !== "") {
        setCreationFormErrorMessages({
          ...creationFormErrorMessages,
          [evt.target.name]: "",
        });
      }
    }
  };

  const handleChangeCate = (evt) => {
    const { dispatch } = context;
    dispatch({
      type: "updateDetailInput",
      payload: {
        key: evt.target.name,
        value: evt.target.value,
      },
    });
    setCreationFormErrorMessages({
      ...creationFormErrorMessages,
      product_category: "",
    });
    const newParent = lodash.find(listChildCate, {
      id: evt.target.value,
    });
    const parent_obj = Utils.getSafeValue(newParent, "parent_obj", {});
    const translates = Utils.getSafeValue(parent_obj, "translates", []);
    const newParentName = Utils.getField(translates, "vi", "name");
    setCurrentParent(newParentName);
  };

  const handleSetCOD = (evt) => {
    const { dispatch } = context;
    console.log(evt.target.checked, "checked");
    dispatch({
      type: "updateDetailInput",
      payload: {
        key: "allow_cod",
        value: evt.target.checked,
      },
    });
  };

  const {
    product_code,
    product_name,
    product_price,
    product_category,
    product_parent_category,
    product_status,
    short_desc,
    product_description,
    width,
    height,
    length,
    weight,
    allow_cod,
    product_name_en,
    short_desc_en,
    product_quantity,
    product_description_en,
    product_slug,
    product_slug_en,
    title_seo,
    title_seo_en,
    description_seo,
    description_seo_en,
    redirect_slug_302,
    redirect_slug_302_en,
    redirect_slug,
    redirect_slug_en,
    attributes,
    product_avg_price,
    import_from_sap,
  } = detail;
  // console.log("detail1 =", detail);
  // const [isColor, setIsColor] = useState(false);
  // useEffect(() => {
  //   if (attributes == []) {
  //     setIsColor(false);
  //   } else {
  //     if (attributes.find((attriItem) => (attriItem.id = 1))) setIsColor(true);
  //     else setIsColor(false);
  //   }
  // }, [attributes]);

  useEffect(() => {
    if (idDetailProd) {
      const a = [];
      product_avg_price?.forEach((item1, index) => {
        a.push({
          key: index,
          price: item1.price,
          sap_material_code: item1?.sap_material_code,
          attributes: [
            {
              id: 1,
              values: [item1.attributes[0].values[0]],
            },
          ],
        });
      });
      setColorConditionList(a);
    }
  }, [product_avg_price]);

  useEffect(() => {
    const { dispatch } = context;
    dispatch({
      type: "updateDetailInput",
      payload: {
        key: "prices",
        value: colorConditionList,
      },
    });
  }, [colorConditionList]);

  const removeColor = (index) => {
    let condition = [...colorConditionList];
    condition.splice(index, 1);
    setColorConditionList(condition);
  };

  const handleImportColor = (event) => {
    if (!event.target.value && event.target.value.length < 2) {
      setIsAddColorError(false);
      setMessageAddColorError("");
      setDataImportColor(null);
    } else {
      setIsAddColorError(false);
      setDataImportColor(event.target.value);
    }
  };

  const handleSubmitColor = async () => {
    const dataForm = {
      translates: [
        {
          language_code: "vi",
          language_field: "name",
          language_value: dataImportColor,
        },
        {
          language_code: "en",
          language_field: "name",
          language_value: dataImportColor,
        },
      ],
    };
    setConfirmImportColor(false);
    checkDataImportNewColor({ id: 1, data: dataForm }).then((res) => {
      if (res?.status === 201) {
        setIsAddColorError(false);
        if (lan === "vi") {
          setMessageAddColorError("Thêm thành công");
        } else {
          setMessageAddColorError("Add successful");
        }
        setStatusAlert(true);
        setTypeAlert("success");
      } else if (res?.statusCode === 409) {
        setIsAddColorError(true);
        setMessageAddColorError("Thêm không thành công, màu thêm bị trùng");
        if (lan === "vi") {
          setMessageAddColorError("Thêm không thành công, màu thêm bị trùng");
        } else {
          setMessageAddColorError("Add color unsuccessful, duplicate color");
        }
        setStatusAlert(true);
        setTypeAlert("error");
      } else {
        setIsAddColorError(true);
        if (lan === "vi") {
          setMessageAddColorError(
            "Thêm không thành công, dữ liệu không hợp lệ"
          );
        } else {
          setMessageAddColorError(
            "Add color unsuccessful, wrong input color name"
          );
        }
        setStatusAlert(true);
        setTypeAlert("error");
      }
    });
    const res = await getAllVariantsColor();
    setDataVariants(res.data);
  };

  return (
    <>
      <ConfirmModalImportColor
        isOpen={confirmImportColor}
        onClose={() => {
          setConfirmImportColor(false);
        }}
        onSubmit={handleSubmitColor}
        title="Thêm màu sắc"
        children="Bạn có chắc thêm màu đã nhập"
        cancelTitle="HỦY BỎ"
        okText="ĐỒNG Ý"
      />
      <StatusModalImportColor
        isOpen={statusAlert}
        onClose={() => setStatusAlert(false)}
        title="Thông báo"
        children={messageAddColorError}
        okText="OK"
        type={typeAlert}
      />
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>
            {lan === "vi" ? "Mã sản phẩm" : "Product code"} *
          </p>
          <TextField
            variant="outlined"
            className={classes.input}
            placeholder={
              lan === "vi" ? "Nhập mã sản phẩm" : "Enter product code"
            }
            name="product_code"
            value={product_code}
            onChange={handleChange}
            error={creationFormErrorMessages.product_code !== ""}
            helperText={creationFormErrorMessages.product_code}
            size="small"
            disabled={import_from_sap === 1}
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}>
          <p className={classes.label}>
            {lan === "vi" ? "Trạng thái" : "Status"} *
          </p>
          <FormControl
            variant="outlined"
            className={classes.input}
            size="small"
          >
            <Select
              value={product_status}
              onChange={handleChange}
              name="product_status"
              // disabled={import_from_sap === 1}
            >
              <MenuItem value={false}>Ẩn</MenuItem>
              <MenuItem value={true}>Hiện</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>
            {lan === "vi" ? "Tên sản phẩm *" : "Product name"}
          </p>
          {lan === "vi" ? (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Nhập tên sản phẩm"
              name="product_name"
              value={product_name}
              onChange={handleChange}
              error={creationFormErrorMessages.product_name !== ""}
              helperText={creationFormErrorMessages.product_name}
              size="small"
              disabled={import_from_sap === 1}
            />
          ) : (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Enter product name"
              name="product_name_en"
              value={product_name_en}
              onChange={handleChange}
              size="small"
              disabled={import_from_sap === 1}
              error={
                // creationFormErrorMessages.short_desc_en !== "" ||
                creationFormErrorMessages.product_name_en !== ""
                // creationFormErrorMessages.product_description_en !== ""
              }
              helperText={creationFormErrorMessages.product_name_en}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}>
          <p className={classes.label}>
            {lan === "vi" ? "Giá bán" : "Price"} *
          </p>
          <TextField
            variant="outlined"
            className={classes.input}
            name="product_price"
            value={product_price}
            onChange={handleChange}
            disabled={colorConditionList.length || import_from_sap === 1}
            // helperText={creationFormErrorMessages.product_price}
            // error={creationFormErrorMessages.product_price !== ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>
                    <strong>VND</strong>
                  </span>
                </InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
            }}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>
            {lan === "vi" ? "Danh mục con" : "Subcategory"} *
          </p>
          <FormControl
            variant="outlined"
            className={classes.input}
            size="small"
          >
            <Select
              value={product_category}
              onChange={handleChangeCate}
              name="product_category"
              defaultValue={product_category}
              disabled={import_from_sap === 1}
            >
              {listChildCate.length > 0 &&
                listChildCate.map((item) => {
                  const id = Utils.getSafeValue(item, "id", 0);
                  const translates = Utils.getSafeValue(item, "translates", []);
                  const name = Utils.getField(translates, "vi", "name");
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {creationFormErrorMessages.product_category !== "" && (
            <div
              style={{
                fontSize: 12,
                color: "red",
                marginTop: 3,
                marginLeft: 15,
              }}
            >
              {creationFormErrorMessages.product_category}
            </div>
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}>
          <p className={classes.label}>
            {lan === "vi" ? "Danh mục cha" : "Parent category"}
          </p>
          <TextField
            disabled
            variant="outlined"
            className={classes.input}
            name="product_parent_category"
            value={currentParent}
            defaultValue={product_parent_category}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>
            {lan === "vi" ? "Kích thước" : "Size"} (cm)*
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              border:
                creationFormErrorMessages.size === ""
                  ? "1px solid #c4c4c4"
                  : "1px solid #f44336",
              borderRadius: "4px",
              marginTop: "-1px",
              marginBottom: "-1px",
            }}
          >
            <TextField
              disabled={import_from_sap === 1}
              type="number"
              step={0.01}
              className={classes.inputCheckbox}
              variant="outlined"
              name="length"
              value={length}
              placeholder={lan === "vi" ? "Dài" : "Length"}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
              }}
              size="small"
            />
            <span style={{ margin: "auto" }}>X</span>
            <TextField
              disabled={import_from_sap === 1}
              type="number"
              step={0.01}
              className={classes.inputCheckbox}
              variant="outlined"
              name="width"
              value={width}
              placeholder={lan === "vi" ? "Rộng" : "Width"}
              onChange={handleChange}
              inputProps={{ style: { textAlign: "center" } }}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
              }}
              size="small"
            />
            <span style={{ margin: "auto" }}>X</span>
            <TextField
              disabled={import_from_sap === 1}
              type="number"
              step={0.01}
              className={classes.inputCheckbox}
              variant="outlined"
              name="height"
              value={height}
              onChange={handleChange}
              placeholder={lan === "vi" ? "Cao" : "Height"}
              inputProps={{ style: { textAlign: "center" } }}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
              }}
              size="small"
            />
          </div>

          {creationFormErrorMessages.size !== "" && (
            <div
              style={{
                fontSize: "0.75rem",
                color: "#f44336",
                marginTop: 4,
                marginLeft: 15,
              }}
            >
              {creationFormErrorMessages.size}
            </div>
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}>
          <p className={classes.label}>
            {lan === "vi" ? "Khối lượng" : "Weight"} (g)*
          </p>
          <TextField
            disabled={import_from_sap === 1}
            variant="outlined"
            type="number"
            step={0.01}
            className={classes.input}
            placeholder={lan === "vi" ? "Nhập khối lượng" : "Enter weight"}
            name="weight"
            value={weight}
            onChange={handleChange}
            helperText={creationFormErrorMessages.weight}
            error={creationFormErrorMessages.weight !== ""}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeftCheckbox}>
          <FormControlLabel
            style={{
              marginRight: "5px",
            }}
            control={
              <Checkbox
                checked={allow_cod === true || allow_cod === 1}
                onChange={handleSetCOD}
                disabled={import_from_sap === 1}
              />
            }
            label={lan === "vi" ? "Cho phép COD" : "Allow COD"}
            size="small"
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}></Grid>
      </Grid>

      <div>
        <div className="page-title">
          {lan === "vi" ? "Bảng màu" : "Color board"}
        </div>
      </div>
      <Grid
        container
        className={classes.grid}
        style={{ minWidth: 300 }}
        space={4}
      >
        <Fragment>
          {Array.isArray(colorConditionList) &&
            colorConditionList.map((item, index) => (
              <ColorCondition
                dataVariants={dataVariants[0]?.values}
                validate={validate}
                isDisabled={import_from_sap}
                index={index}
                colorCondition={item}
                lang={lan}
                detail={detail}
                onRemove={removeColor}
                colorConditionList={colorConditionList}
                setColorConditionList={setColorConditionList}
                setValidate={setValidate}
              />
            ))}
          <PrimaryButton
            startIcon={<Add />}
            onClick={addBill}
            // style={{ marginTop: '15px'}}
            disabled={import_from_sap === 1}
          >
            Thêm màu sắc
          </PrimaryButton>
        </Fragment>
      </Grid>

      <div>
        <div className="page-title">
          {lan === "vi"
            ? "Thêm màu sắc khác (Bỏ qua nếu không cần)"
            : "Add more Color"}
        </div>
      </div>

      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>
            {lan === "vi" ? "Tên màu muốn thêm" : "New Color name"}
          </p>
          {lan === "vi" && (
            <TextField
              disabled={import_from_sap === 1}
              variant="outlined"
              className={classes.input}
              placeholder={
                lan === "vi" ? "Nhập tên màu sắc thêm" : "Type new name color"
              }
              onChange={(e) => handleImportColor(e)}
              error={isAddColorError}
              helperText={isAddColorError ? messageAddColorError : null}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}>
          <PrimaryButton
            style={{ marginTop: 35 }}
            startIcon={<Add />}
            onClick={() => setConfirmImportColor(true)}
            disabled={import_from_sap === 1}
          >
            {lan === "vi" ? "Thêm" : "Add"}
          </PrimaryButton>
        </Grid>
      </Grid>

      <div>
        <div className="page-title">
          {lan === "vi" ? "Thông tin thêm" : "More Information"}
        </div>
      </div>
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>Slug</p>
          {lan === "vi" && (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Nhập slug sản phẩm"
              name="product_slug"
              value={product_slug}
              onChange={handleChange}
              error={creationFormErrorMessages.product_slug !== ""}
              helperText={creationFormErrorMessages.product_slug}
              size="small"
            />
          )}
          {lan === "en" && (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Enter product slug"
              name="product_slug_en"
              value={product_slug_en}
              onChange={handleChange}
              error={creationFormErrorMessages.product_slug_en !== ""}
              helperText={creationFormErrorMessages.product_slug_en}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}></Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={6} className={classes.gridItemLeft}>
          <p className={classes.label}>Redirect Slug 302</p>
          {lan === "vi" && (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Nhập redirect slug 302"
              name="redirect_slug_302"
              value={redirect_slug_302}
              onChange={(e) => {
                const { dispatch } = context;
                dispatch({
                  type: "updateDetailInput",
                  payload: {
                    key: "redirect_slug_302",
                    value: e.target.value,
                  },
                });
              }}
              helperText={creationFormErrorMessages.redirect_slug_302}
              size="small"
            />
          )}
          {lan === "en" && (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Enter product slug 302"
              name="redirect_slug_302_en"
              value={redirect_slug_302_en}
              onChange={(e) => {
                const { dispatch } = context;
                dispatch({
                  type: "updateDetailInput",
                  payload: {
                    key: "redirect_slug_302_en",
                    value: e.target.value,
                  },
                });
              }}
              helperText={creationFormErrorMessages.redirect_slug_302_en}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridItemRight}>
          <p className={classes.label}>Redirect Slug 301</p>
          {lan === "vi" && (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Nhập redirect slug 301"
              name="redirect_slug"
              value={redirect_slug}
              onChange={(e) => {
                const { dispatch } = context;
                dispatch({
                  type: "updateDetailInput",
                  payload: {
                    key: "redirect_slug",
                    value: e.target.value,
                  },
                });
              }}
              helperText={creationFormErrorMessages.redirect_slug}
              size="small"
            />
          )}
          {lan === "en" && (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Enter product slug 302"
              name="redirect_slug_en"
              value={redirect_slug_en}
              onChange={(e) => {
                const { dispatch } = context;
                dispatch({
                  type: "updateDetailInput",
                  payload: {
                    key: "redirect_slug_en",
                    value: e.target.value,
                  },
                });
              }}
              helperText={creationFormErrorMessages.redirect_slug_en}
              size="small"
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <p className={classes.label}>
            {lan === "vi" ? "Mô tả ngắn *" : "Short description *"}
          </p>
          {lan === "vi" ? (
            <TextField
              type="text"
              disabled={import_from_sap === 1}
              variant="outlined"
              className={classes.input}
              multiline
              rows={3}
              name="short_desc"
              placeholder="Nhập mô tả ngắn"
              value={short_desc}
              onChange={handleChange}
              error={creationFormErrorMessages.short_desc !== ""}
              helperText={creationFormErrorMessages.short_desc}
            />
          ) : (
            <TextField
              type="text"
              variant="outlined"
              className={classes.input}
              disabled={import_from_sap === 1}
              multiline
              rows={3}
              name="short_desc_en"
              placeholder="Enter short description"
              value={short_desc_en}
              onChange={handleChange}
              error={creationFormErrorMessages.short_desc_en !== ""}
              helperText={creationFormErrorMessages.short_desc_en}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <p className={classes.label}>
            {lan === "vi" ? "Mô tả chi tiết *" : "Detail description *"}
          </p>
          {lan === "vi" && (
            <div>
              <TextEditors
                // disabled={import_from_sap === 1}
                defaultValue={product_description}
                onChange={(content) => {
                  const { dispatch } = context;
                  dispatch({
                    type: "updateDetailInput",
                    payload: {
                      key: "product_description",
                      value: content,
                    },
                  });
                  setCreationFormErrorMessages({
                    ...creationFormErrorMessages,
                    product_description: "",
                  });
                }}
              />
              {creationFormErrorMessages.product_description !== "" && (
                <div
                  style={{
                    fontSize: 12,
                    color: "red",
                    marginTop: 3,
                    marginLeft: 15,
                  }}
                >
                  {creationFormErrorMessages.product_description}
                </div>
              )}
            </div>
          )}
          {lan === "en" && (
            <div>
              <TextEditors
                // disabled={import_from_sap === 1}
                defaultValue={product_description_en}
                onChange={(content) => {
                  const { dispatch } = context;
                  dispatch({
                    type: "updateDetailInput",
                    payload: {
                      key: "product_description_en",
                      value: content,
                    },
                  });
                  setCreationFormErrorMessages({
                    ...creationFormErrorMessages,
                    product_description_en: "",
                  });
                }}
              />
              {creationFormErrorMessages.product_description_en !== "" && (
                <div
                  style={{
                    fontSize: 12,
                    color: "red",
                    marginTop: 3,
                    marginLeft: 15,
                  }}
                >
                  {creationFormErrorMessages.product_description_en}
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <p className={classes.label}>
            {lan === "vi" ? "Tiêu đề SEO *" : "Title SEO *"}
          </p>
          {lan === "vi" ? (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Nhập tiêu đề SEO"
              name="title_seo"
              value={title_seo}
              onChange={handleChange}
              error={creationFormErrorMessages.title_seo !== ""}
              helperText={creationFormErrorMessages.title_seo}
              size="small"
            />
          ) : (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Enter title SEO"
              name="title_seo_en"
              value={title_seo_en}
              onChange={handleChange}
              error={creationFormErrorMessages.title_seo_en !== ""}
              helperText={creationFormErrorMessages.title_seo_en}
              size="small"
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <p className={classes.label}>
            {lan === "vi" ? "Mô tả SEO *" : "Description SEO *"}
          </p>
          {lan === "vi" ? (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Nhập mô tả SEO"
              name="description_seo"
              value={description_seo}
              onChange={handleChange}
              error={creationFormErrorMessages.description_seo !== ""}
              helperText={creationFormErrorMessages.description_seo}
              size="small"
            />
          ) : (
            <TextField
              variant="outlined"
              className={classes.input}
              placeholder="Enter description SEO"
              name="description_seo_en"
              value={description_seo_en}
              onChange={handleChange}
              error={creationFormErrorMessages.description_seo_en !== ""}
              helperText={creationFormErrorMessages.description_seo_en}
              size="small"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
