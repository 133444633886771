import {
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Modal,
  Select as SelectLib,
  MenuItem,
  OutlinedInput,
  FormControl,
  Divider,
} from "@material-ui/core";
import TextFields from "app/components/TextFields";
import {
  createFlashSaleProduct as _createFlashSaleProduct,
  getProductList as _getProductList,
  PRODUCT_STATUS,
  updateFlashSaleProduct,
} from "app/services/flashsale";
import { formatVND, isNumeric } from "app/utils/common";
import { LANG_VI } from "app/utils/constant";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Autocomplete from "../components/Autocomplete";
import Detailflashsale from "../components/Detailflashsale";
import Input from "../components/Input";
import Select from "../components/Select";
import { formatPrice } from "app/utils/validate";

export const flashSalesStructureForm = {
  flashSaleId: "",
  productId: "",
  productName: "",
  productQuantity: "",
  productPrice: "",
  productReducedPrice: -1,
  promo_sapCodeId: "",
  promo_color: "",
  promo_type_discount: 0,
  productReducePrice: -1,
  productStatus: "",
  productRemaining: 0,
  productDetailsDiscount: [],
  productMaxPerCus: 0,
};

const LABELS = [
  "",
  "",
  "Sản phẩm",
  "Số lượng",
  "Giá sản phẩm",
  "Phần trăm giảm",
  "Giá giảm",
  "Giá sau giảm",
  "Trạng thái",
];
function AddProduct({ isCreate, isOpen, onClose, onSubmit, defaultForm }) {
  const urlParams = useParams();
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [colorList, setColorList] = useState();
  const [conditionPrice, setConditionPrice] = useState({ value: null });
  console.log(defaultForm)
  const [errorDiscount, setErrorDiscount] = useState({
    min: "",
    max: "",
    discount: "",
  });

  const [details, setDetails] = useState([
    ...defaultForm.productDetailsDiscount,
  ]);
  const [alertError, setAlertError] = useState({
    error: "",
    isShow: false,
  });
  const [form, setForm] = useState(() => {
    console.log("defaultForm", defaultForm)
    if (defaultForm) {
      const { productReducePrice, productReducedPrice } = calcDiscount(
        defaultForm.productPrice,
        defaultForm.productDiscount,
        defaultForm?.promo_type_discount,
        false
      );
      defaultForm = {
        ...defaultForm,
        productReducePrice,
        productReducedPrice: "0",
        min: 0,
        max: 0,
      };
      console.log("defaultForm", defaultForm)

      return defaultForm;
    }
    return flashSalesStructureForm;
  });
  console.log("form", form)
  const handleAddDiscount = () => {
    const percentage = Number(conditionPrice?.value);
    console.log(percentage)
    const min = Number(form.min);
    const max = Number(form.max);
    setErrorDiscount({
      min: "",
      max: "",
      discount: "",
    });

    if (!min) {
      setErrorDiscount({
        ...errorDiscount,
        min: "min phải có",
      });
      return;
    }

    // if (details.length && !max) {
    //   setErrorDiscount({
    //     ...errorDiscount,
    //     max: "max phải có",
    //   });
    //   return;
    // }
    if (percentage < 0 && form?.promo_type_discount === 1) {
      setErrorDiscount({
        ...errorDiscount,
        discount: "Phần trăm giảm không nhỏ hơn 0",
      });
      return;
    }
    if (max && min > max) {
      setErrorDiscount({
        ...errorDiscount,
        min: "min không được lớn hơn max",
        max: "min không được lớn hơn max",
      });
      return;
    }
    if (details?.length && !details[details?.length - 1]?.max) {
      setErrorDiscount({
        ...errorDiscount,
        min: "phải set max của discount trước",
      });
      return;
    }
    if (min <= details[details?.length - 1]?.max) {
      setErrorDiscount({
        ...errorDiscount,
        min: "min phải lớn hơn  max trước",
      });
      return;
    }

    setDetails([
      ...details,
      {
        id: Math.random(),
        percentage,
        type: form?.promo_type_discount,
        min,
        max: max ? max : "",
        price: form?.productPrice
      },
    ]);
  };

  const [error, setError] = useState(flashSalesStructureForm);

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = async () => {
    try {
      const request = await _getProductList();
      const { results } = await request.data;
      handleGetProductSuccess(results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetProductSuccess = (data) => {
    const listStructure =
      data.length > 0
        ? data.map((item) => {
          let title = "";
          if (Array.isArray(item?.translates)) {
            title =
              item.translates.find(
                (element) => element?.language_code === LANG_VI
              )?.name || "";
          }
          const totalQuantity = item.product_inventory.reduce(
            (acc, inventory) => acc + inventory.remaining_number,
            0
          );
          return {
            id: item.id,
            title: item.translates.find((item) => LANG_VI === item?.language_code).name,
            price: item?.attributes?.length === 0 ? item?.price : -10,
            attributes: item?.attributes?.length === 0 ? item?.code : item?.attributes?.[0]
          };
        })
        : [];
    setProductList(listStructure);
  };
  let getColors = []

  useEffect(() => {
    if (!isCreate) {

      const getAttributeEdit = productList?.find(item => form.productId === item?.id)

      getColors = typeof (getAttributeEdit?.attributes) === "string" ? [] : getAttributeEdit?.attributes?.values?.map((itemColor) => {
        return {
          price: itemColor?.price,
          id: itemColor?.sap_material_code,
          title: itemColor?.translates.find(itemColorName => itemColorName?.language_code === LANG_VI).language_value,

        }
      })
      setColorList(getColors)
    }
  }, [productList, isCreate, form?.promo_productId])
  const handleAddProduct = () => {
    const isValid = validateForm();

    if (!isValid) return;

    isCreate ? createFlashSaleProduct() : updateFlashSale();
  };

  const createFlashSaleProduct = async () => {
    try {
      if (!urlParams?.id) return;
      const params = {
        id: +urlParams.id,
        sap_material_code: form.promo_sapCodeId,
        product_id: Number(form.productId),
        quantity: Number(form.productQuantity),
        max_per_customer: Number(form.productMaxPerCus),
        status: Number(form.productStatus),
        details: details.map(({ min, max, percentage, type }) => {
          console.log(max)
          if (!max) {
            if (type === 2) {
              return { min, discount: percentage };
            } else {
              return { min, percentage };
            }

          } else {
            if (type === 2) {
              return { min, max, discount: percentage };
            } else {
              return { min, max, percentage };
            }
          }
        }),
      };
      const request = await _createFlashSaleProduct(params);
      const response = await request;
      setError("");
      onSubmit && onSubmit(form);
      onClose && onClose();
    } catch (error) {
      handleErrorApi(error.response.data.errorCode);
    }
  };

  const updateFlashSale = async () => {
    try {

      if (!urlParams?.id) return;
      const params = {
        sap_material_code: form.promo_sapCodeId,

        product_id: Number(form.productId),
        quantity: Number(form.productQuantity),
        max_per_customer: Number(form.productMaxPerCus),
        status: isNaN(form.productStatus)
          ? PRODUCT_STATUS.indexOf(form.productStatus)
          : Number(form.productStatus),
        details: details.map(({ min, max, percentage, type, discount }) => {
          if (!max) {
            if (type === 2) {
              return { min, discount: discount };
            } else {
              return { min, percentage };
            }

          } else {
            if (type === 2) {
              return { min, max, discount: discount };
            } else {
              return { min, max, percentage };
            }
          }
        }),
      };
      console.log(params)
      const request = await updateFlashSaleProduct(
        urlParams?.id,
        form.id,
        params
      );
      const response = await request;
      setError("");
      onSubmit && onSubmit(form);
      onClose && onClose();
    } catch (error) {
      handleErrorApi(error.response.data.errorCode);
    }
  };

  const handleErrorApi = (errorCode) => {
    switch (errorCode) {
      case "flashSale::011":
        setError({
          ...flashSalesStructureForm,
          productName: "Sản phẩm đang giảm giá trong chương trình khác",
        });
        break;
      case "flashSale::012":
        handleAlertError(
          "Số lượng không được lớn hơn số lượng sản phẩm còn lại"
        );
        break;
      default:
        handleAlertError("Something went wrong");
        break;
    }
  };
  console.log("error", error)
  const handleAlertError = (error) => {
    setError("");
    setAlertError((prevState) => ({ isShow: true, error: error }));
  };
  const validateForm = (showError = true) => {
    let isValid = true;

    const isEmpty = Object.keys(form).every((key, val, arr) => {
      if (
        key === "productId" ||
        key === "flashSaleId" ||
        key === "productPrice" ||
        key === "productRemaining" ||
        key === "productDetailsDiscount" ||
        key === "productReducePrice" ||
        key == "promo_sapCodeId" ||
        key == "promo_color" ||

        key === "productDiscount" ||
        key === "min" ||
        key === "max"
      ) {
        return true;
      }
      return form[key] && String(form[key]).length > 0;
    });
    if (!isEmpty) {
      const emptyFields = Object.keys(form).reduce((obj, key, index) => {
        if (
          key === "productId" ||
          key == "promo_sapCodeId" ||
          key == "promo_color" ||

          key === "flashSaleId" ||
          key === "productPrice" ||
          key === "productRemaining" ||
          key === "productDetailsDiscount" ||
          key === "productReducePrice" ||
          // key == "promo_type_discount" ||
          key === "min" ||
          key === "max"
        ) {
          return { ...obj, [key]: true };
        }
        return {
          ...obj,
          [key]:
            String(form[key]).length <= 0 && `${LABELS[index]} được yêu cầu`,
        };
      }, {});
      console.log("emptyFields", emptyFields, form);
      showError && setError(emptyFields);

      isValid = false;
    }
    if (form.productRemaining) {
      // if (form.productQuantity == 0) {
      //   setError(prevState => ({ ...prevState, productQuantity: `Số lượng phải lớn hơn 0` }));
      //   isValid = false;
      // }
      if (form.productQuantity > form.productRemaining) {
        setError((prevState) => ({
          ...prevState,
          productQuantity: `Số lượng còn lại không đủ, số lượng còn lại: ${form.productRemaining}`,
        }));
        isValid = false;
      }
    } else {
      const product = productList.filter((i) => i.id == form.productId);
      const totalQuantity = product.reduce(
        (acc, item) => acc + item.remaining_number,
        0
      );
      if (form.productQuantity == 0) {
        setError((prevState) => ({
          ...prevState,
          productQuantity: `Số lượng phải lớn hơn 0`,
        }));
        isValid = false;
      }
      if (form.productQuantity > totalQuantity) {
        setError((prevState) => ({
          ...prevState,
          productQuantity: `Số lượng còn lại không đủ, số lượng còn lại: ${totalQuantity}`,
        }));
        isValid = false;
      }
    }
    if (!conditionPrice?.value) {
      setError((prevState) => ({
        ...prevState,
        conditionPrice: "Giá trị giảm không hợp lệ",
      }));
      isValid = false;
    }
    if (!error?.promo_type_discount && !isCreate) {
      delete error?.promo_type_discount
      delete error?.conditionPrice
      isValid = true;
    }
    if (form.productDiscount > 100 && form?.promo_type_discount === 1) {
      setError((prevState) => ({
        ...prevState,
        productDiscount: "Phần trăm giảm không hợp lệ",
      }));
      isValid = false;
    }
    return isValid;
  };
  const handleClose = () => {
    onClose && onClose();
  };
  useEffect(() => {
    if (conditionPrice) {
      delete error?.conditionPrice
      calcDiscount(form?.productPrice, conditionPrice?.value, form?.promo_type_discount);
    }
  }, [conditionPrice])
  const onChaneDiscount = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (/^0{2,}/.test(value) || /\D+/.test(value)) {
      return;
    }
    if (form?.promo_type_discount === 1) {
      if (e.target.value <= 100) {
        if (parseInt(e.target.value) > 100) {
          setConditionPrice({ value: "100" });
        } else {
          setConditionPrice({ value: e.target.value });
        }
      }
    } else {
      if (form?.productPrice < value) {
        value = form?.productPrice
      }
      if (/^0[1-9]/.test(value)) {
        setConditionPrice({ value: value.slice(1) });
      } else {
        setConditionPrice({ value: value });
      }
    }
  };
  const handleChange = (key, val) => {
    if (key === "promo_color" && val) {
if(val) delete error?.promo_color
      
      console.log("hello 1", val)
      setForm((prevState) => ({
        ...form,
        promo_color: val.title || form?.promo_color?.title,
        promo_sapCodeId: val?.id ?? form?.promo_sapCodeId,
        productPrice: val?.price !== -10 ? (val.price || prevState.productPrice) : "",
      }));
      calcDiscount(val.price, form.productDiscount || 0);
    }
    if(key === "productStatus" && val){
if(val) delete error?.productStatus

      setForm((prevState) => ({ ...prevState, [key]: val }));
    }
    if(key === "productQuantity" && val){
      if(val) delete error?.productQuantity
      
            setForm((prevState) => ({ ...prevState, [key]: val }));
          }
    if (key === "productName" && val) {
      console.log("hello 1", val)
if(val) delete error?.productName
      const price = val.price || defaultForm.productPrice;
      const discount = form.productDiscount || defaultForm.productDiscount;
      const id = val.id || defaultForm.productId;
      let getColor = []
      const remaining = val.remaining || defaultForm.productRemaining;
      if (isCreate) {
        getColor = typeof (val?.attributes) === "string" ? [] : val?.attributes?.values?.map((itemColor) => {

          return {
            price: itemColor?.price,
            id: itemColor?.sap_material_code,
            title: itemColor?.translates.find(itemColorName => itemColorName?.language_code === LANG_VI).language_value,

          }
        })
        getColors = getColor

        setColorList(getColors)


      }
      if (typeof (val?.attributes) === "string") {
        setForm((prevState) => ({
          ...prevState,
          productName: val.title,
          promo_color: "",
          promo_sapCodeId: val?.attributes,
          productId: id,
          productPrice: val?.price !== -10 ? price : "",
          productRemaining: remaining,
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          productName: val.title,
          promo_color: "",
          promo_sapCodeId: val?.attributes ?? form?.promo_sapCodeId,
          productId: id,
          // productReducedPrice:val?.price !== -10 ? prevState.productReducedPrice : "",
          productPrice: val?.price !== -10 ? price : "",
          productRemaining: remaining,
        }));

      }

      // calcDiscount(price, discount);
      if (typeof (val?.attributes) === "string") {

        calcDiscount(price, discount);

      }
      return;
    }
    if (key === "productPrice") {
      console.log("hello 1")

      calcDiscount(val, form.productDiscount);
      return;
    }
    if (key === "promo_type_discount") {
      console.log("hello 1")
      if (val) delete error?.promo_type_discount
      setForm((prevState) => ({ ...prevState, [key]: val }));


      return;
    }
    if (key === "productStatus") {
      val = val.index;
      setForm((prevState) => ({ ...prevState, [key]: String(val).trim() }));
      return;
    }

    if (key === "min") {
      setForm((prevState) => ({ ...prevState, [key]: String(val).trim() }));
      return;
    }
    if (key === "max") {
      setForm((prevState) => ({ ...prevState, [key]: String(val).trim() }));
      return;
    }
    if (key === "productMaxPerCus") {
      setForm((prevState) => ({ ...prevState, [key]: String(val).trim() }));
      return;
    }
    if (key === "productQuantity") {
      setForm((prevState) => ({ ...prevState, [key]: String(val).trim() }));
      return;
    }

    // key !== "productName" &&
    //   setForm((prevState) => ({ ...prevState, [key]: String(val).trim() }));
  };
  function calcDiscount(price, discount = 0, type, isSetForm = true) {
    let productDiscount = discount;
    let priceReduce = 0;
    let priceReduced = 0;
    console.log("conditionPrice", conditionPrice)
    console.log(type)
    const isValidNumber = isNumeric(productDiscount || 0);
    if (!isValidNumber) return;
    if (type === 1) {
      if (parseInt(productDiscount) > 100) {
        productDiscount = "100";
      } else {
        productDiscount = discount;
      }
      // giá giảm
      priceReduce = price * (productDiscount / 100);
      // giá sau giảm

      priceReduced = price - priceReduce;
    } else {
      // giá giảm

      priceReduce = Number(conditionPrice?.value);
      console.log(priceReduce)
      console.log(price)
      // giá sau giảm

      priceReduced = price - priceReduce;
    }
    console.log(priceReduced)
    isSetForm &&
      setForm((prevState) => ({
        ...prevState,
        productDiscount,
        productReducePrice: formatVND(Number(priceReduce).toFixed(), false),
        productReducedPrice: formatVND(Number(priceReduced).toFixed(), false),
      }));

    return {
      productDiscount,
      productReducePrice:
        price && formatVND(Number(priceReduce).toFixed(), false),
      productReducedPrice:
        price && formatVND(Number(priceReduced).toFixed(), false),
    };
  }
  console.log(form)
  const getBorderColor = () => {
    return error?.conditionPrice && isCreate ? '#f44336' : 'transparent';
  };
  return (
    <Modal style={{ overflow: 'scroll' }} open={isOpen} onClose={handleClose}>
      <div className={classes.container} xs={100}>
        <header className={classes.header}>
          <span>{isCreate ? "Thêm sản phẩm" : "Sửa sản phẩm"}</span>
        </header>
        <article className={classes.article}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Grid container>
                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Autocomplete
                        required
                        disabled={!isCreate}
                        error={error.productName}
                        defaultValue={form.productName}
                        data={productList}
                        id="product"
                        labelName="Sản phẩm"
                        placeholder="Chọn sản phẩm"
                        inputSuffix="VND"
                        onChange={(val) => handleChange("productName", val)}
                      />
                    </Grid>
                    <Grid item xs>
                      <Autocomplete
                        required
                        // disabled={!isCreate}
                        error={colorList?.length > 0 && error.promo_color}
                        defaultValue={form.promo_color}
                        forColor={true}
                        data={colorList}
                        id="color"
                        labelName="Màu sắc"
                        placeholder="Chọn màu sắc"
                        // inputSuffix="VND"
                        onChange={(val) => handleChange("promo_color", val)}
                      />
                      {/* <Autocomplete
           disabled={!colorList?.length}
           error={colorList?.length > 0 && error.promo_color}
           defaultValue={form.promo_color}
           data={colorList}
           id="color"
           labelName={labels.promo_color.label}
           placeholder={labels.promo_color.placproductQuantityhandleeholder}
          
           onChange={(val) => handleChange("promo_color", val)}
         /> */}

                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Select
                        defaultValue={form.productStatus}
                        error={error.productStatus}
                        data={PRODUCT_STATUS}
                        id="status"
                        labelName="Trạng thái"
                        placeholder="-Chọn trạng thái-"
                        onChange={(val) => handleChange("productStatus", val)}
                      />
                    </Grid>
                    <Grid item xs>
                      <Input
                        required
                        error={error.productQuantity}
                        id="quantity"
                        defaultValue={form.productQuantity}
                        labelName="Số lượng bán (dự kiến)"
                        type="number"
                        placeholder="Nhập số lượng"
                        onChange={(val) => handleChange("productQuantity", val)}
                      />
                    </Grid>

                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Input
                        required
                        error={error.productMaxPerCus}
                        id="productMaxPerCus"
                        defaultValue={form.productMaxPerCus}
                        labelName="Số lượng tối đa mà một khách hàng được phép mua"
                        type="number"
                        placeholder="Nhập số lượng"
                        onChange={(val) =>
                          handleChange("productMaxPerCus", val)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <div className="page-title">
                      Thêm mới chương trình Flash Sale
                    </div>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Input
                        required
                        // error={error.min}
                        id="min"
                        defaultValue={form.min}
                        error={errorDiscount.min}
                        labelName="Min"
                        type="number"
                        placeholder="Nhập min"
                        onChange={(val) => handleChange("min", val)}
                      />
                    </Grid>
                    <Grid item xs>
                      <Input
                        required
                        // error={error.max}
                        error={errorDiscount.max}
                        id="max"
                        defaultValue={form.max}
                        labelName="Max"
                        type="number"
                        placeholder="Nhập max"
                        onChange={(val) => handleChange("max", val)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <TextFields
                        label="Giá trị giảm giá"
                        helperText="Giá trị giảm giá đươc yêu cầu"
                        error={
                          error?.conditionPrice && isCreate
                        }
                      >
                        <Box display="flex" alignItems="center">
                          <div style={{ border: `1px solid ${getBorderColor()}`, borderRadius: 4, width: '100%', height: 38 }}>

                            <OutlinedInput
                              disabled={!form?.promo_type_discount}
                              style={{ width: "100%", height: 38 }}
                              value={
                                form?.promo_type_discount === 1
                                  ? conditionPrice.value
                                  : formatPrice(conditionPrice.value)
                              }
                              placeholder="Nhập giá trị giảm giá"
                              error={!!error?.conditionPrice && isCreate}
                              onChange={onChaneDiscount}
                              sx={{ flexGrow: 1 }}
                            />
                          </div>

                          <FormControl style={{ minWidth: 70 }}>
                            <SelectLib
                              // disabled={disable || detail}
                              style={{ marginLeft: 1, fontSize: 14, fontWeight: 700, border: "solid 1px #b3b3b3", borderRadius: "4px" }}
                              // name="promo_type_discount"
                              value={form?.promo_type_discount}
                              error={form?.promo_type_discount}

                              onChange={(e) => {
                                setConditionPrice({ value: "" });
                                handleChange("promo_type_discount", e.target.value)
                              }}
                              disableUnderline
                              className={error?.promo_type_discount ? `${classes.select} ${classes.selecError}` : `${classes.select} ${classes.selectNoError}`}

                              MenuProps={{ classes: { paper: classes.menuProps } }}


                            >
                              <MenuItem value={2}>
                                <Box>VNĐ</Box>
                              </MenuItem>
                              <MenuItem value={1}>
                                <Box> % </Box>
                              </MenuItem>
                            </SelectLib>
                          </FormControl>
                        </Box>
                      </TextFields>
                      {/* <TextFields
                        label="Phần trăm giảm"
                        required
                        placeholder="Nhập %"
                        endAdornment={
                          <InputAdornment position="end">
                            <Box color="#3A3A3A" fontWeight="bold">
                              %
                            </Box>
                          </InputAdornment>
                        }
                        value={form.productDiscount}
                        onChange={(e) =>
                          handleChange("productDiscount", e.target.value)
                        }
                        // error={error.productDiscount}
                        error={errorDiscount.discount}
                        helperText={errorDiscount.discount}
                        inputProps={{ maxLength: 5 }}
                      /> */}
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop:"10px"}} container spacing={2}>
                    <div className="page-title">
                      Cập nhật giá
                    </div>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Input
                        required
                        readOnly
                        error={error.productPrice}
                        id="price"
                        defaultValue={formatVND(form.productPrice, false)}
                        labelName="Giá sản phẩm"
                        placeholder="Tự cập nhật"
                        type="number"
                        inputSuffix="VND"
                        onChange={(val) => handleChange("productPrice", val)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Input
                        required
                        id="reduce_price"
                        defaultValue={form.productReducePrice}
                        error={error.productReducePrice}
                        labelName="Giá giảm"
                        placeholder="Tự cập nhật"
                        readOnly
                        inputSuffix="VND"
                      />
                    </Grid>
                    <Grid item xs>
                      <Input
                        required
                        inputClassName={
                          form.productReducedPrice &&
                          classes.productReducedPriceInput
                        }
                        labelClassName={
                          form.productReducedPrice &&
                          classes.productReducedPriceLabel
                        }
                        id="reduced_price"
                        defaultValue={form.productReducedPrice}
                        error={error.productReducedPrice}
                        labelName="Giá sau giảm"
                        placeholder="Tự cập nhật"
                        readOnly
                        inputSuffix="VND"
                      />
                    </Grid>
                  </Grid>


                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Detailflashsale
                handleAddDiscount={handleAddDiscount}
                details={details}
                setDetails={setDetails}
                productPrice={form.productPrice}
              />
            </Grid>
          </Grid>
        </article>
        {alertError.isShow && (
          <p className={classes.error}>{alertError.error}</p>
        )}
        <footer className={classes.footer}>
          <Grid className={classes.buttons} container spacing={2}>
            <Grid item xs={3}>
              <Button
                fullWidth
                onClick={handleClose}
                variant="outlined"
                classes={{
                  root: classes.cancelButton,
                  label: classes.labelCancelButton,
                }}
              >
                Huỷ
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                disableElevation
                onClick={handleAddProduct}
                variant="contained"
                classes={{
                  root: classes.addButton,
                  label: classes.labelAddButton,
                }}
              >
                {isCreate ? "Thêm" : "Lưu lại"}
              </Button>
            </Grid>
          </Grid>
        </footer>
      </div>
    </Modal>
  );
}

AddProduct.defaultProps = {
  isOpen: false,
  isCreate: true,
  products: [],
  defaultData: {
    productId: "",
    productName: "",
    productQuantity: "",
    productPrice: "",
    productDiscount: "",
    productReducedPrice: "",
    productStatus: "",
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "1050px",
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    padding: "16px",

    zIndex: 10,
    position: "absolute",
    top: "61%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  header: {
    borderBottom: "1px solid #EEEEEE",
    padding: "8px 12px 12px",
    "& span": {
      fontSize: "18px",
      fontWeight: 600,
      margin: 0,
    },
  },
  select: {
    width: "100%",
    height: '100%',
    fontSize: "12px",
    fontWeight: 500,
    padding: 0,
    margin: 0,
    borderRadius: "5px",
    "& :focus": {
      backgroundColor: "transparent",
    },
    ".MuiNativeSelect-select": {
      backgroundColor: 'black'
    },
    "&.MuiInput-root": {
      padding: "0px 16px",
    },

    "& .Mui-disabled span": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  selectNoError: {
    border: "1px solid #B3B3B3 !important",
  },
  selecError: {
    border: "1px solid #EA403F !important",
  },
  menuProps: {
    paddingLeft: 0,
    marginTop: "55px",
    backgroundColor: 'white',
  },
  article: {
    borderBottom: "1px solid #EEEEEE",
    padding: "14px 12px 20px",
    "& .MuiGrid-item > div:not(:first-child)": {},
  },

  footer: {
    marginTop: "24px",
    marginBottom: "14px",
  },
  buttons: {
    justifyContent: "flex-end",
  },

  cancelButton: {},
  addButton: {
    backgroundColor: "#3952D3",
  },
  labelCancelButton: {
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  labelAddButton: {
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "capitalize",
  },

  productReducedPriceLabel: {
    color: "#49A8FF",
  },

  productReducedPriceInput: {
    backgroundColor: "#EEF2FF",
    "& .MuiInputBase-input": {
      color: "#1E72C0",
    },
    "& .MuiInputAdornment-root > span": {
      color: "#1E72C0",
    },
  },
  error: {
    color: "#EA403F",
    fontSize: 20,
  },
}));

export default AddProduct;
