import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import DangerButton from "app/components/Button/DangerButton";
import GrayButton from "app/components/Button/GrayButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import TextFields from "app/components/TextFields";
import { LANG_VI } from "app/utils/constant";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Lodash from "lodash";
// import Select from "app/components/Select";
const ColorCondition = ({
  index,
  colorCondition,
  lang,
  detail,
  onRemove,
  colorConditionList,
  setColorConditionList,
  validate,
  setValidate,
  dataVariants,
  isDisabled,
}) => {
  const classes = useStyles();
  const formatPrice = (value) => {
    let res = (value || "")
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return res;
  };
  const onChaneTotal = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (/^0{2,}/.test(value) || /\D+/.test(value)) {
      return;
    } else {
      changeValueColorCondition({
        price: value,
      });
    }
  };

  const onChangeSelectColorItem = (event) => {
    changeValueColorCondition({
      attributes: [
        {
          id: 1,
          values: [event.target.value],
        },
      ],
    });
  };

  const changeValueColorCondition = (value) => {
    let flag = { ...colorCondition, ...value };
    let arr1 = Lodash.slice(colorConditionList, 0, index);
    let arr2 = Lodash.slice(colorConditionList, index + 1);
    setColorConditionList([...arr1, flag, ...arr2]);
  };
  const remove = () => {
    onRemove instanceof Function && onRemove(index);
  };
  const helperTextDiscount = () => {
    let error = "";
    if (lang === LANG_VI) {
      if (!colorCondition.price) {
        error = "Vui lòng nhập giá màu";
      }
    } else {
      if (!colorCondition.price) {
        error = "Color price is require";
      }
    }
    return error;
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={4}
        className={classes.gridItemLeft}
        style={{ marginTop: 15, borderColor: "black" }}
      >
        <div className={classes.label}>
          {lang === LANG_VI ? "Tên màu" : "Color name"}
        </div>
        <FormControl error={!colorCondition?.attributes[0]?.values[0]} fullWidth>
          <Select
            variant="outlined"
            required
            onChange={(e) => onChangeSelectColorItem(e)}
            placeholder="Chọn màu"
            fullWidth
            value={colorCondition?.attributes[0]?.values[0]}
            style={{ textTransform: "uppercase", maxHeight: 38 }}
            disabled={isDisabled === 1}
            // error={!colorCondition?.attributes[0]?.values[0]}
          >
            {dataVariants?.map((colorProp, index) => {
              const checkColorInList = colorConditionList.find(
                (colorItem) =>
                  colorItem.attributes[0].values[0] === colorProp.id
              );
              return (
                <MenuItem
                  style={{ textTransform: "uppercase" }}
                  value={colorProp?.id}
                  key={index}
                  disabled={checkColorInList}
                >
                  {colorProp?.translates[0]?.language_value}
                </MenuItem>
              );
            })}
          </Select>
          {!colorCondition?.attributes[0]?.values[0] && (
            <FormHelperText style={{ margin: "6px 0 9px 0", fontSize:'12px', color: "#EA403F"}}>
              {lang === "vi" ? "Vui lòng chọn màu" : "Please select color"}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid
        item
        xs={8}
        style={{ marginBottom: 8 }}
        className={classes.gridItemRight}
      >
        <p className={classes.label}>
          {lang === LANG_VI ? "Giá bán theo màu" : "Price for color"}
        </p>
        <TextFields
          // label={lang === LANG_VI ? "Giá bán theo màu" : "Price for color"}
          required
          helperText={helperTextDiscount()}
          error={colorCondition.price === null}
        >
          <Box display="flex" alignItems="center">
            <OutlinedInput
              style={{ width: "50%", height: 38 }}
              // disabled={detail}
              disabled={isDisabled === 1}
              placeholder={lang === LANG_VI ? "Nhập giá bán" : "Enter price"}
              value={
                colorCondition.price === 0
                  ? Intl.NumberFormat("vi-VI").format(colorCondition?.price)
                  : formatPrice(colorCondition.price)
                //  colorCondition.value
              }
              onChange={onChaneTotal}
              error={colorCondition.price === null}
              endAdornment={
                <InputAdornment position="end">
                  <span>
                    <strong>VND</strong>
                  </span>
                </InputAdornment>
              }
            />
            <DangerButton style={{ marginLeft: 24 }} onClick={remove} disabled={isDisabled === 1}>
              Xóa
            </DangerButton>
          </Box>
        </TextFields>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: "10px 0",
    marginBottom: "10px",
    // fontSize: '14px'
  },
  gridItemLeft: {
    padding: "0px 10px 0px 0px",
    margin: "auto",
  },
  gridItemLeftCheckbox: {
    padding: "2vw 10px 0px 0px",
    margin: "auto",
  },
  gridItemRight: {
    padding: "0px 0px 0px 10px",
  },
  featureProductCount: {
    color: "#ED0017",
    fontWeight: "italic",
  },
  input: {
    width: "100%",
    fontSize: "14px !important",
  },
  textarea: {
    width: "100%",
    fontSize: "14px",
  },
  label: {
    marginBottom: "5px",
  },
  inputCheckbox: {
    width: "30%",
    fontSize: "14px !important",
  },
  noBorder: {
    border: "none",
  },
}));

ColorCondition.defaultProps = {
  colorConditionList: [],
  setColorConditionList: () => {},
  validate: false,
};
export default ColorCondition;
