import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@material-ui/core";
  import { Add, DeleteOutline, Edit } from "@material-ui/icons";
  import DangerButton from "app/components/Button/DangerButton";
  import PrimaryButton from "app/components/Button/PrimaryButton";
  import ConfirmDelete from "app/components/ConfirmDelete";
  import Pagination from "app/components/Pagination";
  import SearchInput from "app/components/SearchInput";
  import SelectStatus from "app/components/SelectStatus";
  import SwitchUI from "app/components/Switch";
  import {
    fetchDeleteCoupons,
    fetchListCoupon,
    fetchUpdateCoupon,
  } from "app/reducers/coupon";
  import { PAGE_SIZE } from "app/utils/constant";
  import * as Utils from "app/utils";
  import moment from "moment";
  import { Fragment, useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { BrowserRouter, Link, Route, Switch } from "react-router-dom";

  import { urlCoupon, urlCouponLoyalty, urlRankManagement } from "app/Layouts/AuthenticatedLayout/Sidebar/url";
  import { fetchDeleteLoyaltyCoupons, fetchListLoyaltyCoupon } from "app/reducers/loyaltyCoupon";
import { fetchDeleteRank, fetchListRank } from "app/reducers/rank";
import CouponLoyaltyCreate from "../CouponLoyalty/CouponLoyaltyCreate";
import RankManagementCreateEdit from "./RankManagementCreateEdit";
  const allStatus = { status: "0", label: "Tất cả trạng thái" };
  const LoyaltyCoupon = () => {
    const dispatch = useDispatch();
    const { listRank, isLoading, totalRanks } = useSelector(
      (state) => state.rank
    );
    const [page, setPage] = useState(1);
    const [search_value, setSearchValue] = useState("");
    const [status, setStatus] = useState(allStatus);
    const rankList = listRank;
    const [coupon, setCoupon] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
  
    useEffect(() => {
      dispatch(
        fetchListRank({
          page: page,
          limit: PAGE_SIZE,
          search: search_value || null,
        })
      );
    }, [page, search_value]);
  
    const checkedAll = (e) => {
      if (e.target.checked) {
        if (Array.isArray(rankList))
          setSelectedRows(rankList.map((item) => item?.id));
      } else {
        setSelectedRows([]);
      }
    };
  
    const checkedRow = (e, row) => {
      let selected = [...selectedRows];
      if (e.target.checked) {
        selected.push(row?.id);
      } else {
        selected = selected.filter((item) => item !== row?.id);
      }
      setSelectedRows(selected);
    };
  
    const onSearch = (value) => {
      setSearchValue(value);
      setPage(1);
    };
    const changeStatus = (value) => {
      setStatus(value);
      setPage(1);
    };
    const deleteCoupon = () => {
      setConfirmDelete(false);
      if (selectedRows.length > 0) {
        for(let i=0; i< selectedRows.length; i++){
          dispatch(fetchDeleteRank({ id: selectedRows[i] })).then((s) => {
            refreshList();
          });
        }
      }
      selectedRows.length=0;
    };
    const refreshList = () => {
      dispatch(
        fetchListRank({
          page: page,
          limit: PAGE_SIZE,
          search: search_value || null,
          customer_card_type_id: null,
        })
      );
    };
    const onChangeStatusCoupon = (item = {}) => {
      const { requirements, id, ...rest } = item;
      const body = {
        ...rest,
        status: item.status === 1 ? -1 : 1,
        contents: item.translates,
        order_requirements: requirements,
        product_requirements: requirements,
      };
      dispatch(fetchUpdateCoupon({ ...body, id }));
    };
    const renderRankingList = () => {
      return (
        <Fragment>
          <ConfirmDelete
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            onOK={deleteCoupon}
            title="XÓA THỨ HẠNG"
            message="Bạn có chắc muốn xóa thứ hạng đã chọn?"
            cancelTitle="Hủy bỏ"
            okTitle="Xóa"
          />
          <div className="page-header">
            <div className="page-title">Danh sách thứ hạng</div>
            <PrimaryButton
              component={Link}
              to="/add"
              startIcon={<Add />}
              onClick={() => setCoupon({})}
            >
              Thêm mới
            </PrimaryButton>
            <DangerButton
              startIcon={<DeleteOutline />}
              disabled={selectedRows.length === 0}
              onClick={() => setConfirmDelete(true)}
            >
              Xóa đã chọn
            </DangerButton>
          </div>
          <TableContainer component={Paper}>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < rankList?.length
                      }
                      checked={
                        rankList?.length > 0 &&
                        selectedRows.length > 0
                      }
                      onChange={checkedAll}
                    />
                  </TableCell>
                  <TableCell>Mã thứ hạng</TableCell>
                  <TableCell>Tên thứ hạng</TableCell>
                  <TableCell>Chi tiêu tối thiểu</TableCell>
                  <TableCell>Chi tiêu tối đa</TableCell>
                  <TableCell align="center">Chỉnh sửa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(rankList) &&
                  rankList.map((rank) => {
                    const translates = Utils.getSafeValue(
                      rank,
                      "translates",
                      []
                    );
                    const name = Utils.getObjByLanguage(
                      translates,
                      "vi",
                      "language_value"
                    );
                    return (
                      <TableRow key={rank?.id}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.indexOf(rank?.id) > -1}
                            onChange={(e) => checkedRow(e, rank)}
                          />
                        </TableCell>
                        <TableCell                        
                            style={{ textTransform: 'uppercase' }}
                            >
                            {rank?.key_identity}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/${rank?.id}`}
                            onClick={() => setCoupon(rank)}
                            style={{ color:'#2154FF', textDecoration: 'none' }}
                          > 
                            {rank?.name}
                          </Link>
                        </TableCell>
                        {rank?.config_money_spends.map((moneySpend) => (
                          <>
                            <TableCell>
                              {moneySpend?.min} đ
                            </TableCell>
                            <TableCell>
                              {moneySpend?.max} đ
                            </TableCell>
                          </>
                        ))}
                        <TableCell align="center">
                          <Link
                              to={`/${rank?.id}`}
                              onClick={() => setCoupon(rank)}
                              style={{ textDecoration: 'none', textTransform: 'uppercase', color: 'black' }}
                            > 
                              <Edit/>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(totalRanks / PAGE_SIZE)}
            page={page}
            handleChangePage={setPage}
          />
        </Fragment>
      );
    };
  
    return (
      <BrowserRouter basename={urlRankManagement}>
        <Switch>
          <Route exact path="/">
            {renderRankingList()}
          </Route>
          {/* <Route exact path="/add">
            <AddEditCoupon coupon={{}} />
          </Route> */}
          <Route exact path="/add">
            <RankManagementCreateEdit coupon={{}}/>
          </Route>
          <Route exact path="/:id">
            <RankManagementCreateEdit coupon={coupon} />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  };
  
  export default LoyaltyCoupon;
  