import { convertTranslates } from "app/utils/validate";

export const ACTION_TYPE = {
  GET_ORDERS_FAIL_LIST_REQUEST: "orders/GET_ORDERS_FAIL_LIST_REQUEST",
  GET_ORDERS_FAIL_LIST_SUCCESS: "orders/GET_ORDERS_FAIL_LIST_SUCCESS",
  GET_ORDERS_FAIL_LIST_ERROR: "orders/GET_ORDERS_FAIL_LIST_ERROR",
  GET_ORDERS_REQUEST: "orders/GET_ORDERS_REQUEST",
  GET_ORDERS_FAIL_SUCCESS: "orders/GET_ORDERS_FAIL_SUCCESS",
  GET_ORDERS_ERROR: "orders/GET_ORDERS_ERROR",
  DELETE_COMBO_LIST_REQUEST: "combo/DELETE_COMBO_LIST_REQUEST",
  DELETE_COMBO_LIST_SUCCESS: "combo/DELETE_COMBO_LIST_SUCCESS",
  DELETE_COMBO_LIST_ERROR: "combo/DELETE_COMBO_LIST_ERROR",
  SYNC_TO_SAP_REQUEST: "combo/SYNC_TO_SAP_REQUEST",
  SYNC_TO_SAP_SUCCESS: "orders/SYNC_TO_SAP_SUCCESS",
  SYNC_TO_SAP_ERROR: "orders/SYNC_TO_SAP_ERROR",
};

const initialState = {
  ordersList: [],
  totalRecords: 0,
  orders: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_ORDERS_FAIL_LIST_SUCCESS:
      let ordersList = action.payload?.results;
      if (Array.isArray(ordersList)) {
        ordersList.forEach((item) => {
          if (Array.isArray(item?.order_details)) {
            item.order_details.forEach((item) => {
              convertTranslates(item?.product);
            });
          }
        });
      }
      return {
        ...state,
        ordersList,
        totalRecords: action.payload?.totalRecords,
      };
    case ACTION_TYPE.GET_ORDERS_FAIL_SUCCESS:
      return {
        ...state,
        orders: action.payload?.results,
      };
    default:
      return state;
  }
};

export default reducer;
