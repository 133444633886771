import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Link as LinkUI,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Breadcrumbs from "app/components/Breadcrumbs";
import DangerButton from "app/components/Button/DangerButton";
import DefaultButton from "app/components/Button/DefaultButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import Switch from "app/components/Switch";
import TextFields from "app/components/TextFields";
import {
  fetchCoupon,
  fetchCreateCoupon,
  fetchDeleteCoupons,
  fetchListCoupon,
  fetchUpdateCoupon,
} from "app/reducers/coupon";
import { getTranslateField } from "app/utils";
import { LANG_EN, LANG_VI, PAGE_SIZE } from "app/utils/constant";
import { formatDateTime, formatPrice, isEmpty } from "app/utils/validate";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Lodash from "lodash";
import { getCustomCard as _getCustomCard } from "app/services/flashsale";
import SelectLang from "app/components/SelectLang";
import Textarea from "../../Promotions/components/Textarea";

import {
  fetchCouponSharing,
  fetchCreateCouponSharing,
  fetchUpdateCouponSharing,
} from "app/reducers/couponSharing";

const objectCondition = {
  percentage: null,
  price: null,
  product: null,
  quantity: null,
  type: 1,
  value: null,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CreateEditCouponSharing = ({ coupon }) => {
  const [customerCard, setCustomerCard] = useState([]);
  const { coupon: couponDetail } = useSelector((state) => state.couponSharing);
  const [dataDetail, setDataDetail] = useState(coupon || couponDetail || {});
  const params = useParams();
  const [disable, setDisable] = useState(false);
  const [conditionPrice, setConditionPrice] = useState({ value: null });
  const [validate, setValidate] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [lang, setLang] = useState(LANG_VI);
  const [openToastError, setOpenToastError] = useState(false);
  const [messageToastError, setMessageToastError] = useState("");
  const [detail, setDetail] = useState(true);
  useEffect(() => {
    if (params.id) {
      dispatch(fetchCouponSharing({ id: params.id }));
    }
  }, [params?.id]);

  useEffect(() => {
    if (!coupon?.id && params?.id) {
      onChangeValueDataDetail(couponDetail);
    } else {
      if (coupon?.id && params?.id) {
        onChangeValueDataDetail(couponDetail);
      } else {
        setDataDetail({ ...dataDetail, active_status: 1, sale_type: 2 });
        setDetail(false);
      }
    }
  }, [couponDetail]);
  
  const onChangeValueDataDetail = (object = {}) => {
    const obj_name_vi = getTranslateField(object?.translates || [], "vi");
    const obj_name_en = getTranslateField(object?.translates || [], "en");
    setDataDetail({
      ...object,
      name_vi: obj_name_vi.name,
      name_en: obj_name_en.name,
    });
    setConditionPrice({ value: object.sale_price });
  };
  useEffect(() => {
    if (dataDetail?.type === 1) setDisable(true);
    else setDisable(false);
  }, [dataDetail]);

  const handleCreate = () => {
    if (dataDetail.code && dataDetail.expire_time) {
      if (dataDetail.expire_time < 1) {
        setValidate(true);
        return;
      }
      if (isEmpty(dataDetail.name_en)) {
        dataDetail.name_en = dataDetail.name_vi;
      }
      if (isEmpty(dataDetail.name_vi)) {
        dataDetail.name_vi = dataDetail.name_en;
      }
      const data = {
        code: dataDetail.code,
        expire_time: dataDetail.expire_time,
        active_status: dataDetail.active_status,
        min_money_spend: dataDetail.min_money_spend,
        sale_price: conditionPrice.value,
        min: dataDetail.min,
        max: dataDetail.max,
        sale_type: dataDetail.sale_type ? dataDetail.sale_type : 2,
        translates: [
          {
            language_code: "vi",
            language_field: "name",
            language_value: dataDetail.name_vi,
          },
          {
            language_code: "en",
            language_field: "name",
            language_value: dataDetail.name_en,
          },
        ],
      };
      let dataCreate = {
        items: [data],
      };

      if (params.id) {
        dispatch(fetchUpdateCouponSharing({ ...data, id: params.id }))
          .then((s) => {
            if (s?.status === 200) {
              setDetail(true);
              setValidate(false);
              setLang(LANG_VI);
              history.push(`/${params.id}`);
            } else {
              if ((s.message || "").includes("code already exist")) {
                setMessageToastError("Mã khuyến mại đã tồn tại");
                setValidate(true);
              } else {
                setOpenToastError(true);
                setMessageToastError(s.message);
              }
            }
          })
          .catch((error) => {});
      } else {
        dispatch(fetchCreateCouponSharing({ ...dataCreate }))
          .then((s) => {
            if (s?.status === 201) {
              history.push("/");
            } else {
              setOpenToastError(true);
              setMessageToastError(s.message);
            }
          })
          .catch((error) => {});
      }
    } else {
      setValidate(true);
    }
  };
  const onChangeValue = (e, type, key) => {
    let name = e.target.name;
    if (name === "min_money_spend") {
      setDataDetail({
        ...dataDetail,
        [name]: e.target.value.replaceAll(".", ""),
      });
      return;
    }
    switch (type) {
      case "switch":
        setDataDetail({
          ...dataDetail,
          [key]: dataDetail.status === 1 ? -1 : 1,
        });
        break;
      default:
        setDataDetail({
          ...dataDetail,
          [name]: e.target.value,
        });
        break;
    }
  };

  const onChaneDiscount = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (/^0{2,}/.test(value) || /\D+/.test(value)) {
      return;
    }
    if (dataDetail?.sale_type === 1) {
      if (e.target.value <= 100) {
        if (parseInt(e.target.value) > 100) {
          setConditionPrice({ value: "100" });
        } else {
          setConditionPrice({ value: e.target.value });
        }
      }
    } else {
      if (/^0[1-9]/.test(value)) {
        setConditionPrice({ value: value.slice(1) });
      } else {
        setConditionPrice({ value: value });
      }
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastError(false);
  };
  const onChangeDetail = (e) => {
    e.preventDefault();
    setValidate(false);
    setDetail(!detail);
  };
  useEffect(() => {
    if (!couponDetail) {
      history.replace("/");
      return;
    }
    if (coupon?.id && params?.id) {
      const obj_name_vi = getTranslateField(coupon?.translates || [], "vi");
      const obj_name_en = getTranslateField(coupon?.translates || [], "en");

      setDataDetail({
        ...coupon,
        name_vi: obj_name_vi.name,
        name_en: obj_name_en.name,
      });

      setConditionPrice({ value: coupon.sale_price });
    }
  }, [couponDetail]);

  const handleChangeTextarea = (key, value) => {
    setDataDetail({
      ...dataDetail,
      [key]: value,
    });
  };
  return (
    <Fragment>
      <Snackbar
        open={openToastError}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseToast} severity="error">
          {messageToastError}
        </Alert>
      </Snackbar>
      <div className="page-header">
        {params?.id ? (
          detail ? (
            <Fragment>
              <div className="page-title">
                <Breadcrumbs>
                  <Link to="/">Coupon chia sẻ</Link>
                  <Typography>Chi tiết coupon chia sẻ</Typography>
                </Breadcrumbs>
              </div>
              <PrimaryButton onClick={onChangeDetail}>Chỉnh sửa</PrimaryButton>
            </Fragment>
          ) : (
            <Fragment>
              <div className="page-title">
                <Breadcrumbs>
                  <Link to="/">Coupon chia sẻ</Link>
                  <Link onClick={() => history.go(0)}>
                    Chi tiết coupon
                  </Link>
                  <Typography>Chỉnh sửa</Typography>
                </Breadcrumbs>
              </div>

              <DefaultButton component={Link} onClick={() => history.go(0)}>
                Hủy
              </DefaultButton>
              <PrimaryButton onClick={handleCreate}>Lưu lại</PrimaryButton>
            </Fragment>
          )
        ) : (
          <Fragment>
            <div className="page-title">
              <Breadcrumbs>
                <Link to="/">Coupon chia sẻ</Link>
                <Typography>Thêm mới</Typography>
              </Breadcrumbs>
            </div>
            <DefaultButton component={Link} to="/">
              Hủy
            </DefaultButton>
            <PrimaryButton onClick={handleCreate}>Lưu Lại</PrimaryButton>
          </Fragment>
        )}
      </div>
      <div className="page-content">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box fontSize={16} fontWeight={600}>
            Thông tin chung
          </Box>
          <SelectLang
            value={lang}
            onChange={(e) => {
              setLang(e);
              setValidate(false);
            }}
          />
        </Box>
        {lang === LANG_VI ? (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Mã coupon"
                required
                name="code"
                disabled={detail}
                placeholder="Nhập mã coupon"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.code || ""}
                helperText={
                  validate && !dataDetail.code
                    ? "Vui lòng nhập mã coupon"
                    : validate
                }
                error={validate && !dataDetail.code}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Trạng thái"
                helperText="Vui lòng chọn trạng thái"
                error={validate && !dataDetail.active_status}
                required
              >
                <Select
                  name="active_status"
                  value={dataDetail.active_status || 0}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  disabled={detail}
                  className={`${classes.select} ${
                    validate && !dataDetail.active_status && classes.selectError
                  }`}
                  input={<TextFields/>}
                  MenuProps={{classes:{paper: classes.menuProps}}}
          
                >
                  <MenuItem value={1}>Hiện</MenuItem>
                  <MenuItem value={-1}>Ẩn</MenuItem>
                </Select>
              </TextFields>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Thời hạn sử dụng"
                required
                disabled={detail}
                name="expire_time"
                type="number"
                placeholder="Nhập thời hạn sử dụng (tháng)"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.expire_time}
                helperText={
                  validate && !dataDetail.expire_time
                    ? "Vui lòng nhập thời hạn sử dụng"
                    : validate && dataDetail.expire_time < 1
                    ? "Thời hạn sử dụng k được nhỏ hơn 1"
                    : validate
                }
                error={
                  (validate && !dataDetail.expire_time) ||
                  (validate && dataDetail.expire_time < 1)
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFields
                required
                name='name_vi'
                label="Mô tả ngắn"
                helperText={
                  validate && !dataDetail.name_vi
                    ? "Vui lòng nhập mô tả"
                    : validate
                }
                error={validate && !dataDetail.name_vi}
              >
                <Textarea
                  readOnly={detail}
                  placeholder="Nhập Mô tả ngắn"
                  defaultValue={dataDetail?.name_vi}
                  error={validate && !dataDetail.name_vi}
                  onChange={(e) => handleChangeTextarea("name_vi", e)}
                />
              </TextFields>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Coupon code"
                required
                name="code"
                disabled={detail}
                placeholder="Type Coupon code "
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.code || ""}
                helperText={
                  validate && !dataDetail.code
                    ? "Coupon code is required"
                    : validate
                }
                error={validate && !dataDetail.code}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Status"
                helperText="Status is required"
                error={validate && !dataDetail.active_status}
                required
              >
                <Select
                  name="active_status"
                  value={dataDetail?.active_status}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  disabled={detail}
                  className={`${classes.select} ${
                    validate && !dataDetail.active_status && classes.selectError
                  }`}
                  input={<TextFields/>}
                  MenuProps={{classes:{paper: classes.menuProps}}}
                >
                  <MenuItem value={1}>
                    Active
                  </MenuItem>
                  <MenuItem value={-1}>
                    Inactive
                  </MenuItem>
                </Select>
              </TextFields>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Expire time"
                required
                placeholder="Type expire time ( amount month )"
                disabled={detail}
                name="expire_time"
                type="number"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.expire_time}
                helperText={
                  validate && !dataDetail.expire_time
                    ? "Expire time is requied"
                    : validate && dataDetail.expire_time < 1
                    ? "Expire time < 0"
                    : validate
                }
                error={
                  (validate && !dataDetail.expire_time) ||
                  (validate && dataDetail.expire_time < 1)
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFields
                required
                label="Description"
                name= "name_en"
                helperText={
                  validate && !dataDetail.name_en
                    ? "Description is required"
                    : validate
                }
                error={validate && !dataDetail.name_en}
              >
                <Textarea
                  readOnly={detail}
                  placeholder="Typing Description"
                  defaultValue={dataDetail?.name_en}
                  error={validate && !dataDetail.name_en}
                  onChange={(e) => handleChangeTextarea("name_en", e)}
                />
              </TextFields>
            </Grid>
          </Grid>
        )}
      </div>
      {lang === LANG_VI ? (
        <>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Điều kiện
              </Box>
            </Box>
            <Grid container spacing={4} direction="row" md={12}>
              <Grid item md={4}>
                <TextFields
                  label="Số lượng giới thiệu tối thiểu"
                  required
                  type="number"
                  placeholder="Nhập số lượng giới thiệu tối thiểu"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="min"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.min}
                  helperText="Vui lòng nhập số lượng giới thiệu tối thiểu"
                  error={validate && !dataDetail.min}
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                />
              </Grid>

              <Grid item md={4}>
                <TextFields
                  label="Số lượng giới thiệu tối đa"
                  type="number"
                  required
                  placeholder="Nhập số lượng giới thiệu tối đa"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="max"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.max}
                  helperText="Vui lòng nhập số lượng giới thiệu tối đa"
                  error={validate && !dataDetail.max}
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} md={12}>
              <Grid item md={4} direction='row'>
                <TextFields
                  label="Giá trị hóa đơn để được áp dụng"
                  placeholder="0"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="min_money_spend"
                  onChange={(e) => onChangeValue(e)}
                  value={formatPrice(dataDetail?.min_money_spend)}
                  helperText="Vui lòng nhập giá trị hóa đơn"
                  error={
                    validate &&
                    !dataDetail.min_money_spend
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VNĐ</strong>
                      </span>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item md={4}>
                <TextFields
                  label="Giá trị giảm giá"
                  helperText="Vui lòng nhập giá trị giảm giá"
                  placeholder="0"
                  type="number"
                  error={validate && !conditionPrice.value}
                  required
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width={"100%"}
                    justifyContent="space-between"
                  >
                    <OutlinedInput
                      placeholder="0"
                      name="sale_price"
                      disabled={disable || detail}
                      style={{ width: "80%", height: 38 }}
                      value={
                        dataDetail?.sale_type === 1
                          ? conditionPrice.value
                          : formatPrice(conditionPrice.value)
                      }
                      error={validate && !conditionPrice.value}
                      onChange={onChaneDiscount}
                    />
                    <FormControl style={{ width: "15%", minWidth: 70 }}>
                      <Select
                        disabled={disable || detail}
                        name="sale_type"
                        value={dataDetail.sale_type}
                        onChange={(e) => {
                          setConditionPrice({ value: "" });
                          onChangeValue(e);
                        }}
                        defaultValue={2}
                        disableUnderline
                        className={`${classes.select}`}
                        MenuProps={{classes:{paper: classes.menuProps}}}
                        input={<TextFields/>}
                      >
                        <MenuItem value={2}>
                          VNĐ
                        </MenuItem>
                        <MenuItem value={1}>
                          %
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TextFields>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Require
              </Box>
            </Box>
            <Grid container spacing={4} md={12}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Minimum introduce amount"
                  required
                  type="number"
                  placeholder="Typing amount introduce"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="min"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.min}
                  helperText="Please type amount introduce"
                  error={validate && !dataDetail.min}
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextFields
                  label="Maximum introduce amount"
                  required
                  type="number"
                  placeholder="Typing amount introduce"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="max"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.max}
                  helperText="Please type amount introduce"
                  error={validate && !dataDetail.max}
                  endAdornment={
                    <InputAdornment position="end"></InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} direction="row" md={12}>
              <Grid item md={4}>
                <TextFields
                    label="Minimum bill invoice"
                    placeholder="0"
                    disabled={disable || detail}
                    inputProps={{ maxLength: 50 }}
                    name="min_money_spend"
                    onChange={(e) => onChangeValue(e)}
                    value={formatPrice(dataDetail?.min_money_spend)}
                    helperText="Minimum bill is required"
                    error={
                      validate &&
                      !dataDetail.min_money_spend
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <span>
                          <strong>VNĐ</strong>
                        </span>
                      </InputAdornment>
                    }
                  />
              </Grid>

              <Grid item md={4}>
                <TextFields
                  label="Sale price"
                  helperText="Sale price is required"
                  placeholder="0"
                  type="number"
                  defaultValue={"0"}
                  error={validate && !conditionPrice.value}
                  required
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width={"100%"}
                    justifyContent="space-between"
                  >
                    <OutlinedInput
                      placeholder="0"
                      name="sale_price"
                      disabled={disable || detail}
                      style={{ width: "80%", height: 38 }}
                      value={
                        dataDetail?.sale_type === 1
                          ? conditionPrice.value
                          : formatPrice(conditionPrice.value)
                      }
                      error={validate && !conditionPrice.value}
                      onChange={onChaneDiscount}
                    />
                  <FormControl style={{ width: "15%", minWidth: 70 }}>
                      <Select
                        disabled={disable || detail}
                        name="sale_type"
                        value={dataDetail.sale_type || 2}
                        onChange={(e) => {
                          setConditionPrice({ value: "" });
                          onChangeValue(e);
                        }}
                        defaultValue={2}
                        disableUnderline
                        className={`${classes.select}`}
                        MenuProps={{classes:{paper: classes.menuProps}}}
                        input={<TextFields/>}
                      >
                        <MenuItem value={2}>
                          VNĐ
                        </MenuItem>
                        <MenuItem value={1}>
                          %
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TextFields>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Fragment>
  );
};
const useStyles = makeStyles({
  select: {
    padding:0,
    margin:0,
    width: "100%",
    height:'100%',
    fontSize: "12px",
    fontWeight: 500,
    minHeight: "40px",
    borderRadius: "5px",
    fontSize: '10px',
    "& :focus": {
      backgroundColor: "transparent",
    },
    ".MuiNativeSelect-select":{
      backgroundColor: 'black'
    },
    "&.MuiInput-root": {
      padding: "4px 16px",
    },

    "& .Mui-disabled span": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  menuProps:{
    paddingLeft: 0,
    marginTop:"55px",
    backgroundColor:'white',
  },
  labelError: {
    color: "#EA403F",
  },
  selectError: {
    border: "1px solid #EA403F",
  },
});
CreateEditCouponSharing.defaultProps = {
  coupon: {},
};
export default CreateEditCouponSharing;
