import { createSlice } from "@reduxjs/toolkit";
import {
  createCouponSharing,
  deleteCouponSharings,
  editCouponSharing,
  getCouponSharingId,
  getCouponSharingList,
} from "app/services/couponSharing";
import { PAGE_SIZE } from "app/utils/constant";
export const fetchListCouponSharing = (params) => async (dispatch, getState) => {
  try {
    const response = await getCouponSharingList(params);
    let payload = {
      listCoupon: response.data?.data || [],
      totalCoupons: response.data?.total || 0,
    };
    dispatch(updateDataCouponSharing(payload));
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchCouponSharing = (params) => async (dispatch, getState) => {
  try {
    const response = await getCouponSharingId(params);
    let payload = {
      coupon: response.data?.data || {},
    };
    dispatch(updateDataCouponSharing(payload));
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchCreateCouponSharing = (params) => async (dispatch, getState) => {
  try {
    const response = await createCouponSharing(params);
    const state = getState().coupon;
    const listCoupon = state.listCoupon || [];
    if (listCoupon.length) {
      let payloadListCoupon = {
        listCoupon: [
          {
            ...(response?.data?.data || {}),
            ...params,
            start_date: params.start_date,
            start_time: params.start_time,
            end_date: params.end_date,
            end_time: params.end_time,
            translates: params.contents,
            requirements:
              params.type === 1
                ? params.order_requirements
                : params.product_requirements,
          },
          ...listCoupon,
        ],
      };
      dispatch(updateDataCouponSharing(payloadListCoupon));
    } else {
      dispatch(fetchListCouponSharing({ page: 1, limit: PAGE_SIZE }));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchUpdateCouponSharing = (params) => async (dispatch, getState) => {
  try {
    const response = await editCouponSharing(params);
    const state = getState().coupon;
    const listCoupon = state.listCoupon || [];
    if (listCoupon.length) {
      let payloadListCoupon = {
        listCoupon: [
          ...listCoupon.map((item) => {
            if (item.id === +params.id) {
              return {
                ...params,
                start_date: params.start_date,
                start_time: params.start_time,
                end_date: params.end_date,
                end_time: params.end_time,
                translates: params.contents,
                requirements:
                  params.type === 1
                    ? params.order_requirements
                    : params.product_requirements,
              };
            } else {
              return item;
            }
          }),
        ],
      };
      dispatch(updateDataCouponSharing(payloadListCoupon));
    } else {
      dispatch(fetchListCouponSharing({ page: 1, limit: PAGE_SIZE }));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchDeleteCouponSharings = (params) => async (dispatch, getState) => {
  try {
    const { id } = params;
    const response =  await deleteCouponSharings({ id: id })
    return response;
  } catch (error) {
    return error;
  }
};
export const setupCouponSharing = createSlice({
  name: "couponSharing",
  initialState: {
    listCoupon: [],
    totalCoupons: 0,
    coupon: {},
  },
  reducers: {
    updateDataCouponSharing: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateDataCouponSharing } = setupCouponSharing.actions;
export default setupCouponSharing.reducer;
