import * as Utils from "../../../../../utils";
import * as AppURL from "../../../../../services/urlAPI";
import { PAGE_SIZE_UNLIMIT } from "app/utils/constant";
import { getProductCategory, updateSortCategory } from "app/services/axios";
import { useState,useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import DangerButton from "app/components/Button/DangerButton";
import { ClearSharp } from "@material-ui/icons";
import PrimaryButton from "app/components/Button/PrimaryButton";
import Breadcrumbs from "app/components/Breadcrumbs";

import { Done as DoneIcon } from '@material-ui/icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link, useParams } from "react-router-dom";
import { urlProductCategory } from "app/Layouts/AuthenticatedLayout/Sidebar/url";
import { Typography } from "@material-ui/core";
import { reorder } from "app/pages/ProductCategories/SortCategories/components/helpers";
import DraggableListItem from "app/pages/ProductCategories/SortCategories/components/DraggableListItem";
  
export default function ChildrenProductCategoriesSort() {
  const [parentTitle, setParentTitle] = useState("");
  const { id,grandChildrenId } = useParams();
  let idCategory
  if(grandChildrenId === undefined){
    idCategory = id
  }else{
    idCategory = grandChildrenId
    const url = Utils.replaceStrUrl(AppURL.getChildProductCategory, [
      1,
      PAGE_SIZE_UNLIMIT,
      id,
    ]);
    getProductCategory(url).then((data) => {
      const results = Utils.getSafeValue(data, "results", []);
      setParentTitle(results[0]?.parent_obj?.translates[0]?.language_value) 
    });
  }
console.log(id,grandChildrenId)
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [reMakedata, setReMakeData] = useState([]);
    const [parentTitle1, setParentTitle1] = useState("");
const history =useHistory()
    useEffect(() => {
        const url = Utils.replaceStrUrl(AppURL.getChildProductCategory, [
          1,
          PAGE_SIZE_UNLIMIT,
          idCategory,
        ]);
        getProductCategory(url).then((data) => {
          const results = Utils.getSafeValue(data, "results", []);
          setData(results.sort((a, b) => a.order - b.order));
          setParentTitle1(results[0]?.parent_obj?.translates[0]?.language_value) 
        });
      }, [idCategory]);



    
  useEffect(()=>{
    data.map(item=>{
        setReMakeData(arr =>[...arr,{id:item.id,name:item.translates[0].language_value}])
    })
    
    
  },[data])

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = reorder(reMakedata, result.source.index,
        result.destination.index);

    setReMakeData(newItems);
  };
  const handleOpenAddModal = () => {
    let idSort = []
    reMakedata.map((item)=>{

        idSort=[...idSort,item.id]
    })
    updateSortCategory("/admin/product-category/orders",{parent:idCategory,items:idSort})
    if(grandChildrenId === undefined){
      history.push(`/product-categories/${id}`);
    }else{
      history.push(`/product-categories/${id}/${grandChildrenId}`);
    }
   
  }
  
    return (
       <>
       <div className="page-header">
            <div className="page-title">
              <Breadcrumbs>
                <Link to={urlProductCategory}>Danh mục sản phẩm</Link>
                {!(grandChildrenId === undefined) && (<Link to={`${urlProductCategory}/${id}`}>{parentTitle}</Link>)}
                <Typography>{parentTitle1}</Typography>
              </Breadcrumbs>
            </div>
        <PrimaryButton
          startIcon={<DoneIcon />}
          onClick={handleOpenAddModal}
        >
          Đồng ý
        </PrimaryButton>
        <DangerButton
          startIcon={<ClearSharp />}
        onClick={() =>  history.goBack()}
        >
          Hủy
        </DangerButton>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"1"} direction="vertical">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {reMakedata.map((item, index) =>(
                <DraggableListItem item={item} index={index} key={item.id} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
       </>
       
      
    )
}
const useStyles = makeStyles({
    flexPaper: {
      flex: 1,
      margin: 16,
      minWidth: 350
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  });