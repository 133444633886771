export const PAGE_SIZE = 10;
export const PAGE_SIZE_UNLIMIT = 500000;
export const SAGA_THROTTLE = 1000;

export const LANG_VI = "vi"
export const LANG_EN = "en"

export const VOUCHER = {
    FREESHIP: "FREESHIP",
    CL_BIRTHDAY: "CL-BIRTHDAY",
    CL_COINS: "CL-COINS",
    CL_DISCOUNT: "CL-DISCOUNT",
    SMS_BIRTHDAY: "SMS-BIRTHDAY",
}

export const DATE_DEPLOY_PROD = "12/07/2022";

export const HCM_TIME_ZONE = 'Asia/Ho_Chi_Minh';