import {
  Box,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DangerButton from "app/components/Button/DangerButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import Pagination from "app/components/Pagination";
import SearchInput from "app/components/SearchInput";
import { ACTION_TYPE } from "app/reducers/ordersFail";
import { LANG_VI, PAGE_SIZE } from "app/utils/constant";
import { formatDateTime, formatPrice, isEmpty } from "app/utils/validate";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Popover from "app/components/Popover";
import { urlOrdersFail } from "app/Layouts/AuthenticatedLayout/Sidebar/url";

const allStatus = { status: 0, label: "Trạng thái giao vận", color: "unset" };
const allPaymentStatus = {
  status: 0,
  label: "Trạng thái thanh toán",
  color: "unset",
};
export const listTypeShipping = [
  {
    status: 0,
    label: "Đơn vị vận chuyển",
  },
  {
    status: 2,
    label: "Nhựa long thành",
  },
  {
    status: 1,
    label: "Giao hàng tiết kiệm",
  },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function OrdersFail() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search_value, setSearchValue] = useState("");
  const [status, setStatus] = useState(allStatus);
  const [paymentStatus, setPaymentStatus] = useState(allPaymentStatus);
  const [typeShipping, setTypeShipping] = useState(listTypeShipping[0]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const ordersList = useSelector((store) => store.ordersFail.ordersList);
  const totalRecords = useSelector((store) => store.ordersFail.totalRecords);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState("");

  useEffect(() => {
    dispatch({
      type: ACTION_TYPE.GET_ORDERS_FAIL_LIST_REQUEST,
      params: {
        limit: PAGE_SIZE,
        page,
        search_value: isEmpty(search_value) ? undefined : search_value,
        shipping_status:
          status?.status === allStatus.status ? undefined : status.status,
        payment_status:
          paymentStatus?.status === allPaymentStatus.status
            ? undefined
            : paymentStatus.status,
        shipping_driver: typeShipping?.status
          ? typeShipping?.status
          : undefined,
        order_date_from: startDate === "" ? undefined : startDate,
        order_date_to: endDate === "" ? undefined : endDate,
      },
    });
    setSelectedRows([]);
  }, [
    dispatch,
    page,
    search_value,
    status,
    paymentStatus,
    startDate,
    endDate,
    typeShipping,
  ]);
  const refreshList = () => {
    dispatch({
      type: ACTION_TYPE.GET_ORDERS_FAIL_LIST_REQUEST,
      params: {
        limit: PAGE_SIZE,
        page,
        search_value: isEmpty(search_value) ? undefined : search_value,
        shipping_status:
          status?.status === allStatus.status ? undefined : status.status,
        payment_status:
          paymentStatus?.status === allPaymentStatus.status
            ? undefined
            : paymentStatus.status,
        order_date_from: startDate === "" ? undefined : startDate,
        order_date_to: endDate === "" ? undefined : endDate,
      },
    });
    setSelectedRows([]);
  };
  const onSearch = (value) => {
    setSearchValue(value);
    setPage(1);
  };
  const onChangePaymentStatus = (status, orders) => {
    dispatch({
      type: ACTION_TYPE.SYNC_TO_SAP_REQUEST,
      data: { ids: orders?.id },
      success: () => {
        refreshList();
        setMessageToast("Sync to SAP Successfully");
        setOpenToast(true);
      },
    });
  };

  const handleCloseToast = (event, reason) => {
    setOpenToast(false);
  };

  const renderOrderList = () => {
    return (
      <Fragment>
        <Snackbar
          open={openToast}
          autoHideDuration={6000}
          onClose={handleCloseToast}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleCloseToast} severity="success">
            {messageToast}
          </Alert>
        </Snackbar>
        <div className="page-header">
          <div className="page-title" style={{ width: "100%" }}>
            Danh sách đơn hàng thất bại{" "}
          </div>
          <SearchInput
            defaultValue={search_value}
            onSubmit={onSearch}
            placeholder="Mã đơn hàng/SĐT"
            type="number"
          />
        </div>
        <TableContainer component={Paper}>
          <Table padding="normal" style={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell>Mã đơn hàng</TableCell>
                <TableCell>Thời gian đặt</TableCell>
                <TableCell>Giá tổng (VNĐ)</TableCell>
                <TableCell style={{ width: "10%" }}>Tên Mặt hàng</TableCell>
                <TableCell>Tên khách hàng</TableCell>
                <TableCell>Số điện thoại</TableCell>
                <TableCell>Trạng thái thanh toán</TableCell>
                <TableCell>Đơn vị vận chuyển</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(ordersList) &&
                ordersList.map((orders) => {
                  return (
                    <TableRow key={orders?.id}>
                      <TableCell>{orders?.code}</TableCell>
                      <TableCell>
                        {formatDateTime(orders?.created_at)}
                      </TableCell>
                      <TableCell>{formatPrice(orders?.total)}</TableCell>
                      <TableCell>
                        <Popover
                          renderButton={() => (
                            <div
                              className="ellipsis"
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                {Array.isArray(orders?.order_details) &&
                                  orders.order_details
                                    .map(
                                      (item) => item?.product?.[LANG_VI]?.name
                                    )
                                    .join("; ")}
                              </span>
                            </div>
                          )}
                        >
                          <Box maxHeight={350} maxWidth={350}>
                            {Array.isArray(orders?.order_details) &&
                              orders.order_details.map((item, index) => {
                                return (
                                  <Box
                                    key={item?.id}
                                    px={2}
                                    py={1}
                                    borderBottom="1px solid rgba(224, 224, 224, 1)"
                                  >
                                    {index + 1}.{" "}
                                    {item?.product?.[LANG_VI]?.name}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Popover>
                      </TableCell>
                      <TableCell>
                        {orders?.order_shippings?.[0]?.name}
                      </TableCell>
                      <TableCell>
                        {orders?.order_shippings?.[0]?.phone_number}
                      </TableCell>
                      <TableCell>
                        <DangerButton
                          width={170}
                          onClick={(status) =>
                            onChangePaymentStatus(status, orders)
                          }
                          variant="outlined"
                          borderColor="#d32f2f"
                          type="label"
                        >
                          Sync lại cho SAP
                        </DangerButton>
                      </TableCell>
                      <TableCell>
                        {orders?.order_shippings?.[0]?.driver === 1
                          ? "Giao hàng tiết kiệm"
                          : "Long Thanh Plastic"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(totalRecords / PAGE_SIZE)}
          page={page}
          handleChangePage={setPage}
        />
      </Fragment>
    );
  };

  return (
    <BrowserRouter basename={urlOrdersFail}>
      <Switch>
        <Route exact path="/">
          {renderOrderList()}
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
