import * as Utils from "../../../utils";
import * as AppURL from "../../../services/urlAPI";
import { PAGE_SIZE_UNLIMIT } from "app/utils/constant";
import { getProductCategory, updateSortCategory } from "app/services/axios";
import { useState,useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getItems, reorder } from "./components/helpers";
import Paper from '@material-ui/core/Paper';
import DraggableListItem from "./components/DraggableListItem";
import DangerButton from "app/components/Button/DangerButton";
import { ClearSharp } from "@material-ui/icons";
import PrimaryButton from "app/components/Button/PrimaryButton";
import { Link, useParams } from "react-router-dom";
import { Done as DoneIcon } from '@material-ui/icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlProductCategory } from "app/Layouts/AuthenticatedLayout/Sidebar/url";
import Breadcrumbs from "app/components/Breadcrumbs";
  
export default function ChildrenProductCategories() {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [reMakedata, setReMakeData] = useState([]);
const history =useHistory()
    useEffect(() => {
        const url = Utils.replaceStrUrl(AppURL.getProductCategory, [
          1,
          PAGE_SIZE_UNLIMIT,
        ]);
        getProductCategory(url).then((data) => {
          const results = Utils.getSafeValue(data, "results", []);
          setData(results.sort((a, b) => a.order - b.order));
        });
      }, []);
  useEffect(()=>{
    data.map(item=>{
        setReMakeData(arr =>[...arr,{id:item.id,name:item.translates[0].language_value}])
    })
    
    
  },[data])

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = reorder(reMakedata, result.source.index,
        result.destination.index);

    setReMakeData(newItems);
  };
  const handleOpenAddModal = () => {
    let id = []
    reMakedata.map((item)=>{

        id=[...id,item.id]
    })
    updateSortCategory("/admin/product-category/orders",{items:id})
    history.push("/product-categories");
  }
  
    return (
       <>
       <div className="page-header">
       <div className="page-title">
              <Breadcrumbs>
                <Link to={urlProductCategory}>Danh mục sản phẩm</Link>
              
              </Breadcrumbs>
            </div>
        <PrimaryButton
          startIcon={<DoneIcon />}
          onClick={handleOpenAddModal}
        >
          Đồng ý
        </PrimaryButton>
        <DangerButton
          startIcon={<ClearSharp />}
        
          onClick={() =>  history.goBack()}
        >
          Hủy
        </DangerButton>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"1"} direction="vertical">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {reMakedata.map((item, index) =>(
                <DraggableListItem item={item} index={index} key={item.id} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
       </>
       
      
    )
}
const useStyles = makeStyles({
    flexPaper: {
      flex: 1,
      margin: 16,
      minWidth: 350
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  });