import axios from "../../axios";
import { apiCouponLoyalty, apiLoyaltyCoupon } from "../../axios/urlApi";

export const getLoyaltyCouponList = (params) => {
  return axios.get(`${apiLoyaltyCoupon}`, { params });
};

export const getLoyaltyCouponId = ({ id, ...params }) => {
  return axios.get(`${apiLoyaltyCoupon}/${id}`, { params });
};



export const editLoyaltyCoupon = ({ id, ...params }) => {
  return axios.put(`${apiLoyaltyCoupon}/${id}`, params);
};

export const deleteLoyaltyCoupons = (params) => {
    return axios.delete(`${apiLoyaltyCoupon}/${params.id}`, params)
};

export const createCouponLoyalty = (params) => {
  return axios.post(`${apiCouponLoyalty}`, params);
};
