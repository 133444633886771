import { useState, useEffect } from "react";
import lodash from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MTable from "@material-ui/core/Table";
import MTableBody from "@material-ui/core/TableBody";
import MTableCell from "@material-ui/core/TableCell";
import MTableContainer from "@material-ui/core/TableContainer";
import MTableHead from "@material-ui/core/TableHead";
import MTableRow from "@material-ui/core/TableRow";
import {
  Grid,
  Checkbox,
  Paper,
  Chip,
  Button,
  FormControl,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  TableContainer,
  Box,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Pagination from "app/components/Pagination";
import Select from "react-select";
import ConfirmModal from "app/components/ConfirmModal";
import DateRangePicker from "app/components/DateRangePicker";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SelectStatus from "./SelectStatus";
import { formatDateTime } from "app/utils/validate";
import { formatVND } from "app/utils/common";
import OrdersStatus, {
} from "../../../Orders/components/OrdersStatus";
import PaymentStatus, {
  paymentStatusList,
} from "../../../Orders/components/PaymentStatus";

import * as AppURL from "app/services/urlAPI";
import * as Utils from "app/utils";
import { getProductCategory, addNewCate, putApi, getTransactionWallet } from "app/services/axios";

function createData(
  id,
  transactionId,
  time,
  totalPrice,
  name,
  phoneNum,
  status
) {
  return { id, transactionId, time, totalPrice, name, phoneNum, status };
}

const mockData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) =>
  createData(
    item,
    `ABCXYZ${item}`,
    `02/02/2020 10:30`,
    "200.000.000 VNĐ",
    "Sản phẩm A, Thùng nhựa nặng ...",
    "0987321253",
    lodash.random(1, 4)
  )
);

const columns = [
  {
    id: 1,
    name: "code",
    text: "Mã giao dịch",
  },
  {
    id: 2,
    name: "created_at",
    text: "Ngày giờ đặt",
  },
  {
    id: 3,
    name: "type",
    text: "Loại giao dịch",
  },
  {
    id: 4,
    name: "send",
    text: "Người chuyển",
  },
  {
    id: 4,
    name: "receiver",
    text: "Người nhận",
  },
  {
    id: 5,
    name: "customer_receive",
    text: "Số xu",
  },
  {
    id: 6,
    name: "content",
    text: "Nội dung",
  },
  {
    id: 7,
    name: "status",
    text: "Trạng thái",
  },
 
];

const initialOrderStatus = {
  status: "",
  label: "Tất cả trạng thái",
};

const initialPaymentStatus = {
  status: '',
  label: "Trạng thái thanh toán",
  color: "unset",
};

const useStyles = makeStyles((theme) => ({
  main: {
    margin: "20px 0",
  },
  toolbar: {
    margin: "15px 0",
  },
  title: {
    fontSize: "18px",
    margin: 0,
    fontWeight: 600,
    lineHeight: "36px",
  },
  actionBlock: {
    // textAlign: "left",
    // width: "90%",
    marginRight: "15px",
  },
  dateRange: {
    width: "90%",
  },
  deleteForm: {
    textAlign: "right",
  },
  deleteButton: {
    backgroundColor: "#D70000",
    textTransform: "unset",
    height: "40px !important",
    width: "150px",
  },
  table: {
    backgroundColor: "#ffffff",
  },
  tableHeader: {
    color: "#99A6B7",
  },
  pagination: {
    margin: "20px 0",
    float: "right",
  },
  statusCompleted: {
    color: "#00B41D",
    border: "1px solid #9EE2B8",
    backgroundColor: "#ffffff",
    height: "24px",
    width: "135px",
  },
  statusPending: {
    color: "#F8B711",
    border: "1px solid #F3C51F",
    backgroundColor: "#ffffff",
    height: "24px",
    width: "135px",
  },
  statusCanceled: {
    color: "#EA403F",
    border: "1px solid #EA403F",
    backgroundColor: "#ffffff",
    height: "24px",
    width: "135px",
  },
  statusConfirmed: {
    color: "#858585",
    border: "1px solid #858585",
    backgroundColor: "#ffffff",
    height: "24px",
    width: "135px",
  },
  statusConfirmedPending: {
    color: "#ffc043",
    border: "1px solid #ffc043",
    backgroundColor: "#ffffff",
    height: "24px",
    width: "135px",
  },
  statusReturn: {
    color: "#dbff00",
    border: "1px solid #dbff00",
    backgroundColor: "#ffffff",
    height: "24px",
    width: "135px",
  },
  searchForm: {
    fontSize: "14px",
    display: "flex",
    width: "200px",
  },
  searchFormLabel: {
    fontSize: "14px",
    marginTop: "-8px",
    color: "#898989",
  },
  searchFormInput: {
    fontSize: "14px",
    height: "40px",
    borderColor: "#E2E2E2",
    width: "90%",
    backgroundColor: "#ffffff",
  },
  searchFormIcon: {
    height: "36px",
    width: "36px",
  },
  icon: {
    height: "10px",
    width: "10px",
    marginRight: "10px",
  },
  iconCompleted: {
    height: "10px",
    width: "10px",
    color: "#00c537",
    marginRight: "10px",
  },
  iconPending: {
    height: "10px",
    width: "10px",
    color: "#f7b611",
    marginRight: "10px",
  },
  iconConfirmed: {
    height: "10px",
    width: "10px",
    color: "#383838",
    marginRight: "10px",
  },
  iconDelivering: {
    height: "10px",
    width: "10px",
    color: "#276ef1",
    marginRight: "10px",
  },
  iconCanceled: {
    height: "10px",
    width: "10px",
    color: "#ea403f",
    marginRight: "10px",
  },
  iconReturn: {
    height: "10px",
    width: "10px",
    color: "#DBFF00",
    marginRight: "10px",
  },
  add:{
    color:'green'
  },
  error:{
    color:'red'
  },

  iconConfirmedPending: {
    height: "10px",
    width: "10px",
    color: "#FF8947",
    marginRight: "10px",
  },
  menuFloat: {
    fontSize: "14px",
  },
  textBlue: {
    color: "#007BFF !important",
    cursor: "pointer",
  },
  input: {
    width: "100%",
  },
  ellipsis: {
    display: "inline-block",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#373737",
      fontSize: "14px",
    };
  },
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function TransactionWallet({ id,name,coin }) {
  const classes = useStyles();
  const history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [isOpenDeleteOrderConfirmation, setIsOpenDeleteOrderConfirmation] =
    useState(false);
     const ordersStatusList = [
        {
          status: 'PENDING',
          label: "Đang xử lý",
          
        },
        {
          status: 'SUCCESS',
          label: "Thành công",
          
        },
        {
          status: 'FAILED',
          label: "Thất bại",
          
        },
       
      ];
  const [selectedItem, setSelectedItem] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [orderFilterList, setOrderFilterList] = useState([
    initialOrderStatus,
    ...ordersStatusList,
  ]);
  const [paymentList, setPaymentList] = useState([
    initialPaymentStatus,
    ...paymentStatusList,
  ]);
  const [currentStatusOrder, setCurrentStatusOrder] =
    useState(initialOrderStatus);
  const [currentPaymentStatus, setCurrentPaymentStatus] =
    useState(initialPaymentStatus);
  const [query, setQuery] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPayment, setAnchorElPayment] = useState(null);

  useEffect(async () => {
    try {
        console.log(id)
      if (id) {
        let url = Utils.replaceStrUrl(AppURL.transactionWallet, [
          id,
          currentPage,
          10,
        ]);
        if (query !== "") url += `&code=${query}`;
        if (currentStatusOrder.status !== '')
          url += `&status=${currentStatusOrder.status}`;
      
        if (startDate !== "") url += `&from_date=${startDate}`;
        if (endDate !== "") url += `&to_date=${endDate}`;
        console.log(url)
        await getTransactionWallet(url).then((res) => {
            console.log(res)
          if (res.code === 200) {
            console.log(res)
            const data = Utils.getSafeValue(res, "data", {});
            const results = Utils.getSafeValue(data, "results", []);
            const total = Utils.getSafeValue(data, "totalRecords", 0);
            setData(results);
            setTotal(total);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    id,
    currentPage,
    query,
    currentStatusOrder,
    startDate,
    endDate,
  ]);

  



  const handleChangePage = (event, newPage) => {
    console.log("handleChangePage", newPage);
    setCurrentPage(newPage);
  };

  
  

  const setRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    // setPage(1);
  };




  

  return (
    <Grid className={classes.main}>
        <Box flexDirection={'row'} alignItems='center' display={'flex'}>
      <h3 className={classes.title} style={{marginRight:"12px"}}>
        <span className={classes.titleLink}>Lịch sử giao dịch của ví</span>
      </h3>
      <Box flexDirection={'row'} display={'flex'} justifyContent="center"  borderRadius={55} fontSize={10} minWidth="16%" sx={{backgroundColor:"blue !important" }}>
      <h1 style={{color:"white"}}>Số xu: </h1>
        <h1 style={{color:"#F5A328",marginLeft:"6px",marginRight:"6px"}}>{coin}</h1>
      <img src="/imgs/coin.svg"/>
      </Box>
      </Box>
      <div className="page-header">
        <FormControl variant="outlined" className={classes.searchForm}>
          <InputLabel className={classes.searchFormLabel}>
            Mã giao dịch
          </InputLabel>
          <OutlinedInput
            size="small"
            type="text"
            className={classes.searchFormInput}
            endAdornment={
              <InputAdornment position="end" className={classes.searchFormIcon}>
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </FormControl>
        <div className={classes.actionBlock}>
          <SelectStatus
            width={210}
            statusList={orderFilterList}
            onChange={(value) => setCurrentStatusOrder(value)}
            value={currentStatusOrder}
          />
        </div>
      
        <Grid style={{ paddingRight: "10px" }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={setRange}
            label="Ngày giao dịch"
          />
        </Grid>
    
      </div>
      <div className={classes.table}>
        <MTableContainer component={Paper}>
          <MTable padding="normal" style={{ minWidth: 1500 }}>
            <MTableHead>
              <MTableRow>
                
                {columns.map((item) => (
                  <MTableCell key={item.id} className={classes.tableHeader}>
                    {item.text}
                  </MTableCell>
                ))}
              </MTableRow>
            </MTableHead>
            <MTableBody>
              {data.map((row) => {
                const id = Utils.getSafeValue(row, "id", 0);
                const code = Utils.getSafeValue(row, "code", "");
                const created_at = Utils.getSafeValue(row, "created_at", "");
                const type = Utils.getSafeValue(row, "type", "");
                const receive = Utils.getSafeValue(row?.customer_receive, "name", "");
                const send = Utils.getSafeValue(row?.customer_send, "name", "");
                const content = Utils.getSafeValue(row, "content", "");
                const status = Utils.getSafeValue(row, "status", "");
                const coin = Utils.getSafeValue(row, "action", "") +' ' +  Utils.getSafeValue(row, "point",0);
             console.log(Utils.getSafeValue(row, "action", "") ==='-')
                return (
                  <MTableRow key={id}>
                    <MTableCell>
                      <span
                      >
                        {code}
                      </span>

                    </MTableCell>
                    <MTableCell>{formatDateTime(created_at)}</MTableCell>
                    <MTableCell>{type}</MTableCell>
                    <MTableCell>
                      {send ? send :name}
                    </MTableCell>
                    <MTableCell>
                      {receive}
                    </MTableCell>
                    <MTableCell>
                    <span
                    
                    className={Utils.getSafeValue(row, "action", "") ==='-' ?classes.error :classes.add}
                      >
                      {coin}
                      </span>

                    </MTableCell>
                    <MTableCell>
                      {content}
                    </MTableCell>
                    <MTableCell>
                      {status}
                    </MTableCell>
                   
                   
               
                  
                  </MTableRow>
                );
              })}
            </MTableBody>
          </MTable>
        </MTableContainer>
        <Pagination count={Math.ceil(total / 10)} onChange={handleChangePage} />
      </div>
     
    </Grid>
  );
}
