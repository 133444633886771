export const baseUrl =
  process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BASE_URL_VERSION;

export const urlLogin = "/admin/auth/login";
export const apiOrders = "/admin/orders";

export const apiProductList = "/admin/product/sap-products";
export const apiProductReviews = "/admin/product-review";
export const apiUploadImage = "/admin-media-upload";
//Coupon loyalty
export const apiCustomCard = "/admin/customer-card-types";
export const apiCouponLoyalty = "/admin/coupon-loyalties";
// FlashSale
export const apiFlashSale = "/admin/flash-sale";
// export const apiFlashSale = "/admin/flash-sale";
//Promotion Slider
export const apiPromotions = "/admin/slider";

//Authorization
export const apiPermissionGroup = "/admin/group";

export const apiCountry = "/customer/country";
export const apiCity = "/customer/city";
export const apiDistrict = "/customer/district";
export const apiWard = "/customer/ward";

export const apiBranch = "/admin/branch";

export const apiShop = "/admin/shop";

export const apiCombo = "/admin/product-combo";
export const apiCoupon = "/admin/coupon";
export const apiListProductCoupon = "/admin/coupon/get-product-list";
export const apiCharity = "/admin/charity";
export const apiProduct = "/admin/product";
export const apiStaticPage = "/admin/pages";
export const apiWarehouse = "admin/inventory";
export const apiProfile = "admin/users/profile";
export const apiEmail = "/admin/email-subscription";
export const apiLoyaltyCoupon = "/admin/coupon-loyalties";
export const apiCouponSharing = "/admin/coupon-friends";
export const apiRanking = "/admin/customer-card-types";

export const apiVariantsColor = "/admin/product/config-product-attributes";
export const apiAddVariantValue = "/admin/product/config-product-attributes";

//contact
export const apiContact = "/admin/customer-contact-form";

//user-mangement
export const apiExportFileListUsers = "/admin/customers/export";
export const apiCouponLoyaltyByIdCustomer =
  "/admin/coupon-loyalties/customer-coupon-loyalties";
export const apiCouponFriendsByIdCustomer =
  "/admin/coupon-friends/customer-coupon-friends";

//news
export const apiNews = "admin/news";

// Sync online payment order to SAP
export const apiSyncToSap = "/admin/orders/sync-to-sap";

export const apiSapCheckConnection = "/admin/sap/ping";
