import instance from "app/axios";
import { apiOrders, apiSyncToSap } from "app/axios/urlApi";
import { ACTION_TYPE } from "app/reducers/ordersFail";
import { SAGA_THROTTLE } from "app/utils/constant";
import { call, put, takeLatest, throttle } from "redux-saga/effects";

function* getOrdersList(action) {
  const { success, error, params } = action;
  try {
    const response = yield call(() =>
      instance.get(`${apiOrders}/online-payment-orders-sync-to-sap-failed`, {
        params,
      })
    );
    yield put({
      type: ACTION_TYPE.GET_ORDERS_FAIL_LIST_SUCCESS,
      payload: response?.data?.data,
    });
    success && success(response);
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_ORDERS_FAIL_LIST_ERROR,
      payload: e,
    });
    error && error(e?.message);
  }
}

function* watchGetOrdersList() {
  yield takeLatest(ACTION_TYPE.GET_ORDERS_FAIL_LIST_REQUEST, getOrdersList);
}

function* deleteCombo(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      instance.post(`${apiOrders}/${data.ids}`)
    );
    yield put({
      type: ACTION_TYPE.DELETE_COMBO_LIST_SUCCESS,
      payload: response?.data,
    });
    success && success(response);
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_COMBO_LIST_ERROR,
      payload: e,
    });
    error && error(e?.message);
  }
}
function* watchDeleteCombo() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.DELETE_COMBO_LIST_REQUEST,
    deleteCombo
  );
}

function* syncToSap(action) {
  const { data, success, error } = action;

  try {
    const response = yield call(() =>
      instance.post(`${apiSyncToSap}/${data.ids}`)
    );
    yield put({
      type: ACTION_TYPE.SYNC_TO_SAP_SUCCESS,
      payload: response?.data,
    });
    success && success(response?.data);
  } catch (e) {
    console.log(e);
    yield put({
      type: ACTION_TYPE.DELETE_COMBO_LIST_ERROR,
      payload: e?.data,
    });
    error && error(e?.data);
  }
}

function* watchSyncToSAP() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.SYNC_TO_SAP_REQUEST, syncToSap);
}

const saga = [watchGetOrdersList, watchDeleteCombo, watchSyncToSAP];

export default saga;
