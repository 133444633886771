import {  urlProductCategory } from "app/Layouts/AuthenticatedLayout/Sidebar/url";
import { Route, Switch } from "react-router-dom";
import ProductCategories from "app/pages/ProductCategories";
import ChildrenProductCategories from "app/pages/ProductCategories/ChildrenProductCategories";
import GrandChildrenProductCategories from "./ChildrenProductCategories/GrandChildrenProductCategories";
import SortCategories from "./SortCategories";
import ChildrenProductCategoriesSort from "./ChildrenProductCategories/components/ChildrenCategoriesSort";

const ProductCategoryRouter = () => {
  return (
    <Switch>
      <Route exact path={urlProductCategory} component={ProductCategories} />
    <Route exact path={`${urlProductCategory}/sort`} component={SortCategories} />
    <Route
        exact
        path={`${urlProductCategory}/:id/sort`}
        component={ChildrenProductCategoriesSort}
      />
      <Route
        exact
        path={`${urlProductCategory}/:id`}
        component={ChildrenProductCategories}
      />
      <Route
        exact
        path={`${urlProductCategory}/:id/:grandChildrenId`}
        component={GrandChildrenProductCategories}
      />
      <Route
        exact
        path={`${urlProductCategory}/:id/:grandChildrenId/sort`}
        component={ChildrenProductCategoriesSort}
      />
    </Switch>
  );
};

export default ProductCategoryRouter;
