import { Grid, IconButton, Modal, Select as SelectLib,
  MenuItem,
  OutlinedInput,
  FormControl,
  Box,makeStyles
 } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DefaultButton from "app/components/Button/DefaultButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import TextFields from "app/components/TextFields";
import { isNumeric } from "app/utils/common";
import { useEffect, useState } from "react";
import Input from "./Input";
import { formatPrice, isEmpty } from "app/utils/validate";

const EditFlashSale = ({
  flashsale,
  setFlashsale,
  modal,
  setModal,
  callbackSubmit,
  details,
}) => {
  const [dataEdit, setDataEdit] = useState({ ...flashsale });
  const [conditionPrice, setConditionPrice] = useState({ value: null });
  const [typePriceDiscount, setTypePriceDiscount] = useState("");
  const classes = useStyles();
console.log(flashsale)
console.log(details)
  const [minText, setMinText] = useState("");
  const [maxText, setMaxText] = useState("");
  const [percentText, setPercentText] = useState("");
  const [errorDiscount, setErrorDiscount] = useState({
    min: "",
    max: "",
    discount: "",
    type:0
  });
  console.log(errorDiscount);

  useEffect(() => {
    if (flashsale) {
      const { min, max, percentage } = flashsale;
      setMinText(min);
      setMaxText(max);
      setConditionPrice({value:percentage ? percentage : flashsale?.discount});
setTypePriceDiscount(flashsale?.type)
      // setPercentText(percentage ? percentage : flashsale?.discount  );
    }
    // return () => {
    //   setFlashsale(null);
    // };
  }, [flashsale, setFlashsale]);

  const onChangePercentage = (e) => {
    const value = e || undefined;
    const isValidNumber = isNumeric(value || 0);
    if (!isValidNumber) return;
    if (parseInt(value) > 100) {
      value = 100;
    }
    setPercentText(value);
    setDataEdit({ ...dataEdit, percentage: value });
  };

  const validateFlashSale = () => {
    const percentage = Number(percentText);
    const min = Number(minText);
    const max = Number(maxText);

    setErrorDiscount({
      min: "",
      max: "",
      discount: "",
      type:0
    });
    if (!min) {
      setErrorDiscount({
        ...errorDiscount,
        min: "min phải có",
      });
      return false;
    }
    
    // if (details.length && !max) {
    //   setErrorDiscount({
    //     ...errorDiscount,
    //     max: "max phải có",
    //   });
    //   return false;
    // }
    if (!conditionPrice?.value && typePriceDiscount) {
      setErrorDiscount({
        ...errorDiscount,
        discount: "Giá trị giảm giá được yêu cầu",
      });
      return false;
    }
    if (max && min > max) {
      setErrorDiscount({
        ...errorDiscount,
        min: "min không được lớn hơn max",
        max: "min không được lớn hơn max",
      });
      return false;
    }
    // if (details?.length && !details[details?.length - 1]?.max) {
    //   setErrorDiscount({
    //     ...errorDiscount,
    //     min: "phải set max của discount trước",
    //   });
    //   return false;
    // }
    
    const index = [...(details || [])].findIndex(
      (item) => item?.id === flashsale?.id
    );

    if (index <= details?.length - 2 && !max) {
      setErrorDiscount({
        ...errorDiscount,
        max: "max phải có",
      });
      return false;
    }

    if (index >= 1 && index <= details?.length - 2) {
      if (min <= details[index - 1]?.max || max >= details[index + 1]?.min) {
        setErrorDiscount({
          ...errorDiscount,
          min: "không hợp lệ",
          max: "không hợp lệ",
        });
        return false;
      }
    } else if (index === 0 && index <= details?.length - 2) {
      if (max >= details[index + 1]?.min) {
        setErrorDiscount({
          ...errorDiscount,
          min: "không hợp lệ",
          max: "không hợp lệ",
        });
        return false;
      }
    } else if (index === details?.length - 1 && index >= 1) {
      if (min <= details[index - 1]?.max) {
        setErrorDiscount({
          ...errorDiscount,
          min: "không hợp lệ",
          max: "không hợp lệ",
        });
        return false;
      }
    }
    return true;
  };

  const onSubmit = () => {
    if (!validateFlashSale()) return;
   
console.log("dataEdit",dataEdit)
    callbackSubmit({ ...flashsale, ...dataEdit });
    setFlashsale({ ...flashsale, ...dataEdit });
    handleModal();
  };
  const handleModal = () => {
    setErrorDiscount({ min: "", max: "", discount: "" });
    setFlashsale(null);
    setModal(!modal);
  };
  const onChangeTypePriceDiscount = (value) => {
    setTypePriceDiscount(value);
  };
  const getBorderColor = () => {
    return   errorDiscount?.discount ? '#f44336' : 'transparent';
  };
  const onChaneDiscount = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (/^0{2,}/.test(value) || /\D+/.test(value)) {
      return;
    }
    if (typePriceDiscount === 1) {
      if (e.target.value <= 100) {
        if (parseInt(e.target.value) > 100) {
          setConditionPrice({ value: "100" });
        } else {
          setConditionPrice({ value: e.target.value });
        }
      }
    } else {
      if(flashsale?.price < value){
        value = flashsale?.price
      }
      if (/^0[1-9]/.test(value)) {
        setConditionPrice({ value: value.slice(1) });
      } else {
        setConditionPrice({ value: value });
      }
    }
   
  };
  useEffect(()=>{
    if(conditionPrice?.value){
      if(typePriceDiscount === 2){
        setDataEdit({ ...dataEdit, discount: conditionPrice?.value ,percentage:null,type:typePriceDiscount });
  
      }else{
        setDataEdit({ ...dataEdit, percentage: conditionPrice?.value,discount:null ,type:typePriceDiscount });
  
      }
    }
  },[conditionPrice])
  return (
    <Modal open={modal} onClose={handleModal} style={{ outline: "none" }}>
      <div className="modal-content" style={{ maxWidth: 650 }}>
        <div className="modal-header">
          <div className="modal-title">Chỉnh sửa discount flashsale</div>
          <IconButton onClick={handleModal} style={{ padding: 3 }}>
            <Close />
          </IconButton>
        </div>
        <div className="modal-body">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                id="min"
                error={errorDiscount.min}
                labelName="Min"
                required
                placeholder="min"
                type="number"
                defaultValue={minText}
                onChange={(e) => {
                  setMinText(e);
                  setDataEdit({
                    ...dataEdit,
                    min: e || undefined,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                id="max"
                error={errorDiscount.max}
                labelName="Max"
                placeholder="max"
                type="number"
                defaultValue={maxText}
                onChange={(e) => {
                  setMaxText(e);
                  setDataEdit({
                    ...dataEdit,
                    max: e || undefined,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
            <TextFields
                label="Giá trị giảm giá"
                helperText="Giá trị giảm giá đươc yêu cầu"

                error={
                  errorDiscount?.discount
                }
              >
                <Box display="flex" alignItems="center">
                <div style={{ border: `1px solid ${getBorderColor()}`, borderRadius: 4, width: '100%', height: 38 }}>

                  <OutlinedInput
                    disabled={!typePriceDiscount}
                    style={{ width: "100%", height: 38 }}
                    value={
                      typePriceDiscount === 1
                        ? conditionPrice.value
                        : formatPrice(conditionPrice.value)
                    }
                    placeholder="Nhập giá trị giảm giá"
                    error={errorDiscount?.discount}
                    onChange={onChaneDiscount}
                    sx={{ flexGrow: 1 }}
                  />
                </div>

                  <FormControl style={{ minWidth: 70 }}>
                    <SelectLib
                      // disabled={disable || detail}
                      style={{ marginLeft: 8, fontSize: 14, fontWeight: 700, border: "solid 1px #b3b3b3", borderRadius: "4px" }}
                      // name="promo_type_discount"
                      value={typePriceDiscount}
                    error={  !typePriceDiscount}

                      onChange={(e) => {
                        setConditionPrice({ value: "" });
                        onChangeTypePriceDiscount(e.target.value)
                      }}
                      disableUnderline
                      className={ !typePriceDiscount ? `${classes.select} ${classes.selecError}` : `${classes.select} ${classes.selectNoError}`}

                      MenuProps={{ classes: { paper: classes.menuProps } }}


                    >
                      <MenuItem value={2}>
                        <Box>VNĐ</Box>
                      </MenuItem>
                      <MenuItem value={1}>
                        <Box> % </Box>
                      </MenuItem>
                    </SelectLib>
                  </FormControl>
                </Box>
              </TextFields>
            </Grid>
          </Grid>
        </div>
        <div className="modal-footer">
          <DefaultButton onClick={handleModal}>Hủy</DefaultButton>
          <PrimaryButton onClick={onSubmit}>Lưu lại</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    height: '100%',
    fontSize: "12px",
    fontWeight: 500,
    padding: 0,
    margin: 0,
    borderRadius: "5px",
    "& :focus": {
      backgroundColor: "transparent",
    },
    ".MuiNativeSelect-select": {
      backgroundColor: 'black'
    },
    "&.MuiInput-root": {
      padding: "0px 16px",
    },

    "& .Mui-disabled span": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  selectNoError: {
    border: "1px solid #B3B3B3 !important",
  },
  selecError: {
    border: "1px solid #EA403F !important",
  },
  menuProps: {
    paddingLeft: 0,
    marginTop: "55px",
    backgroundColor: 'white',
  },
}))
export default EditFlashSale;
