import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Add, DeleteOutline, Edit } from "@material-ui/icons";
import DangerButton from "app/components/Button/DangerButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import Pagination from "app/components/Pagination";
import SearchInput from "app/components/SearchInput";
import SelectStatus from "app/components/SelectStatus";
import SwitchUI from "app/components/Switch";
import {
  fetchDeleteCoupons,
  fetchListCoupon,
  fetchUpdateCoupon,
} from "app/reducers/coupon";
import { PAGE_SIZE } from "app/utils/constant";
import * as Utils from "app/utils";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";

import {
  urlCoupon,
  urlCouponLoyalty,
  urlCouponSharing,
} from "app/Layouts/AuthenticatedLayout/Sidebar/url";
import {
  fetchDeleteLoyaltyCoupons,
  fetchListLoyaltyCoupon,
} from "app/reducers/loyaltyCoupon";
import { fetchDeleteCouponSharings, fetchListCouponSharing } from "app/reducers/couponSharing";
import CreateEditCouponSharing from "./CreateEditCouponSharing";
const allStatus = { status: "0", label: "Tất cả trạng thái" };
const CouponSharing = () => {
  const dispatch = useDispatch();
  const { listCoupon, isLoading, totalCoupons } = useSelector(
    (state) => state.couponSharing
  );
  const [page, setPage] = useState(1);
  const [search_value, setSearchValue] = useState("");
  const [status, setStatus] = useState(allStatus);
  const couponList = listCoupon;
  const [coupon, setCoupon] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    dispatch(
      fetchListCouponSharing({
        page: page,
        limit: PAGE_SIZE,
        search: search_value || null,
        customer_card_type_id: null,
      })
    );
  }, [page, search_value]);

  const checkedAll = (e) => {
    if (e.target.checked) {
      if (Array.isArray(couponList))
        setSelectedRows(couponList.map((item) => item?.id));
    } else {
      setSelectedRows([]);
    }
  };

  const checkedRow = (e, row) => {
    let selected = [...selectedRows];
    if (e.target.checked) {
      selected.push(row?.id);
    } else {
      selected = selected.filter((item) => item !== row?.id);
    }
    setSelectedRows(selected);
  };

  const onSearch = (value) => {
    setSearchValue(value);
    setPage(1);
  };
  const changeStatus = (value) => {
    setStatus(value);
    setPage(1);
  };
  const deleteCoupon = () => {
    setConfirmDelete(false);
    if (selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        dispatch(fetchDeleteCouponSharings({ id: selectedRows[i] })).then(
          (s) => {
            refreshList();
          }
        );
      }
    }
    selectedRows.length = 0;
  };
  const refreshList = () => {
    dispatch(
      fetchListCouponSharing({
        page: page,
        limit: PAGE_SIZE,
        search: search_value || null,
        customer_card_type_id: null,
      })
    );
  };
  const onChangeStatusCoupon = (item = {}) => {
    const { requirements, id, ...rest } = item;
    const body = {
      ...rest,
      status: item.status === 1 ? -1 : 1,
      contents: item.translates,
      order_requirements: requirements,
      product_requirements: requirements,
    };
    dispatch(fetchUpdateCoupon({ ...body, id }));
  };
  const renderCouponSharing = () => {
    return (
      <Fragment>
        <ConfirmDelete
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          onOK={deleteCoupon}
          title="XÓA COUPON CHIA SẺ"
          message="Bạn có chắc muốn xóa coupon đã chọn?"
          cancelTitle="Hủy bỏ"
          okTitle="Xóa"
        />
        <div className="page-header">
          <div className="page-title">Quản lý chia sẻ</div>
          <PrimaryButton
            component={Link}
            to="/create"
            startIcon={<Add />}
            onClick={() => setCoupon({})}
          >
            Thêm mới
          </PrimaryButton>
          <DangerButton
            startIcon={<DeleteOutline />}
            disabled={selectedRows.length === 0}
            onClick={() => setConfirmDelete(true)}
          >
            Xóa đã chọn
          </DangerButton>
        </div>
        <TableContainer component={Paper}>
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < couponList?.length
                    }
                    checked={couponList?.length > 0 && selectedRows.length > 0}
                    onChange={checkedAll}
                  />
                </TableCell>
                <TableCell>Mã chia sẻ</TableCell>
                <TableCell>Số lượng tối thiểu</TableCell>
                <TableCell>Giá trị</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell align="center">Chỉnh sửa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(couponList) &&
                couponList.map((coupon) => {
                  const translates = Utils.getSafeValue(
                    coupon,
                    "translates",
                    []
                  );
                  const name = Utils.getObjByLanguage(
                    translates,
                    "vi",
                    "language_value"
                  );
                  return (
                    <TableRow key={coupon?.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.indexOf(coupon?.id) > -1}
                          onChange={(e) => checkedRow(e, coupon)}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/${coupon?.id}`}
                          onClick={() => setCoupon(coupon)}
                          style={{
                            textDecoration: "none",
                            textTransform: "uppercase",
                          }}
                        >
                          {coupon?.code}
                        </Link>
                      </TableCell>
                      <TableCell>{coupon?.min}</TableCell>
                      <TableCell>{coupon?.min_money_spend}</TableCell>
                      <TableCell>
                        <SwitchUI checked={coupon?.active_status === 1} />
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          to={`/${coupon?.id}`}
                          onClick={() => setCoupon(coupon)}
                          style={{
                            textDecoration: "none",
                            textTransform: "uppercase",
                            color: "black",
                          }}
                        >
                          <Edit />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(totalCoupons / PAGE_SIZE)}
          page={page}
          handleChangePage={setPage}
        />
      </Fragment>
    );
  };

  return (
    <BrowserRouter basename={urlCouponSharing}>
      <Switch>
        <Route exact path="/">
          {renderCouponSharing()}
        </Route>
          <Route exact path="/create">
            <CreateEditCouponSharing coupon={{}} />
          </Route>
          <Route exact path="/:id">
            <CreateEditCouponSharing coupon={coupon} deleteCoupon={deleteCoupon} />
          </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default CouponSharing;
