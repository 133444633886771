import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Link as LinkUI,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Breadcrumbs from "app/components/Breadcrumbs";
import DangerButton from "app/components/Button/DangerButton";
import DefaultButton from "app/components/Button/DefaultButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import ConfirmDelete from "app/components/ConfirmDelete";
import Switch from "app/components/Switch";
import TextFields from "app/components/TextFields";
import {
  fetchCoupon,
  fetchCreateCoupon,
  fetchDeleteCoupons,
  fetchListCoupon,
  fetchUpdateCoupon,
} from "app/reducers/coupon";
import { getTranslateField } from "app/utils";
import { LANG_EN, LANG_VI, PAGE_SIZE } from "app/utils/constant";
import { formatDateTime, formatPrice, isEmpty } from "app/utils/validate";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Lodash from "lodash";
import { getCustomCard as _getCustomCard } from "app/services/flashsale";
import SelectLang from "app/components/SelectLang";
import Textarea from "../../Promotions/components/Textarea";
import {
  fetchCreateCouponLoyalty,
  fetchDeleteLoyaltyCoupons,
  fetchListLoyaltyCoupon,
  fetchLoyaltyCoupon,
  fetchUpdateLoyaltyCoupon,
} from "app/reducers/loyaltyCoupon";
import { isNumeric } from "app/utils/common";

const objectCondition = {
  percentage: null,
  price: null,
  product: null,
  quantity: null,
  type: 1,
  value: null,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CouponLoyaltyCreate = ({ coupon }) => {
  const [customerCard, setCustomerCard] = useState([]);
  const [tempVoucher, setTempVoucher] = useState([]);
  const { coupon: couponDetail } = useSelector((state) => state.loyaltyCoupon);
  const [dataDetail, setDataDetail] = useState(coupon || couponDetail || {});
  const params = useParams();
  const [disable, setDisable] = useState(false);
  const [conditionPrice, setConditionPrice] = useState({ value: null });
  const [validate, setValidate] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [lang, setLang] = useState(LANG_VI);
  const [openToastError, setOpenToastError] = useState(false);
  const [messageToastError, setMessageToastError] = useState("");
  const [detail, setDetail] = useState(true);
  useEffect(() => {
    if (params.id) {
      dispatch(fetchLoyaltyCoupon({ id: params.id }));
    }
  }, [params?.id]);
  useEffect(() => {
    const getCustomerCard = async () => {
      const res = await _getCustomCard();
      const { data } = await res.data;

      const listStructure =
        data.length > 0
          ? data.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setCustomerCard(listStructure);
    };
    getCustomerCard();
  }, []);
  useEffect(() => {
    const getCustomerCard = async () => {
      const res = await _getCustomCard();
      const { data } = await res.data;
      const dataVoucher = data.find(
        (item) => item.id === dataDetail.customer_card_type_id
      );
      const dataVoucherTemp = dataVoucher?.config_rewards.at(0);
      let tempData = [];
      // if (dataVoucherTemp?.is_evoucher_birthday === 1) {
      //   tempData = [
      //     ...tempData,
      //     {
      //       id: 4,
      //       name:
      //         lang === LANG_VI ? "E-voucher sinh nhật" : "E-voucher birthday",
      //     },
      //   ];
      // }
      if (dataVoucherTemp?.is_evoucher_sale === 1) {
        tempData = [
          ...tempData,
          {
            id: 3,
            name:
              lang === LANG_VI ? "e-voucher giảm giá" : "E-voucher discount",
          },
        ];
      }
      if (dataVoucherTemp?.is_freeship === 1) {
        tempData = [
          ...tempData,
          {
            id: 1,
            name: lang === LANG_VI ? "Miễn phí vận chuyển" : "Freeship",
          },
        ];
      }
      // if (dataVoucherTemp?.is_evoucher_cent === 1) {
      //   tempData = [
      //     ...tempData,
      //     { id: 2, name: lang === LANG_VI ? "e-voucher xu" : "E-voucher coin" },
      //   ];
      // }
      setTempVoucher(tempData);
    };
    getCustomerCard();
  }, [dataDetail.customer_card_type_id,lang]);
  useEffect(() => {
    if (dataDetail?.type === 1) setDisable(true);
    else setDisable(false);
  }, [dataDetail]);
  const handleCreate = () => {
    if (
      dataDetail.code &&
      dataDetail.expire_time &&
      dataDetail.type &&
      dataDetail.active_status &&
      dataDetail.customer_card_type_id
    ) {
      if (dataDetail.expire_time < 1) {
        setValidate(true);
        return;
      }
      if (isEmpty(dataDetail?.name_vi) && isEmpty(dataDetail?.name_en)) {
        setValidate(true);
        return;
      }
      if (isEmpty(dataDetail.name_en)) {
        dataDetail.name_en = dataDetail.name_vi;
      }
      if (isEmpty(dataDetail.name_vi)) {
        dataDetail.name_vi = dataDetail.name_en;
      }
      if (!(/^(\w|-)*$/).test(dataDetail?.code)) {
        setValidate(true);
        return;
      }
      if (dataDetail.expire_time > 24) {
        setValidate(true);
        return;
      }
      const data = {
        code: dataDetail.code,
        expire_time: dataDetail.expire_time,
        active_status: dataDetail.active_status,
        type: dataDetail.type?.id || dataDetail.type ,
        customer_card_type_id: dataDetail.customer_card_type_id?.id ||dataDetail.customer_card_type_id,
        total_price: dataDetail?.type === 1 ? null : dataDetail.total_price,
        sale_price: dataDetail?.type === 1 ? null : conditionPrice.value,
        sale_type:
          dataDetail?.type === 1
            ? null
            : dataDetail.sale_type
            ? dataDetail.sale_type
            : 2,
        translates: [
          {
            language_code: "vi",
            language_field: "name",
            language_value: dataDetail.name_vi,
          },
          {
            language_code: "en",
            language_field: "name",
            language_value: dataDetail.name_en,
          },
        ],
      };
      let dataCreate = {
        items: [data],
      };
      if (params.id) {
        dispatch(fetchUpdateLoyaltyCoupon({ ...data, id: params.id }))
          .then((s) => {
            if (s?.status === 200) {
              setDetail(true);
              setValidate(false);
              setLang(LANG_VI);
              history.push(`/${params.id}`);
            } else {
              if ((s.message || "").includes("code already exist")) {
                setMessageToastError("Mã khuyến mại đã tồn tại");
                setValidate(true);
              } else {
                setOpenToastError(true);
                setMessageToastError(s.message);
              }
            }
          })
          .catch((error) => {});
      } else {
        dispatch(fetchCreateCouponLoyalty({ ...dataCreate }))
          .then((s) => {
            if (s?.status === 201) {
              history.push("/");
            } else {
              setOpenToastError(true);
              setMessageToastError(s.message);
            }
          })
          .catch((error) => {});
      }
    } else {
      setValidate(true);
    }
  };
  const onChangeValue = (e, type, key) => {
    let name = e.target.name;
    if (name === "total_price") {
      setDataDetail({
        ...dataDetail,
        [name]: e.target.value.replaceAll(".", ""),
      });
      return;
    }
    if (name === "expire_time") {
      if (!isNumeric(e.target.value || 0)) return;
    }
    if (e === "expire_time") {
      if (!isNumeric(e.target.value || 0)) return;
    }
    switch (type) {
      case "switch":
        setDataDetail({
          ...dataDetail,
          [key]: dataDetail.status === 1 ? -1 : 1,
        });
        break;
      default:
        setDataDetail({
          ...dataDetail,
          [name]: e.target.value,
        });
        break;
    }
  };
  const onChaneDiscount = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (/^0{2,}/.test(value) || /\D+/.test(value)) {
      return;
    }
    if (dataDetail?.sale_type === 1) {
      if (e.target.value <= 100) {
        if (parseInt(e.target.value) > 100) {
          setConditionPrice({ value: "100" });
        } else {
          setConditionPrice({ value: e.target.value });
        }
      }
    } else {
      if (/^0[1-9]/.test(value)) {
        setConditionPrice({ value: value.slice(1) });
      } else {
        setConditionPrice({ value: value });
      }
    }
  };
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastError(false);
  };
  const onChangeDetail = (e) => {
    e.preventDefault();
    setValidate(false);
    setDetail(!detail);
  };
  useEffect(() => {
    if (!couponDetail) {
      history.replace("/");
      return;
    }
    if(!params.id)
    setDetail(Boolean(coupon?.id));
    if (couponDetail?.id && params?.id) {
      const obj_name_vi = getTranslateField(couponDetail?.translates || [], "vi");
      const obj_name_en = getTranslateField(couponDetail?.translates || [], "en");

      setDataDetail({
        ...couponDetail,
        name_vi: obj_name_vi.name,
        name_en: obj_name_en.name,
        customer_card_type_id: couponDetail.customer_card_type?.id,
      });

      setConditionPrice({ value: couponDetail.sale_price });
    }
  }, [couponDetail]);
 
  const handleChangeTextarea = (key, value) => {
    setDataDetail({
      ...dataDetail,
      [key]: value,
    });
  };
  return (
    <Fragment>
      <Snackbar
        open={openToastError}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseToast} severity="error">
          {messageToastError}
        </Alert>
      </Snackbar>
      <div className="page-header">
        {params?.id ? (
          detail ? (
            <Fragment>
              <div className="page-title">
                <Breadcrumbs>
                  <Link to="/">Coupon loyalty</Link>
                  <Typography>Chi tiết coupon loyalty</Typography>
                </Breadcrumbs>
              </div>
              <PrimaryButton onClick={onChangeDetail}>Chỉnh sửa</PrimaryButton>
            </Fragment>
          ) : (
            <Fragment>
              <div className="page-title">
                <Breadcrumbs>
                  <Link to="/">Coupon loyalty</Link>
                  <LinkUI href="#" onClick={()=>history.go(0)}>
                    Chi tiết coupon
                  </LinkUI>
                  <Typography>Chỉnh sửa</Typography>
                </Breadcrumbs>
              </div>

              <DefaultButton component={Link} onClick={() => history.go(0)}>
                Hủy
              </DefaultButton>
              <PrimaryButton onClick={handleCreate}>Lưu lại</PrimaryButton>
            </Fragment>
          )
        ) : (
          <Fragment>
            <div className="page-title">
              <Breadcrumbs>
                <Link to="/">Coupon loyalty</Link>
                <Typography>Thêm mới</Typography>
              </Breadcrumbs>
            </div>
            <DefaultButton component={Link} to="/">
              Hủy
            </DefaultButton>
            <PrimaryButton onClick={handleCreate}>Lưu Lại</PrimaryButton>
          </Fragment>
        )}
      </div>
      <div className="page-content">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box fontSize={16} fontWeight={600}>
            {lang === LANG_VI ? "Thông tin chung" : "General information"}
          </Box>
          <SelectLang
            value={lang}
            onChange={(e) => {
              setLang(e);
              setValidate(false);
            }}
          />
        </Box>
        {lang === LANG_VI ? (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Mã khuyến mãi"
                required
                name="code"
                disabled={detail}
                placeholder="Nhập mã khuyến mãi"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.code || ""}
                helperText={
                  validate && !dataDetail.code
                    ? "Mã khuyến mãi được yêu cầu"
                    : validate && /^(\w|-)*$/.test(dataDetail?.code) ? validate : "Mã khuyến mãi chỉ chưa kí tự , số , gạch ngang"
                }
                error={(validate && !dataDetail.code)||(validate && !(/^(\w|-)*$/.test(dataDetail?.code)))}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Hạng thành viên áp dụng"
                helperText="Hạng thành viên phải đươc yêu cầu"
                error={validate && !dataDetail.customer_card_type_id}
                required
              >
                <Select
                  name="customer_card_type_id"
                  value={dataDetail.customer_card_type_id || 0}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  disabled={detail}
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  renderValue={
                    dataDetail.customer_card_type_id ? undefined : () => <span className={classes.placeholder}>Chọn hạng thành viên</span>
                  }
                  className={`${classes.select} ${
                    validate &&
                    !dataDetail.customer_card_type_id &&
                    classes.selectError
                  }`}
                  MenuProps={{classes:{paper: classes.menuProps}}}
                >

                  {customerCard.map((data) => (
                    <MenuItem  key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  
                  ))}
                </Select>
              </TextFields>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Loại khuyến mãi"
                helperText="Loại khuyến mãi được yêu cầu"
                required
                error={validate && !dataDetail.type}
              >
                <Select
                  name="type"
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  
                  value={dataDetail.type || 0}
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  renderValue={
                    dataDetail.type ? undefined : () => <span className={classes.placeholder}>Chọn loại khuyến mãi</span>
                  }
                  disabled={detail || !(dataDetail.customer_card_type_id)}
                  className={`${classes.select} ${
                    validate && !dataDetail.type && classes.selectError
                  }`}
                  MenuProps={{classes:{paper: classes.menuProps}}}

                >
                  {tempVoucher.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </TextFields>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Trạng thái"
                helperText="Trạng thái phải được yêu cầu"
                error={validate && !dataDetail.active_status}
                required
              >
                <Select
                  name="active_status"
                  value={dataDetail.active_status || 0}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  placeholder="Chọn hạng thành viên"
                  style={{ width: "100%" }}
                  disableUnderline
                  renderValue={
                    dataDetail.active_status ? undefined : () => <span className={classes.placeholder}>Chọn trạng thái</span>
                  }
                  displayEmpty
                  required
                  disabled={detail}
                  
                  className={`${classes.select} ${
                    validate && !dataDetail.active_status && classes.selectError
                  }`}
                  MenuProps={{classes:{paper: classes.menuProps}}}

                >
                  <MenuItem value={-1}>
                    <Box>Ẩn</Box>
                  </MenuItem>
                  <MenuItem value={1}>
                    <Box>Hiện</Box>
                  </MenuItem>
                </Select>
              </TextFields>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Thời hạn sử dụng (tháng)"
                required
                disabled={detail}
                name="expire_time"
                defaultValue={0}
                placeholder="Nhập thời hạn sử dụng"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.expire_time}
                helperText={
                  validate && !dataDetail.expire_time
                    ? "Thời hạn sử dụng được yêu cầu"
                    : validate && dataDetail.expire_time < 1
                    ? "Thời hạn sử dụng không được nhỏ hơn 1"
                    :validate && dataDetail.expire_time > 24 ? "Thời hạn sử dụng không được lớn hơn 24 tháng" : validate
                }
                error={
                  (validate && !dataDetail.expire_time) ||
                  (validate && dataDetail.expire_time < 1) ||
                  (validate && dataDetail.expire_time > 24)
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFields
                required
                label="Mô tả ngắn"
                helperText={
                  validate && !dataDetail.name_vi
                    ? "Mô tả ngắn được yêu cầu"
                    : validate
                }
                error={validate && !dataDetail.name_vi}
              >
                <Textarea
                  readOnly={detail}
                  placeholder="Nhập mô tả ngắn"
                  defaultValue={dataDetail?.name_vi}
                  error={validate && !dataDetail.name_vi}
                  onChange={(e) => handleChangeTextarea("name_vi", e)}
                />
              </TextFields>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Coupon code"
                required
                name="code"
                disabled={detail}
                placeholder="Enter coupon code "
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.code || ""}
                helperText={
                  validate && !dataDetail.code
                    ? "Coupon code is required"
                    : validate && /^(\w|-)*$/.test(dataDetail?.code) ? validate : "Coupon code constain letter , number , dahses"
                }
                error={(validate && !dataDetail.code)||(validate && !(/^(\w|-)*$/.test(dataDetail?.code)))}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFields
                label="Applied membership"
                placeholder="Choose a applied membership"
                helperText="Membership is required"
                error={validate && !dataDetail.customer_card_type_id}
                required
              >
                <Select
                  name="customer_card_type_id"
                  value={dataDetail.customer_card_type_id || 0}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  renderValue={
                    dataDetail.customer_card_type_id ? undefined : () => <span className={classes.placeholder}>Choose an applied membership</span>
                  }
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  disabled={detail}
                  
                  className={`${classes.select} ${
                    validate &&
                    !dataDetail.customer_card_type_id &&
                    classes.selectError
                  }`}
                  MenuProps={{classes:{paper: classes.menuProps}}}

                >
                  {customerCard.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </TextFields>
            </Grid>
            <Grid item xs={12} md={4}>
            
              <TextFields
                label="Coupon type"
                helperText="Coupon type is required"
                placeholder="Choose a coupon type "
                required
                error={validate && !dataDetail.type}
              >
                <Select
                  name="type"
                  value={dataDetail.type}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  renderValue={
                    dataDetail.type ? undefined : () => <span className={classes.placeholder}>Choose a coupon type</span>
                  }
                  disabled={detail || !(dataDetail.customer_card_type_id)}
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  className={`${classes.select} ${
                    validate && !dataDetail.type && classes.selectError
                  }`}
                  MenuProps={{classes:{paper: classes.menuProps}}}

                >
                  {tempVoucher.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </TextFields>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Status"
                helperText="Status is required"
                error={validate && !dataDetail.active_status}
                required
              >
                <Select
                  name="active_status"
                  value={dataDetail?.active_status}
                  onChange={(e) => {
                    onChangeValue(e);
                  }}
                  renderValue={
                    dataDetail.active_status ? undefined : () => <span className={classes.placeholder}>Choose a status</span>
                  }
                  style={{ width: "100%" }}
                  disableUnderline
                  displayEmpty
                  required
                  disabled={detail}
                  className={`${classes.select} ${
                    validate &&
                    !dataDetail.active_status &&
                    classes.selectError
                  }`}
                  MenuProps={{classes:{paper: classes.menuProps}}}

                >
                  <MenuItem value={-1}>
                    <Box>Inactive</Box>
                  </MenuItem>
                  <MenuItem value={1}>
                    <Box>Active</Box>
                  </MenuItem>
                </Select>
              </TextFields>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextFields
                label="Expired date"
                required
                disabled={detail}
                name="expire_time"
                placeholder="Enter expired date"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => onChangeValue(e)}
                value={dataDetail?.expire_time}
                helperText={
                  validate && !dataDetail.expire_time
                    ? "Expired date is requied"
                    : validate && dataDetail.expire_time < 1
                    ? "Expired date < 0"
                    : validate
                }
                error={
                  (validate && !dataDetail.expire_time) ||
                  (validate && dataDetail.expire_time < 1)
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFields
                required
                label="Short description"
                helperText={
                  validate && !dataDetail.name_en
                    ? "Short description is required"
                    : validate
                }
                error={validate && !dataDetail.name_en}
              >
                <Textarea
                  readOnly={detail}
                  placeholder="Enter short description"
                  defaultValue={dataDetail?.name_en}
                  error={validate && !dataDetail.name_en}
                  onChange={(e) => handleChangeTextarea("name_en", e)}
                />
              </TextFields>
            </Grid>
          </Grid>
        )}
      </div>
      {lang === LANG_VI ? (
        <>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Chi tiết khuyến mãi
              </Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Giá trị hóa đơn để được áp dụng"
                  placeholder="Nhập giá trị hóa đơn"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  name="total_price"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.type === 1 ? 0 : formatPrice(dataDetail?.total_price)}
                  helperText="Giá hoá đơn phải được yêu cầu"
                  error={
                    validate &&
                    !(dataDetail?.type === 1) &&
                    !dataDetail.total_price
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VNĐ</strong>
                      </span>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextFields
                  label="Giá trị giảm giá"
                  helperText="Giá trị giảm giá đươc yêu cầu"
                  error={
                    validate &&
                    !(dataDetail?.type === 1) &&
                    !conditionPrice.value
                  }
                >
                  <Box display="flex" alignItems="center">
                    <OutlinedInput
                      disabled={disable || detail}
                      style={{ width: "100%", height: 38 }}
                      value={dataDetail?.type === 1 ? 0 :(
                        dataDetail?.sale_type === 1
                          ? conditionPrice.value
                          : formatPrice(conditionPrice.value))
                      }
                      placeholder="Nhập giá trị giảm giá"
                      error={validate && !conditionPrice.value}
                      onChange={onChaneDiscount}
                      sx={{ flexGrow: 1 }}
                    />
                    <FormControl style={{ minWidth: 70 }}>
                      <Select
                        disabled={disable || detail}
                        style={{ marginLeft: 8,fontSize:14,fontWeight: 700 }}
                        name="sale_type"
                        value={dataDetail?.sale_type}
                        onChange={(e) => {
                          setConditionPrice({ value: "" });
                          onChangeValue(e);
                        }}
                        defaultValue={2}
                        disableUnderline
                        className={`${classes.select}`}
                  MenuProps={{classes:{paper: classes.menuProps}}}


                      >
                        <MenuItem value={2}>
                          <Box>VNĐ</Box>
                        </MenuItem>
                        <MenuItem value={1}>
                          <Box> % </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TextFields>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className="page-header"></div>
          <div className="page-content" style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={16} fontWeight={600}>
                Detail
              </Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextFields
                  label="Applicable invoice value"
                  disabled={disable || detail}
                  inputProps={{ maxLength: 50 }}
                  placeholder="Enter applicable invoice value "
                  name="total_price"
                  onChange={(e) => onChangeValue(e)}
                  value={dataDetail?.type === 1 ? 0 :formatPrice(dataDetail?.total_price)}
                  helperText="Total price is required"
                  error={
                    validate &&
                    !(dataDetail?.type === 1) &&
                    !dataDetail.total_price
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <span>
                        <strong>VND</strong>
                      </span>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextFields
                  label="Discount value"
                  helperText="Sale price is required"
                  error={
                    validate &&
                    !(dataDetail?.type === 1) &&
                    !conditionPrice.value
                  }
                >
                  <Box display="flex" alignItems="center">
                    <OutlinedInput
                      placeholder="Enter discount value"
                      disabled={disable || detail}
                      style={{ width: "100%", height: 38 }}
                      value={dataDetail?.type === 1 ? 0 :(
                        dataDetail?.sale_type === 1
                          ? conditionPrice.value
                          : formatPrice(conditionPrice.value))
                      }
                      error={validate && !conditionPrice.value}
                      onChange={onChaneDiscount}
                      sx={{ flexGrow: 1 }}
                    />
                    <FormControl style={{ minWidth: 70 }}>
                      <Select
                        disabled={disable || detail}
                        style={{ marginLeft: 8,fontSize:14,fontWeight: 700 }}
                        
                        name="sale_type"
                        value={dataDetail?.sale_type}
                        onChange={(e) => {
                          setConditionPrice({ value: "" });
                          onChangeValue(e);
                        }}
                        defaultValue={2}
                        disableUnderline
                        className={`${classes.select}`}
                  MenuProps={{classes:{paper: classes.menuProps}}}

                      >
                        <MenuItem value={2}>
                          <Box>VND</Box>
                        </MenuItem>
                        <MenuItem value={1}>
                          <Box> % </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TextFields>
              </Grid>
              {/* <Grid item xs={12} md={4}>
            <TextFields
              label="Số xu"
              disabled={disable}
              required
              inputProps={{ maxLength: 50 }}
            />
          </Grid> */}
            </Grid>
          </div>
        </>
      )}
    </Fragment>
  );
};
const useStyles = makeStyles({
  select: {
    width: "100%",
    height:'100%',
    fontSize: "12px",
    fontWeight: 500,
    padding:0,
    margin:0,
    borderRadius: "5px",
    border: "1px solid #B3B3B3",
    "& :focus": {
      backgroundColor: "transparent",
    },
    ".MuiNativeSelect-select":{
      backgroundColor: 'black'
    },
    "&.MuiInput-root": {
      padding: "4px 16px",
    },

    "& .Mui-disabled span": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  labelError: {
    color: "#EA403F",
  },
  placeholder: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#b3b3b3",
  },
  menuProps:{
    paddingLeft: 0,
    marginTop:"55px",
    backgroundColor:'white',
  },
  selectError: {
    border: "1px solid #EA403F",
  },
  paper: {
    marginTop: 8,
  },
});
CouponLoyaltyCreate.defaultProps = {
  coupon: {},
};
export default CouponLoyaltyCreate;
