import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, Collapse
} from "@material-ui/core";
import Breadcrumbs from "app/components/Breadcrumbs";
import GrayButton from "app/components/Button/GrayButton";
import PrimaryButton from "app/components/Button/PrimaryButton";
import SelectStatus from "app/components/SelectStatus";
import { ACTION_TYPE } from "app/reducers/orders";
import { getCouponSharingId } from "app/services/couponSharing";
import { getLoyaltyCouponId } from "app/services/loyaltyCoupon";
import * as Utils from "app/utils";
import { DATE_DEPLOY_PROD, LANG_VI, VOUCHER } from "app/utils/constant";
import { formatCompareDate, formatDateTime, formatDateTimeNOTPLUSSEVENT, formatPrice } from "app/utils/validate";
import { Fragment, useEffect, useState } from "react";
import { RiArrowDownSFill, RiArrowRightSFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { getRankId } from "../../../services/ranking";
import OrdersStatus, { ordersStatusList } from "../components/OrdersStatus";
import PaymentStatus, { paymentStatusList } from "../components/PaymentStatus";
export default function OrdersDetail({ refreshList }) {
  const dispatch = useDispatch();
  const orders = useSelector((store) => store.orders.orders) || {};
  const history = useHistory();
  let { id } = useParams();
  const [status, setStatus] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [shipping_price, setShippingPrice] = useState();
  const [rank, setRank] = useState();
  const [open, setOpen] = useState(true);
  const [isColor, setIsColor] = useState(false);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPE.GET_ORDERS_REQUEST,
      params: {
        id,
      },
    });
  }, []);
  useEffect(() => {
    if (orders?.id) {
      setShippingPrice(formatPrice(orders?.shipping_price));
      setStatus(
        ordersStatusList.find((item) => item.status === orders?.shipping_status)
      );
      setPaymentStatus(
        paymentStatusList.find((item) => item.status === orders?.payment_status)
      );
      if(orders?.coupon_loyalty_id){
        getLoyaltyCouponId({id: orders.coupon_loyalty_id} ).then((res) => {
          const dataRank = Utils.getSafeValue(res, "data", {});
          const resultsRank = Utils.getSafeValue(dataRank, "data", {});
          setRank(resultsRank);
        });
      }else if(orders?.coupon_friend_id){
        getCouponSharingId({id: orders.coupon_friend_id }).then((res) => {
          const dataRank = Utils.getSafeValue(res, "data", {});
          const resultsRank = Utils.getSafeValue(dataRank, "data", {});
          setRank(resultsRank);
        });
      }
      
    }
  }, [orders]);

 
  const onChangeShippingPrice = (e) => {
    let value = e.target.value;
    if (value.trim() === "") {
      setShippingPrice("0");
    } else {
      setShippingPrice(formatPrice(e.target.value));
    }
  };

  const onSubmit = () => {
    let shipping_cost = shipping_price.replace(/[^0-9]/g, "");
    if (
      shipping_cost !== orders?.shipping_price ||
      status?.status !== orders?.status ||
      paymentStatus?.status !== orders?.payment_status
    ) {
      dispatch({
        type: ACTION_TYPE.EDIT_ORDERS_REQUEST,
        id: orders?.id,
        data: {
          shipping_price: shipping_cost,
          shipping_status: status?.status,
          payment_status: paymentStatus?.status,
        },
        success: () => {
          refreshList instanceof Function && refreshList();
          history.push("/");
        },
      });
    } else {
      history.push("/");
    }
  };
  const ColorTableCell = (props) => {
    const { item, index } = props;
    const [isShow, setIsShow] = useState(false)
    const name = (item?.product?.translates || []).find(
      (itemName) => itemName.language_code === LANG_VI
    ).name;
    let listColor = []
    const colorProduct = Utils.getSafeValue(item, "detail_buyings", []);

    useEffect(() => {
      if (colorProduct.length <= 0) {
        setIsColor(false);
      } else {
        setIsColor(true);
      }
    }, [colorProduct]);
    colorProduct.map(item1 => item1.attribute_buyings.map(item2 => {
      listColor = [...listColor, {
        color: item2.order_detail_product_attribute.product_attribute_value_name, price: item1.price, quality: item1.quantity
      }]
    })
    )
    return (
      <>
        <TableRow key={item?.id} > {
          isColor ? (<TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => { setIsShow(!isShow) }}
            >
              {isShow ? <RiArrowDownSFill /> : <RiArrowRightSFill />}
            </IconButton>
          </TableCell>) : null
        }

          <TableCell  >{index + 1}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{item?.inventory?.name}</TableCell>
          {!isColor && (<>
            <TableCell align="center">
              {item?.number}
            </TableCell>
            <TableCell>
              {formatPrice(item?.original_price)} VNĐ
            </TableCell>
          </>)}
          <TableCell>{formatPrice(item?.total)} VNĐ</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isShow} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead style={{ background: "#eeeee4" }}>
                    <TableRow>
                      <TableCell width="50%" align="center">Màu sắc</TableCell>
                      <TableCell width="12%" align="center">Giá</TableCell>
                      <TableCell width="19%" align="center">Số lượng</TableCell>
                      <TableCell align="center"></TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {listColor.map((row) => {
                      console.log(row); return (
                        <TableRow key={row}>

                          <TableCell align="center">
                            {row.color}
                          </TableCell>
                          <TableCell align="center">
                         {formatPrice(row.price)} VNĐ
                          </TableCell>
                          <TableCell align="center">
                            {row.quality}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }
  
  return (
    <Fragment>
      <div className="page-header">
        <div className="page-title">
          <Breadcrumbs>
            <Link to="/">Danh sách đơn hàng</Link>
            <Typography>{orders?.code}</Typography>
          </Breadcrumbs>
        </div>
        {orders?.can_update_status && (
          <Fragment>
            <GrayButton component={Link} to="/">
              Hủy
            </GrayButton>
            <PrimaryButton
              onClick={() => {
                onSubmit();
              }}
            >
              Lưu Lại
            </PrimaryButton>
          </Fragment>
        )}
      </div>
      <div className="page-content">
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Tên khách hàng</div>
              <div className="info-value">
                {orders?.order_shippings?.[0]?.name}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Mã đơn hàng</div>
              <div className="info-value">{orders?.code}</div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Số điện thoại</div>
              <div className="info-value">
                {orders?.order_shippings?.[0]?.phone_number}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Trạng thái giao vận</div>
              <div className="info-value first-child-inline">
                {orders?.can_update_status ? (
                  <SelectStatus
                    width={236}
                    disabled
                    value={status}
                    onChange={setStatus}
                    statusList={ordersStatusList}
                  />
                ) : (
                  <OrdersStatus status={orders?.shipping_status} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Địa chỉ nhận hàng</div>
              <div className="info-value">
                {orders?.order_shippings?.[0]?.address}
                {", "}
                {orders?.order_shippings?.[0]?.ward?.name}
                {", "}
                {orders?.order_shippings?.[0]?.district?.name}
                {", "}
                {orders?.order_shippings?.[0]?.city?.name}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Trạng thái thanh toán</div>
              <div className="info-value first-child-inline">
                {orders?.can_update_status ? (
                  <SelectStatus
                    width={236}
                    disabled
                    value={paymentStatus}
                    onChange={setPaymentStatus}
                    statusList={paymentStatusList}
                  />
                ) : (
                  <PaymentStatus status={orders?.payment_status} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Thời gian đặt</div>
              <div className="info-value">
                {formatDateTimeNOTPLUSSEVENT(orders?.created_at)}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            {orders?.can_update_status ? (
              <div className="info-content">
                <div className="info-label" style={{ marginBottom: 8 }}>
                  Phí vận chuyển*
                </div>
                <OutlinedInput
                  className="text-input"
                  endAdornment={
                    <InputAdornment position="end">
                      <Box color="#3A3A3A" fontWeight="bold">
                        VND
                      </Box>
                    </InputAdornment>
                  }
                  value={shipping_price}
                  onChange={onChangeShippingPrice}
                />
              </div>
            ) : (
              <div className="info-content">
                <div className="info-label">Giá ship</div>
                <div className="info-value">
                  {formatPrice(orders?.shipping_price)} VNĐ
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid>
              <div className="info-content">
                <div className="info-label">Voucher áp dụng</div>
                <div className="info-value">
                    <span
               
                      style={{ display: "inline-block", marginRight: 48 }}
                    >
                      {rank?.code}
                    </span>
                </div>
              </div>
            </Grid>
            <Grid>
              <div className="info-content">
                <div className="info-label">Giá giảm</div>
                <div className="info-value">{formatPrice(orders?.discount_price) } VNĐ</div>
              </div>
            </Grid>
            <Grid>
              <div className="info-content">
                <div className="info-label">Tích xu</div>
                <div className="info-value">
                 {orders?.discount_price === 0 && !(orders?.coupon_loyalty_id === null) ? "+" + formatPrice(rank?.sale_price): 0}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid>
              <div className="info-content">
                <div className="info-label">Hình thức thanh toán</div>
                <div className="info-value">
                  {orders?.payments?.[0]?.type === 1
                    ? "Tiền mặt khi nhận hàng (COD)"
                    : orders?.payments?.[0]?.type === 2
                      ? "Thanh toán online"
                      : orders?.payments?.[0]?.type === 3 &&
                      "Liên hệ Nhựa Long Thành"}
                </div>
              </div>
            </Grid>
            <Grid>
              <div className="info-content">
                <div className="info-label">Đơn vị vận chuyển</div>
                <div className="info-value">
                
                        {orders?.order_shippings?.[0]?.driver === 1 ? "Giao hàng tiết kiệm" :
                           "Long Thanh Plastic"}
                      
                </div>
              </div>
            </Grid>
            <Grid>
              <div className="info-content">
                <div className="info-label">Lý do hủy</div>
                <div className="info-value">{orders.note}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="info-content">
              <div className="info-label">Giá tổng</div>
              <div className="info-value">{formatPrice(orders?.total)}</div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </div>
      <div className="page-title">Danh sách sản phẩm</div>
      <TableContainer component={Paper} style={{ marginBottom: 24 }}>
        <Table padding="normal" stickyHeader>
          <TableHead>
            <TableRow>
              {isColor ? (<TableCell>Màu sắc</TableCell>) : null}
              <TableCell>STT</TableCell>
              <TableCell>Tên sản phẩm</TableCell>
              <TableCell>Kho hàng</TableCell>
              {!isColor && (<>
                <TableCell align="center">Số lượng</TableCell>
                <TableCell>Đơn giá</TableCell>
              </>
              )}


              <TableCell>Giá tổng</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(orders?.order_details) &&
              orders.order_details.map((orderItem, index) => {
                return (<ColorTableCell item={orderItem} index={index} />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
