import { createSlice } from "@reduxjs/toolkit";
import {
  createCouponLoyalty,
  deleteLoyaltyCoupons,
  editLoyaltyCoupon,
  getLoyaltyCouponId,
  getLoyaltyCouponList,
} from "app/services/loyaltyCoupon";
import { PAGE_SIZE } from "app/utils/constant";
export const fetchListLoyaltyCoupon = (params) => async (dispatch, getState) => {
  try {
    const response = await getLoyaltyCouponList(params);
    let payload = {
      listCoupon: response.data?.data || [],
      totalCoupons: response.data?.total || 0,
    };
    dispatch(updateDataLoyaltyCoupon(payload));
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchCreateCouponLoyalty = (params) => async (dispatch, getState) => {
  try {
    const response = await createCouponLoyalty(params);
    const state = getState().coupon;
    const listCoupon = state.listCoupon || [];
    if (listCoupon.length) {
      let payloadListCoupon = {
        listCoupon: [
          {
            ...(response?.data?.data || {}),
            ...params,
            total_price: params.total_price,
            sale_price: params.sale_price,
            sale_type: params.sale_type,
            translates: params.translates,
          },
          ...listCoupon,
        ],
      };
      dispatch(updateDataLoyaltyCoupon(payloadListCoupon));
    } else {
      dispatch(fetchListLoyaltyCoupon({ page: 1, limit: PAGE_SIZE }));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchLoyaltyCoupon = (params) => async (dispatch, getState) => {
  try {
    const response = await getLoyaltyCouponId(params);
    let payload = {
      coupon: response.data?.data || {},
    };
    dispatch(updateDataLoyaltyCoupon(payload));
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchUpdateLoyaltyCoupon = (params) => async (dispatch, getState) => {
  try {
    const response = await editLoyaltyCoupon(params);
    const state = getState().coupon;
    const listCoupon = state.listCoupon || [];
    if (listCoupon.length) {
      let payloadListCoupon = {
        listCoupon: [
          ...listCoupon.map((item) => {
            if (item.id === +params.id) {
              return {
                ...params,
                start_date: params.start_date,
                start_time: params.start_time,
                end_date: params.end_date,
                end_time: params.end_time,
                translates: params.contents,
                requirements:
                  params.type === 1
                    ? params.order_requirements
                    : params.product_requirements,
              };
            } else {
              return item;
            }
          }),
        ],
      };
      dispatch(updateDataLoyaltyCoupon(payloadListCoupon));
    } else {
      dispatch(fetchListLoyaltyCoupon({ page: 1, limit: PAGE_SIZE }));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchDeleteLoyaltyCoupons = (params) => async (dispatch, getState) => {
  try {
    const { id } = params;
    const response =  await deleteLoyaltyCoupons({ id: id })
    return response;
  } catch (error) {
    return error;
  }
};
export const setupLoyaltyCoupon = createSlice({
  name: "loyaltyCoupon",
  initialState: {
    listCoupon: [],
    totalCoupons: 0,
    coupon: {},
  },
  reducers: {
    updateDataLoyaltyCoupon: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateDataLoyaltyCoupon } = setupLoyaltyCoupon.actions;
export default setupLoyaltyCoupon.reducer;
