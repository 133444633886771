import axios from "../../axios";
import { apiCouponSharing } from "../../axios/urlApi";

export const getCouponSharingList = (params) => {
  return axios.get(`${apiCouponSharing}`, { params });
};

export const getCouponSharingId = ({ id, ...params }) => {
  return axios.get(`${apiCouponSharing}/${id}`, { params });
};

export const createCouponSharing = (params) => {
  return axios.post(`${apiCouponSharing}`, params);
};

export const editCouponSharing = ({ id, ...params }) => {
  return axios.put(`${apiCouponSharing}/${id}`, params);
};

export const deleteCouponSharings = (params) => {
    return axios.delete(`${apiCouponSharing}/${params.id}`, params)
};
